import React from "react";
import { Modal, Button } from "antd";
import { Link } from 'react-router-dom';


const NoResumeDataModal = ({ onClose }) => {
    return (
        <Modal
            visible={true}
            title={<div style={{ textAlign: 'center' }}>Missing Resume</div>}
            footer={null}
            closable={false}
        >
            <div style={{ textAlign: 'center' }}>
            <p>There is no resume data to display. Please upload your resume first.</p>

            <Button className= "button-color" type="primary">
                <Link to="/profile/resume">Upload Resume</Link>
            </Button>
            </div>
        </Modal>
    );
}

export default NoResumeDataModal;