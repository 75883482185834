import React, { useState, useEffect } from 'react';
import { Modal, Card, Divider } from 'antd';
import MailBox from '../../MailBox';



const MailBoxModal = ({ visible, onClose, contact, myEmails}) => {

    console.log('contact:', contact)

    var name = "Contact"
    if (contact.companyDetails && contact.companyDetails.companyName) {
        name = contact.companyDetails.companyName;
    }
    if (contact.contactDetails && contact.contactDetails.firstName) {
        name = contact.contactDetails.firstName;
    }
    if (contact.contactDetails && contact.contactDetails.lastName) {
        name += ' ' + contact.contactDetails.lastName;
    }

    var toEmail
    if (contact.contactDetails && contact.contactDetails.email) {
        toEmail = contact.contactDetails.email;
    }
    if (contact.companyDetails && contact.companyDetails.companyEmail) {
        toEmail = contact.companyDetails.companyEmail;
    }

    

    return (
        <Modal
            // title={<div style={{ textAlign: 'center', fontSize: '2em' }}></div>}
            centered
            visible={visible}
            onCancel={() => onClose()}
            closable={true}
            footer={null}
            width={'55vw'}
            bodyStyle={{
                height: '85vh',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                padding: 0, // Remove padding
                margin: 0, // Remove margin
            }}
            maskClosable={true}
        >
            <MailBox
                style={{ margin: 0, padding: 0 }}
                contactName={name}
                myEmails={myEmails}
                userContactId={contact._id}
                toEmail={toEmail}
            />
        </Modal>
    );
}


export default MailBoxModal;