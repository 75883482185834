import React, { useState, useEffect } from "react";
import { Card, Button, Dropdown, Menu } from 'antd';
import HTMLPreviewer from "../HTMLPreviewer";
import { fetchCreateCL } from '../../../utils/CreateResumeAPIs/createCL';
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { UPDATE_MY_SAVED_COVERLETTER } from "../../../utils/mutations";
//import { useQuery } from "@apollo/client";
import { QUERY_COVER_LETTER_BY_ID } from "../../../utils/queries";


const CLPreviewer = ({ intialHtmlData, currentCLData, clID }) => {
    const [theme, setTheme] = useState('MainTemplate');
    const [htmlContent, setHtmlContent] = useState(intialHtmlData);
    const [loading, setLoading] = useState(false);
    const [updateMySavedCoverLetter] = useMutation(UPDATE_MY_SAVED_COVERLETTER);
    const shouldSkip = intialHtmlData || currentCLData;
    const { data } = useQuery(QUERY_COVER_LETTER_BY_ID, {
        variables: { id: clID },
        skip: shouldSkip
    });
    const [clData, setCLData] = useState(currentCLData);

    console.log(data);

    useEffect(() => {
        if (currentCLData) {
            // Trigger the preview
            previewTheme(currentCLData, theme, "html");
            setCLData(currentCLData);
        }
    }, [currentCLData]);

    useEffect(() => {
        if (data && data.getCoverLetterDataByID && !currentCLData) {
            fetchIntialHtmlData(data.getCoverLetterDataByID, theme, 'html');
            setCLData(data.getCoverLetterDataByID);
        }
    }, [data]);

    const fetchIntialHtmlData = async (data, theme, type) => {
        const htmlData = await fetchCreateCL(data, theme, type);
        setHtmlContent(htmlData);
    }

    const previewTheme = async (data, theme, docType) => {
        setTheme(theme);
        setLoading(true);
        try {
            const htmlData = await fetchCreateCL(data, theme, docType);
            if (docType === "pdf") {
                // Converting base64 to blob
                console.log(htmlData.data);
                const binaryString = window.atob(htmlData.data);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);
                for (let i = 0; i < binaryLen; i++) {
                    const ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            }
            if (docType === "html") {
                setHtmlContent(htmlData);
            }
        }
        catch (error) {
            console.error("Error previewing cover letter:", error);
        } finally {
            setLoading(false);
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="MainTemplate" onClick={() => previewTheme('MainTemplate', 'html')}>Main Template</Menu.Item>
            {/* <Menu.Item key="coverLetter1" onClick={() => previewTheme('coverLetter1', 'html')}>Alternative Template</Menu.Item> */}
        </Menu>
    );

    const saveCoverLetter = (clID) => async () => {
        try {
            const { data } = await updateMySavedCoverLetter({
                variables: {
                    coverLetterData: clID
                }
            });
            console.log("Saved Cover Letter", data);
        } catch (error) {
            console.error("Error saving cover letter:", error);
        }
    }


    return (
        <Card
            title="Cover Letter Preview"
            style={{ width: '100%', height: '100%' }} bodyStyle={{ width: '100%', height: '90%' }}
            extra={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button>Themes</Button>
                </Dropdown>
                <Button className='button-color' loading={loading} onClick={() => previewTheme(clData, theme, 'pdf')} >Download</Button>
                {/* <Button onClick={saveCoverLetter(clID)} >Save</Button> */}
            </div>
            }
        >
            <HTMLPreviewer html={htmlContent} />
        </Card>
    );

}

export default CLPreviewer;