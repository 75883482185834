import React, { useEffect, useState, useContext, useRef } from 'react';
import MatchCard from "../../components/Cards/MatchCard";
import { useQuery } from '@apollo/client';
import { QUERY_PUBLIC_COMPANIES } from '../../utils/queries.js'
import { QUERY_MY_COMPANIES } from '../../utils/queries.js'
import { UserContext } from '../../contexts/userContext.js';
import { backgroundJobCheck } from '../../utils/helpers/background.js';
import { startGenerateMatchesTask, getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult } from '../../utils/FindMatchAPIs/generateMatches.js';
import LoadingMatches from '../../components/Loading/LoadingMatches/index';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, Spin } from 'antd';
import './Matcher.css';
import useIsMobile from '../../contexts/useIsMobile'

const Matcher = () => {


    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const { data: companyData, refetch } = useQuery(QUERY_PUBLIC_COMPANIES);
    const { data: myCompanyData, refetch: refetchMyCompanies } = useQuery(QUERY_MY_COMPANIES);
    const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);
    const [showMatches, setShowMatches] = useState(false);
    const [allCompaniesReviewed, setAllCompaniesReviewed] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const isMobile = useIsMobile();

    const [showMatchProgress, setShowMatchProgress] = useState(false);
    const [searchFinished, setSearchFinished] = useState(false);
    const [progress, setProgress] = useState(0);

    const [totalLikes, setTotalLikes] = useState(user ? user.matches.matchesLiked : 0);

    const hasPerformedMatch = useRef(false);

    const maxlikes = 50
    const maxMatchesFound = 50

    const findMatches = async () => {
        // Clear current matches
        setCompanies([]);

        setSearchFinished(false);
        setShowMatchProgress(true);
        setProgress(0);
        setCurrentCompanyIndex(0);

        console.log('Finding Matches...');

        // Start generateMatches task and get a task ID
        let taskResponse = await startGenerateMatchesTask(user._id);
        let taskId = taskResponse.data.taskID;

        // Check the status of the task and get the result
        let matchResultsPromise = backgroundJobCheck(getGenerateMatchesTaskStatus, getGenerateMatchesTaskResult, taskId);

        // Create a timeout promise that resolves after 6 seconds
        const timeoutPromise = new Promise(resolve => setTimeout(resolve, 2000));

        // Wait for both the match results and the timeout
        let [matchResults] = await Promise.all([matchResultsPromise, timeoutPromise]);

        // Refetch Companies
        await refetchMyCompanies();
        await refetch();

        setSearchFinished(true);

        return matchResults;
    };

    
    // useEffect to handle the filtering and setting of companies
    useEffect(() => {
        const updateCompanies = async () => {
            if (user && user.matches) {
                setTotalLikes(user.matches.matchesLiked);
            }

            if (isFirstLoad) {
                // Trigger refetches to ensure data is up-to-date

                if ((user.matches.matchesFound.thisWeek >= maxMatchesFound || totalLikes >= maxlikes) && !hasPerformedMatch.current) {
                    hasPerformedMatch.current = true;
                    const matchResults = await findMatches();
                    
                    if (matchResults.result && !matchResults.result.includes("MAX")) {
                        setTotalLikes(0);
                    } else {
                        setAllCompaniesReviewed(true);
                    }      
                }

                await refetch();
                await refetchMyCompanies();
                setIsFirstLoad(false); // Set to false so it doesn't refetch on subsequent renders
            }

            // if (totalLikes >= maxlikes) {
            //     // Trigger Find Matches - See if user match limits have been refreshed
            //     setAllCompaniesReviewed(true);
            // }

            if (companyData && companyData.publicCompanies && user && user._id && myCompanyData && myCompanyData.myCompanies) {

                const filteredCompanies = companyData.publicCompanies.filter(company =>
                    company.recommendedTo && company.recommendedTo.some(recUser => recUser && recUser._id === user._id) &&
                    !myCompanyData.myCompanies.some(myCompany => myCompany.companyDetails && myCompany.companyDetails._id === company._id)
                );


                setCompanies(filteredCompanies);
                setLoading(false);
                //setShowMatchProgress(false)
            }
        };

        updateCompanies();
    }, [user, isFirstLoad, companyData, myCompanyData]);

    useEffect(() => {
        let interval;
        if (searchFinished) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => setShowMatchProgress(false), 2000); // Stay at 100% for 2 seconds
                        return 100;
                    }
                    return prevProgress + 10; // Speed up the progress
                });
            }, 50); // Faster interval time to quickly reach 100%
        } else if (showMatchProgress) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return prevProgress + 1;
                });
            }, 500); // Adjust the interval time as needed
        }

        return () => clearInterval(interval);
    }, [searchFinished, showMatchProgress]);


    const next = (notLiked) => {

        if (!notLiked) {
            var currentLikes = totalLikes + 1
            setTotalLikes(currentLikes)
            if (currentLikes >= maxlikes) {
                setAllCompaniesReviewed(true);
                return
            }
        }

        const nextIndex = (currentCompanyIndex + 1) % companies.length;
        if (nextIndex === 0) {
            //setAllCompaniesReviewed(true);
            findMatches();
        } else {
            setCurrentCompanyIndex(nextIndex);
        }
    }

    const prev = () => {
        setCurrentCompanyIndex((currentCompanyIndex - 1 + companies.length) % companies.length);
    }


    //If we are loading matches
    if (showMatchProgress) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingMatches progress={progress} />
            </div>
        );
    }

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" tip="Loading..." />
            </div>
        );
    }


    // If there are no companies or all companies have been reviewed, show a message
    if (!loading && (!companies.length || allCompaniesReviewed)) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f8f9fa', color: '#343a40' }}>
                <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>You've gone through all your matches for now!</p>
                <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.8em', fontWeight: 'normal' }}>Head <a href="/" style={{ color: '#007bff' }}>Home</a> for your the next steps in your job hunt journey.</p>
                {isMobile ? (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>Or</p>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>
                            Check your <a href="/inbox" style={{ color: '#007bff' }}>Inbox</a> to view your replies. Please visit us on your desktop to access more features designed to help you land your dream job.
                        </p>
                    </>
                ) : (
                    <>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>Or</p>
                        <p style={{ textAlign: 'center', margin: '20px', fontSize: '1.2em', fontWeight: 'normal' }}>
                            Review your existing <a href="/contacts" style={{ color: '#007bff' }}>contacts</a> or check on your <a href="/applications" style={{ color: '#007bff' }}>applications</a>.
                        </p>
                    </>
                )}
            </div>
        );
    }

    // If there are companies to show, display them
    return (
        !showMatches ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p className='discover-text'>
                    Exciting news! We've discovered {companies.length} fantastic opportunities just for you. Ready to explore?
                </p>
                <Button className='button-color-premium' size="large" onClick={() => setShowMatches(true)}>Discover Matches</Button>
            </div>
            :
            <div className="main-container" >
                <div className="title-section">
                    <h1 className="title">Matches</h1>
                    <h1 className='count'>{`${currentCompanyIndex + 1}/${companies.length}`}</h1>
                </div>
                <div style={{ position: 'relative', width: '100%' }}>
                    <TransitionGroup>
                        <CSSTransition
                            key={currentCompanyIndex}
                            timeout={1500}
                            classNames="fade"
                        >
                            <MatchCard match={companies[currentCompanyIndex]} next={next} />
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>

    )
}

export default Matcher;