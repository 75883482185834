import axios from 'axios';

export const fetchBulletPoint = async (BulletPoint, WordCount) => {
    const data = {
        BulletPoint: BulletPoint,
        WordCount: WordCount
    };
    try {
        const response = await axios.post('/resume/resumeBulletPoint', data);

        return response.data.improvedBulletPoint;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}