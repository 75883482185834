import axios from 'axios';

//Background

export const startGenerateCLTask = async (resumeData, jdData) => {

    const data = {
        resumeData,
        jdData
    };

    try {

        const response = await axios.post('/resume/generate-cl-task', data);

        return response;

    } catch (error) {

        console.error("There was a problem parse your resume", error.message);

    }
}

export const getGenerateCLTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/resume/generate-cl-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your resume", error.message);

    }
}

export const getGenerateCLTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/resume/generate-cl-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your resume", error.message);

    }
}

//Legacy
export const fetchGenerateCLdata = async (resumeData, jdData) => {

    const data = {
        resumeData,
        jdData
    };

    try {
        const response = await axios.post('/resume/generateCLData', data);

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}