import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col } from 'antd';
import { useMutation } from "@apollo/client";
import { EDIT_COMPANY } from "../../../utils/mutations.js";

const EditCompanyModal = ({ onClose, company }) => {
    const [form] = Form.useForm();
    const [editCompany] = useMutation(EDIT_COMPANY);
    const [companyPublicOrPrivate, setcompanyPublicOrPrivate] = useState(company.postingStatus);
    const [formStatus, setFormStatus] = useState("");

    console.log(company);

    useEffect(() => {
        if (companyPublicOrPrivate === "Public") {
            setFormStatus("disabled");
        }
    }, [companyPublicOrPrivate]);

    const handleSubmit = async () => {
        try {
            const formData = await form.validateFields();
            await editCompany({
                variables: {
                    id: company._id,
                    companyName: formData.companyName,
                    companyWebsite: formData.website,
                    companyLinkedIn: formData.linkedin,
                    companyEmail: formData.email,
                    companyPhone: formData.phone,
                    companyAddress: formData.address,
                    companyCity: formData.city,
                    companyStateOrProvince: formData.state,
                    industry: formData.industry,
                    size: formData.size,
                    yearFounded: formData.yearFounded,
                },
            });

            form.resetFields();
            onClose();
        } catch (error) {
            console.error("Error editing company:", error);
        }
    };

    return (
        <Modal
            title="Edit Company"
            visible={true}
            // onOk={handleSubmit}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit} className='button-color'>
                    Update
                </Button>,
            ]}
            className="custom-modal"
        >
            <Form form={form} layout="vertical" className="custom-form" disabled={formStatus}>
                <Form.Item
                    label="Company Name"
                    name="companyName"
                    initialValue={company.companyName}
                    rules={[{ required: true, message: 'Please input the company name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Website"
                    name="website"
                    initialValue={company.companyWebsite}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="LinkedIn"
                    name="linkedin"
                    initialValue={company.companyLinkedIn}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            initialValue={company.companyEmail}
                        >
                            {companyPublicOrPrivate === "Public" ? (
                                <Input type="password" />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Phone"
                            name="phone"
                            initialValue={company.companyPhone}
                        >
                            {companyPublicOrPrivate === "Public" ? (
                                <Input type="password" />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="Address"
                    name="address"
                    initialValue={company.companyAddress}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="City"
                            name="city"
                            initialValue={company.companyCity}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="State"
                            name="state"
                            initialValue={company.companyStateOrProvince}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Industry"
                            name="industry"
                            initialValue={company.industry}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Size"
                            name="size"
                            initialValue={company.size}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Year Founded"
                            name="yearFounded"
                            initialValue={company.yearFounded}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default EditCompanyModal;