import React, { useState, useEffect } from 'react';
import { Calendar } from 'antd';
import dayjs from 'dayjs';
import './style.css';

// const contacts = [
//     { meeting: { date: '2023-12-01', time: '10:00 AM' } },
//     { meeting: { date: '2023-12-02', time: '2:30 PM' } },
//     { meeting: { date: '2023-12-02', time: '4:00 PM' } },
// ];

const MeetingCalendar = ({ Data }) => {
    const [loading, setLoading] = useState(true);
    const [meetingsData, setMeetingsData] = useState([]);

    useEffect(() => {
        if (Data && Data.length > 0) {
            if (Data[0].meeting) {
                if (Data[0].meeting.date) {
                    setMeetingsData(Data);
                    console.log(Data[0].meeting.date)
                }
            }
        }
    }, [Data]);

    useEffect(() => {
        if (meetingsData.length > 0) {
            setLoading(false);
        }
    }, [meetingsData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const dateCellRender = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        const meetings = meetingsData.filter((contact) => dayjs(parseInt(contact.meeting.date)).format('YYYY-MM-DD') === formattedDate);

        return (
            <ul>
                {meetings.map((meeting, index) => (
                    <li className="meeting" key={index}>{meeting.contactDetails.firstName}: {meeting.meeting.start} - {meeting.meeting.end}</li>
                ))}
            </ul>
        );
    };

    return <Calendar cellRender={dateCellRender} />;
};

export default MeetingCalendar;