import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col } from 'antd';
import { useMutation } from "@apollo/client";
import { ADD_CONTACT } from "../../../utils/mutations.js";
import { useQuery } from "@apollo/client";
import { QUERY_MY_COMPANIES } from "../../../utils/queries.js";
import AutoComplete from 'antd/lib/auto-complete';
import { ADD_COMPANY } from "../../../utils/mutations.js";
import { SAVE_CONTACT } from "../../../utils/mutations.js";
import { SAVE_COMPANY } from "../../../utils/mutations.js";
import { CheckCircleOutlined } from '@ant-design/icons';
import './style.css';

const { Option } = Select;


const AddContactModal = ({ onClose }) => {
  const [form] = Form.useForm();
  const [addContact] = useMutation(ADD_CONTACT);
  const { data, loading, refetch } = useQuery(QUERY_MY_COMPANIES);
  const [companies, setCompanies] = useState([]);
  const [addCompany] = useMutation(ADD_COMPANY);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [currentCompany, setCurrentCompany] = useState('');
  const [saveContact] = useMutation(SAVE_CONTACT);
  const [saveCompany] = useMutation(SAVE_COMPANY);

  useEffect(() => {
    if (data) {
      setCompanies(data.myCompanies);
    }
  }, [data]);

  //console.log(companies);
  console.log(companyOptions);
  console.log(currentCompany);

  const handleSubmit = async () => {

    var companyID = null;

    try {

      //Check if there is company entereed
      if (currentCompany) {
        if (companies) {
          console.log("company exist");
          var filteredCompanies = companies.filter(company => company.companyDetails.companyName === currentCompany);
          if (filteredCompanies.length > 0) {
            console.log(filteredCompanies[0].companyDetails._id);
            companyID = filteredCompanies[0].companyDetails._id;
          } else {
            console.log("no companies found");
            // Do not proceed if no company is found
            return;
          }
        }
      }

      const formData = await form.validateFields();
      const newContact = await addContact({
        variables: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          company: companyID ? { _id: companyID } : null, // pass the company object as a variable only if companyID is not null
          jobTitle: formData.jobTitle,
          linkedIn: formData.linkedIn,
          email: formData.email,
          phone: formData.phone,
          postingStatus: "Private",
        },
      });

      // Save the contact to the user's contacts
      await saveContact({
        variables: {
          contact: newContact.data.addContact._id,
          relationship: formData.relationship ? formData.relationship : "Other",
        },
      });

      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error editing contact:", error);
    }
  };

  const handleSearch = (value) => {
    if (companies) {
      const filteredCompanies = companies.filter(company => company.companyDetails.companyName.toLowerCase().includes(value.toLowerCase()));
      const options = filteredCompanies.map(company => {
        return { value: company.companyDetails.companyName, id: company._id }
      });
      setCompanyOptions(options);
    }
  };

  const handleSelectCompany = (value, id) => {
    setCurrentCompany(value);
  }

  const handleAddCompany = async () => {
    try {
      const newCompany = await addCompany({
        variables: {
          companyName: currentCompany,
          postingStatus: "Private"
        },
      });
      //Save Company to user's saved companies
      await saveCompany({
        variables: {
          company: newCompany.data.addCompany._id
        },
      });
      refetch();
    } catch (error) {
      console.error("Error adding company:", error);
    }
  }

  const isCompanyAdded = () => {
    if (companies) {
      const filteredCompanies = companies.filter(company => company.companyDetails.companyName === currentCompany);
      if (filteredCompanies.length > 0) {
        return true;
      }
    }
    return false;
  }


  return (
    <Modal
      title="Add Contact"
      visible={true}
      // onOk={handleSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} className='button-color'>
          Add
        </Button>,
      ]}
      className="custom-modal"
    >
      <Form form={form} layout="vertical" className="custom-form">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Company"
          name="company"
        >
          <div className="autocomplete-wrapper">
            <AutoComplete
              options={companyOptions}
              style={{ width: '100%' }}
              onSearch={handleSearch}
              onSelect={handleSelectCompany}
              onChange={(value) => setCurrentCompany(value)}
            >
              <Input.Search size="medium" placeholder="Company's Name" />
            </AutoComplete>
            {isCompanyAdded() ? (
              <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />
            ) : (
              <Button className='button-color' type="primary" onClick={handleAddCompany} style={{ marginLeft: '10px' }}>Quick Add</Button>
            )}
          </div>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Job Title" name="jobTitle">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Relationship" name="relationship">
              <Select>
                <Option value="Family">Family</Option>
                <Option value="Friend">Friend</Option>
                <Option value="Mutual">Mutual</Option>
                <Option value="Co-Worker">Co-Worker</Option>
                <Option value="Alumni">Alumni</Option>
                <Option value="Recruiter">Recruiter</Option>
                <Option value="Hiring Manager">Hiring Manager</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="LinkedIn" name="linkedIn">
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddContactModal;