import axios from 'axios';
import { notification } from 'antd';

export const fetchSendEmail = async (to, from, subject, message, contactID, userID) => {

    const data = {
        to,
        from,
        subject,
        message,
        contactID,
        userID
    };

    try {
        const response = await axios.post('/email/send', data);

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}

// export const sendEmail = async (toEmail, fromEmail, subject, body, userContactId, userId) => {
//     try {
//         //Send email
//         const response = await fetchSendEmail(toEmail, fromEmail, subject, body, userContactId, userId);

//         // Check if the response is an error
//         if (response instanceof Error || !response) {
//             console.log("Error sending email:", response);
//             throw response;
//         }

//         notification.success({
//             message: 'Email Sent',
//             description: 'Your email has been sent successfully.',
//             placement: 'topRight',
//             duration: 10,
//         });

//         // markAsSent();
//     } catch (error) {
//         notification.error({
//             message: 'Error Sending Email',
//             description: 'This feature is not available for the beta',
//             placement: 'topRight',
//             duration: 10,
//         });
//     }

// };