import React from "react";
import { Form, Input } from 'antd';


const TargetJobExtraInfo = () => {
    return (
        <div>
            <Form.Item name='Worksite' label="Worksite">
                <Input />
            </Form.Item>
            <Form.Item name='Desired Salary' label="Desired Salary">
                <Input />
            </Form.Item>
            <Form.Item name='Part or Full Time' label="Part or Full Time">
                <Input />
            </Form.Item>
        </div>
    );
}

export default TargetJobExtraInfo;