import axios from 'axios';

export const fetchCreateCL = async (coverLetterData, coverLetterTemplate, docType) => {

    const data = {
        coverLetterData,
        coverLetterTemplate,
        docType
    };

    try {
        const response = await axios.post('/resume/createCL', data);

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}