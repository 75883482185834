import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { EDIT_SOCIALURLS } from '../../../../utils/mutations';


const SocialURLsForm = ({ URLs }) => {

    const [linkedIn, setLinkedIn] = useState('');
    const [github, setGithub] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [editSocialURLs] = useMutation(EDIT_SOCIALURLS);

    const [formSocialURLs] = Form.useForm();

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 },
    }

    useEffect(() => {
        setLinkedIn(URLs.linkedIn);
        setGithub(URLs.github);
        setPortfolio(URLs.portfolio)
    }, [URLs]);

    formSocialURLs.setFieldsValue({
        'LinkedIn': linkedIn,
        'Github': github,
        'Portfolio': portfolio,
    })

    const handleFormSubmitSocialURLs = async (values) => {
        setLinkedIn(values['LinkedIn']);
        setGithub(values['Github']);
        setPortfolio(values['Portfolio']);
        try {
            // call the editSocialURLs mutation with the form values
            await editSocialURLs({
                variables: {
                    linkedIn: values['LinkedIn'],
                    github: values['Github'],
                    portfolio: values['Portfolio']
                }
            });

        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form
                {...layout}
                form={formSocialURLs} // Pass the form instance to the Form component
                name={`nest-messages`}
                style={{ width: '100%'}}
                onFinish={handleFormSubmitSocialURLs}
            >
                <Row>
                    <Form.Item name='LinkedIn' label="LinkedIn">
                        <Input/>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item name='Github' label="Github">
                        <Input/>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item name='Portfolio' label="Portfolio">
                        <Input/>
                    </Form.Item>
                </Row>
                <Row style={{display:'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
}

export default SocialURLsForm;