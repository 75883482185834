import React from 'react';
import { Button, Form, Input, Checkbox, DatePicker, Row, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { ADD_WORK_HISTORY } from '../../../../utils/mutations';


const WorkExperienceForm = ({refreshFunction}) => {
    const [addWorkHistory] = useMutation(ADD_WORK_HISTORY);

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 },
    };

    const handleFormSubmit = async (values) => {
        try {
            // Call the addWorkHistory mutation with the form values
            await addWorkHistory({
                variables: {
                    jobTitle: values['Job Title'],
                    company: values['Company'],
                    city: values['City'],
                    provinceOrState: values['Province Or State'],
                    currentlyWorking: values['Currently Working'],
                    startDate: values['Start Date'],
                    endDate: values['End Date'],
                    description: values['Description']
                }
            });

            // Reset the form
            form.resetFields();

            refreshFunction()
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    }

    return (
        <div >
            <Form
                {...layout}
                form={form} // Pass the form instance to the Form component
                name={`nest-messages`}
                style={{ width: '100%' }}
                onFinish={handleFormSubmit}
            >
                <Row>
                    <Form.Item name='Job Title' label="Job Title">
                        <Input style={{ minWidth: "300px" }} />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item name='Company' label="Company">
                        <Input style={{ minWidth: "300px" }} />
                    </Form.Item>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name='City' label="City">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='Province Or State' label="Province Or State">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={10} >
                        <Form.Item name='Currently Working' label="Currently Working" valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='Start Date' label="Start Date">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='End Date' label="End Date">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        <Form.Item name='Description' label="Description">
                            <Input.TextArea style={{ width: '100%', minWidth: '400px', whiteSpace: 'pre-wrap' }} rows={6} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{display:'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <Form.Item style={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                    {/* <Col span={20} style={{ textAlign: 'center' }}>
                        <Button onClick={() => setShowWorkHistoryForm(false)}>Cancel</Button>
                    </Col> */}
                </Row>
            </Form>
        </div>
    )
}

export default WorkExperienceForm;

