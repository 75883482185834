export const resumeSampleData = {
    "basics": {
        "name": "Jane Doe",
        "label": "Software Developer",
        "email": "jane.doe@example.com",
        "phone": "1234567890",
        "summary": "Experienced software developer with a passion for creating user-friendly applications.",
        "location": {
            "address": "123 Elm St",
            "city": "San Francisco",
            "countryCode": "US",
            "region": "California"
        },
        "profiles": [
            {
                "network": "LinkedIn",
                "username": "jane-doe",
                "url": "https://www.linkedin.com/in/jane-doe"
            }
        ]
    },
    "work": [
        {
            "position": "Financial Analyst",
            "name": "Banking Company",
            "location": "New York",
            "startDate": "2018",
            "endDate": "Present",
            "currentlyWorking": true,
            "highlights": [
                "Leveraged advanced data analysis techniques to interpret complex financial data, providing actionable insights that influenced key business decisions and strategies.",
                "Compiled and presented comprehensive, detailed reports on financial performance, trends, and forecasts to senior management, facilitating data-driven decision-making processes.",
                "Fostered cross-departmental collaboration, leading to successful completion of multifaceted projects that improved operational efficiency and financial performance."
            ]
        },
        {
            "position": "Software Developer Intern",
            "name": "Startup",
            "location": "San Francisco",
            "startDate": "June 2017",
            "endDate": "August 2017",
            "currentlyWorking": false,
            "highlights": [
                "Engineered innovative features and functionalities for the company's flagship mobile application, enhancing user experience and driving increased engagement.",
                "Fostered a collaborative environment, working closely with fellow interns on a strategic team project, leading to successful and timely completion.",
                "Effectively communicated project progress and key milestones to company stakeholders, demonstrating excellent presentation skills and contributing to transparent operations."
            ]
        },
        {
            "position": "Investment Banking Analyst Intern",
            "name": "Investment Bank",
            "location": "New York",
            "startDate": "June 2016",
            "endDate": "August 2016",
            "currentlyWorking": false,
            "highlights": [
                "Contributed to the development of sophisticated financial models for high-stakes client presentations, enhancing the firm's ability to provide strategic investment advice.",
                "Conducted in-depth research on emerging market trends, providing valuable insights that informed investment decisions and strategies.",
                "Prepared comprehensive and insightful materials for client meetings, demonstrating a strong understanding of financial concepts and client needs."
            ]
        }
    ],
    "education": [
        {
            "studyType": "Bachelor of Science",
            "area": "Computer Science",
            "institution": "University of California, Berkeley",
            "startDate": "2014",
            "score": "3.8 GPA",
            "endDate": "2018",
            "currentlyAttending": false,
            "highlights": [
                "Finished in the top 10% of the class.",
            ],
            'courses': ['Data Structures', 'Algorithms', 'Web Development']
        }
    ],
    "projects": [
        {
            "name": "Personal Website",
            "highlights": [
                "Created a personal website to showcase my portfolio and blog posts. Implemented responsive design for optimal viewing on all devices and integrated a Content Management System for easy updates.",
            ],
            "startDate": "2020-01-01",
            "endDate": "Present"
        }
    ],
    'otherActivities': [
        {
            'location': 'Missuaga, ON',
            'name': "Wounder Warriors Society",
            'position': 'VP of Marketing',
            'startDate': '2022',
            'endDate': 'Present',
            "highlights": [
                "Conceptualized and executed a personal website to effectively showcase my diverse portfolio and insightful blog posts, enhancing online visibility and engagement.",
                "Implemented cutting-edge responsive design principles, ensuring optimal viewing and seamless user experience across all devices.",
                "Seamlessly integrated the website with a Content Management System (CMS), facilitating easy and efficient content updates, and ensuring the site remains current and relevant."
            ],
          }
    ],
    "skills": [
        "JavaScript",
        "React",
        "Node.js",
        "SQL",
        "Git",
        "HTML/CSS",
        "Excel",
        "Financial Modeling",
        "Data Analysis",
        "Project Management",
        "Agile Methodologies",
        "UI/UX Design",
        "Leadership"
    ],
    "languages": [
        {
            "language": "English",
            "fluency": "Native or Bilingual Proficiency"
        },
        {
            "language": "Spanish",
            "fluency": "Professional Working Proficiency"
        }
    ],
    "interests": ["Reading", "Travel", "Cooking", "Hiking", "Photography", "Yoga"],
};