import axios from 'axios';



export const title_accept = "Acceptance";

export function defaultAcceptanceMessage(Name, Position, Company) {
    if (!Name) {
        Name = "[Name]";
    }

    return `Hi ${Name},

I am writing to formally accept your offer for the ${Position} position at ${Company}. I am very excited about the opportunity and look forward to contributing to the team.

I appreciate your assistance throughout the hiring process and for providing me with this wonderful opportunity.

Please let me know the next steps in the process.

Thank you once again.

Best regards,
[Your Name]
`;
}

export const additionalInfoAcceptancePlaceholder = "Add any details on why you are excited to join the company";

export const fetchAcceptanceMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit,
        MessageType: "Acceptance"
    };
    try {
        const response = await axios.post('/message/applicationMessage', data);

        return response.data.applicationMessage;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}
