import React, { useState, useEffect } from 'react';
import { Button, message, theme, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_INTERVIEW_OR_NEGOTIATION_PREP } from '../../../utils/mutations';

const NegotiationTips = ({ visible, onClose, app, updateNegotiation }) => {
    const [updateInterviewOrNegotiationPrep] = useMutation(UPDATE_INTERVIEW_OR_NEGOTIATION_PREP);

    const handleComplete = async () => {
        try {
            await updateInterviewOrNegotiationPrep({
                variables: {
                    id: app._id,
                    reviewedNegotiationTips: true,
                },
            });
            updateNegotiation(true);
            message.success('Negotiation Tips marked as complete');
            onClose();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Modal
            title="Negotiation Tips Beta"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button 
                onClick={handleComplete}
                className='button-color'
                >
                    Mark as Complete
                </Button>,
                <Button key="back" onClick={onClose}>
                    Return
                </Button>,
            ]}
            centered
        >
            <h3>How to Negotiate Your Salary</h3>
            <p>Here are some tips to help you negotiate your salary:</p>
            <ul>
                <li>Research the average salary for your position in your area.</li>
                <li>Consider your experience and skills when determining your salary expectations.</li>
                <li>Practice your negotiation skills before the call.</li>
                <li>Be prepared to discuss your salary expectations and provide reasons for your desired salary.</li>
                <li>Be confident and assertive during the negotiation process.</li>
                <li>Consider other benefits and perks in addition to salary.</li>
                <li>Be willing to negotiate and compromise to reach a mutually beneficial agreement.</li>
            </ul>
        </Modal>
    );
}

export default NegotiationTips;
