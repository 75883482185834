

export const backgroundJobCheck = async (statusCheckFunction, GetResultFunction, taskId) => {
    // Poll the server for the status of the task
    let taskStatus;
    const startTime = Date.now();
    const timeout = 5 * 60 * 1000; // 5 minutes in milliseconds
    do {
        try {
            let statusResponse = await statusCheckFunction(taskId);
            taskStatus = statusResponse.data.status;
        } catch (error) {
            console.error('Error checking task status:', error);
            throw error; // re-throw the error to be handled by the caller
        }
        if (taskStatus === 'processing') {
            // Check if the task has timed out
            if (Date.now() - startTime > timeout) {
                throw new Error('Task timed out');
            }
            // Wait a bit before checking again
            console.log('Task is still processing...');
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    } while (taskStatus === 'processing');

    if (taskStatus === "completed") {
        // Once the task is complete, get the result
        let resultResponse = await GetResultFunction(taskId);
        // Remove the unused variable declaration
        return resultResponse.data;
    }
    else {
        console.error('Task failed:', taskStatus);
        throw new Error('Task failed:', taskStatus);
    }
}

