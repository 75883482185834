import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'; // Import the appropriate query hook
import { QUERY_MY_APPLICATIONS } from '../../../utils/queries.js'
import { QUERY_MY_CONTACTS } from '../../../utils/queries.js';

import { Row, Col, Card, Divider } from 'antd';

const rowStyle = { marginBottom: '-10px' };

const MainStats = () => {
    const { data: myApps } = useQuery(QUERY_MY_APPLICATIONS);
    const { data: myContacts } = useQuery(QUERY_MY_CONTACTS);
    const [applications, setApplications] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (myApps) {
            setIsLoading(false);
            setApplications(myApps.myApplications);
        }
        if (myContacts) {
            setIsLoading(false);
            setContacts(myContacts.myContacts);
        }
    }, [myApps, myContacts]);

    //Total Number of Applications
    const totalApplications = applications.length;
    //Total Number of Applies
    const totalApplies = applications.filter(application => application.dateApplied !== null).length;
    //Total Number of Interviews
    const totalInterviews = applications.filter(application => application.recievedInterview === true).length;
    //Total Number of Offers
    const totalOffers = applications.filter(application => application.recievedOffer === true).length;
    //Total Number of Resumes Optimized
    const totalResumesOptimized = applications.filter(application => application.resumeOptimized === true).length;
    //Total Number of Cover Letters Optimized
    const totalCoverLettersOptimized = applications.filter(application => application.coverletterOptimized === true).length;

    //Total Number of Contacts
    const totalContacts = contacts.length;
    //Total Number of Networked Contacts
    const totalNetworkedContacts = contacts.filter(contact => contact.lastContacted !== null).length;
    //Total Number of Replies
    const totalReplies = contacts.filter(contact => contact.replied === true).length;

    return (
        <div>
            <Card
                title="Job Search Statistics"
            >
                <Row style={rowStyle}>
                    <Col >Applications: {totalApplications}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col>Applies: {totalApplies}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col >Interviews: {totalInterviews}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col>Offers: {totalOffers}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col >Resumes Optimized: {totalResumesOptimized}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col>Cover Letters Optimized: {totalCoverLettersOptimized}</Col>
                </Row>
                <Divider />
                <Row style={rowStyle}>
                    <Col>Contacts: {totalContacts}</Col>
                </Row>
                <Divider />
                <Row>
                    <Col>Networked Contacts: {totalNetworkedContacts}</Col>
                </Row>
                <Divider />
                <Row>
                    <Col>Replies: {totalReplies}</Col>
                </Row>
            </Card>
        </div>
    )
}

export default MainStats;
