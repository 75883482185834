import React from 'react';
import Preferences from '../../Profile/Preferences';
import '../stepsStyle.css'; // Import the CSS file for styling

const Step3 = () => {
    return (
        <div> {/* Add a container div with a class name */}
            <h1>Describe Your Desired Job</h1>
            <div className="wiz-container"> {/* Add a container div for centering */}
                <Preferences showBasicInfo={false} showJobSearchStatus={false} showTargetJob={true} showTargetJobExtraInfo={true} />
            </div>
        </div>
    );
}

export default Step3;