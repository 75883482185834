import React from "react";
import { Form, Input } from 'antd';


const TargetJob = () => {
    return (
        <div>
            <Form.Item name='Industry' label="Industry">
                <Input />
            </Form.Item>
            <Form.Item name='Desired Job Title' label="Desired Job Title">
                <Input />
            </Form.Item>
            <Form.Item name='Job Description 1' label="Job Description 1">
                <Input.TextArea />
            </Form.Item>
            <Form.Item name='Job Description 2' label="Job Description 2">
                <Input.TextArea />
            </Form.Item>
            <Form.Item name='Job Description 3' label="Job Description 3">
                <Input.TextArea />
            </Form.Item>
            <Form.Item name='Job Description 4' label="Job Description 4">
                <Input.TextArea />
            </Form.Item>
            <Form.Item name='Job Description 5' label="Job Description 5">
                <Input.TextArea />
            </Form.Item>
        </div>
    );
}

export default TargetJob;