import React, { useState, useEffect } from 'react';
import { Progress, Typography } from 'antd';

const { Title, Text } = Typography;


const LoadingMatches = ({ progress }) => {

    const tips = [
        "Welcome to Hiremize! We're excited to help you land your dream job.",
        "Hiremize offers a suite of features designed to help you land your dream job.",
        "A compelling resume is the first step towards success. We're here to assist you in crafting one.",
        "We're currently analyzing your resume and extracting as much information as we can. Your patience is appreciated during this process.",
        "Upon completion, we'll present you with the results. You'll then have the opportunity to edit your resume and fill in any missing details.",
        "If you're satisfied with the outcome, feel free to proceed and add your first job application.",
    ];

    const [currentTip, setCurrentTip] = useState(tips[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTip(tips[(tips.indexOf(currentTip) + 1) % tips.length]);
        }, 1000);

        return () => clearInterval(interval); // This is important to clear the interval when the component unmounts
    }, [currentTip]);


    

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <Title level={4}>Finding Matches</Title>
            <Text>Just a moment while we find the best opportunities for you.</Text>
            <Progress
                type='line'
                percent={progress}
                status="active"
                size={['100%', 25]}
                style={{ width: '80%', marginTop: '20px', marginLeft: '10%' }}
            // success={{ percent: progress === 100 ? 100 : 0, strokeColor: '#fcdc5c' }}
            />
            <div style={{ display: 'flex', alignItems: 'center', padding: '10px', width: '100%' }}>
                {/* <Spin tip="Reviewing" size="large" />
                    <Typography.Paragraph style={{ marginLeft: '20px', fontSize: '18px', color: '#333', flex: 1 }}>
                        {currentTip}
                    </Typography.Paragraph> */}
            </div>
        </div>
    )

}

export default LoadingMatches;

