import React from 'react';
import { Card, Row, Col } from 'antd';


const SkillsView = ({ skills }) => {

    return (
        <>
            {skills.map((entry, index) => (
                <Card key={index} style={{ marginBottom: '20px', width: '50%', justifyContent: 'center' }}>
                    <Row>
                            <p><strong>{entry}</strong></p>
                    </Row>
                </Card>
            ))}
        </>
    );
};

export default SkillsView;