import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_MY_COMPANIES } from '../../utils/queries.js';
import { Table, Space, Button, Drawer, Divider, Col, Badge } from 'antd'; // Import the necessary components
import { StarOutlined, StarFilled } from '@ant-design/icons'; // Import the specific icons
import { useMutation } from "@apollo/client";
import { FAVOURITE_COMPANY } from "../../utils/mutations.js";
import EditCompanyModal from '../../components/Modals/EditCompanyModal/index.js';
import ContactMessageModal from '../../components/Modals/ContactMessageModal/index.js';
import { UserContext } from '../../contexts/userContext.js'
import LastMessageModal from '../../components/Modals/LastMessageModal/index.js';
import { MailOutlined } from '@ant-design/icons';
import MailBoxModal from '../../components/Modals/MailBoxModal/index.js';

import { useLocation } from 'react-router-dom';

const { Column } = Table;

const CompanyList = ({ onAddclosed, openFilters, closeFilters }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { data, loading, refetch } = useQuery(QUERY_MY_COMPANIES);
    const [isLoading, setIsLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [favouriteCompany] = useMutation(FAVOURITE_COMPANY);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [isCompanyModalOpen, setisCompanyModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [fav, setFav] = useState(queryParams.get('cfav') || false);
    const [contacted, setContacted] = useState(queryParams.get('ccontacted') || 'all');
    const [replied, setReplied] = useState(queryParams.get('creplied') || 'all');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentContact, setCurrentContact] = useState(null);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [isMailBoxModalOpen, setisMailBoxModalOpen] = useState(false);
    const [modalMessageType, setModalMessageType] = useState("followUp");

    const { allMyEmails, user } = useContext(UserContext);


    const openCompanyModal = () => {
        setisCompanyModalOpen(true);
    };

    const closeCompanyModal = () => {
        setisCompanyModalOpen(false);
        refetch();
    };

    const openMessageModal = () => {
        setIsMessageModalOpen(true);
    };

    const closeMessageModal = () => {
        setIsMessageModalOpen(false);
        refetch();
    };

    const handleEdit = (company) => {
        openCompanyModal();
        setCurrentCompany(company);
    };

    if (!onAddclosed) {
        refetch();
    }

    useEffect(() => {
        if (data) {
            setIsLoading(false);
            setCompanies(data.myCompanies);
            console.log(data);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    console.log(companies);

    const favCompany = async (favStatus, id) => {
        try {
            await favouriteCompany({
                variables: {
                    id: id,
                    favourite: favStatus,
                }
            });
            refetch();
        } catch (err) {
            console.error("Error updating replied:", err);
        }
    }

    const handleFavClick = () => {
        setFav(!fav);
    };

    const handleContactedClick = (value) => {
        if (value === contacted) {
            setContacted('all');
            return;
        }
        setContacted(value);
    };

    const handleRepliedClick = (value) => {
        if (value === replied) {
            setReplied('all');
            return;
        }
        setReplied(value);
    }

    const handleSortClick = (value) => {
        setSortOrder(value);
    }

    const sortContacts = (contacts, order) => {
        return contacts.slice().sort((a, b) => {
            // if (order === 'asc') {
            //     return a.firstName.localeCompare(b.firstName);
            // } else {
            //     return b.firstName.localeCompare(a.firstName);
            // }
            const dateA = a.lastContacted;
            const dateB = b.lastContacted;

            if (order === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
    };

    const applyFilters = (contacts) => {
        const filterFunctions = [];

        if (fav) {
            filterFunctions.push((contact) => contact.favourite === true);
        }

        if (contacted === 'yes') {
            filterFunctions.push((contact) => contact.lastContacted !== null);
        } else if (contacted === 'no') {
            filterFunctions.push((contact) => contact.lastContacted === null);
        }

        return contacts.filter((contact) => filterFunctions.every((filterFn) => filterFn(contact)));
    };

    const filteredAndSortedContacts = sortContacts(
        applyFilters(companies), // Only difference People & No replied filter
        sortOrder
    );

    return (
        <div className="company-list-container">
            <div className="filter-sort-section">
                <Drawer
                    title="Filters & Sort"
                    placement={"right"}
                    onClose={closeFilters}
                    visible={openFilters}
                >
                    <Space direction="vertical" size="middle">
                        <Space>
                            <span>Favorites Only:</span>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleFavClick()}
                                className={fav === true ? 'active' : ''}
                            >
                                Favorites
                            </Button>
                        </Space>
                        <Divider />
                        <Space>
                            <span>Sort By Contact Date:</span>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleSortClick('asc')}
                            >
                                Ascending
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleSortClick('desc')}
                            >
                                Descending
                            </Button>
                        </Space>
                        <Divider />
                        <Space>
                            <span>Contacted:</span>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleContactedClick("yes")}
                                className={contacted === 'yes' ? 'active' : ''}
                            >
                                Yes
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => handleContactedClick("no")}
                                className={contacted === 'no' ? 'active' : ''}
                            >
                                No
                            </Button>
                        </Space>
                    </Space>
                </Drawer>
            </div>
            <Table dataSource={filteredAndSortedContacts} loading={isLoading} rowKey={record => record.id}>
                <Column
                    title="Favourite"
                    dataIndex="favourite"
                    key="favourite"
                    render={(_, record) => (
                        <>
                            <div className="favorite-icon" onClick={(e) => favCompany(!record.favourite, record._id)}>
                                {record.favourite ? <StarFilled style={{ color: "#fcdc5c" }} /> : <StarOutlined style={{ color: "#5ce4f4" }} />}
                            </div>
                        </>
                    )}
                />
                <Column
                    title="Company Name"
                    key="companyName"
                    render={(_, record) => (
                        <>
                            <div style={{ fontSize: '16px' }}>
                                {record.companyDetails.companyName}
                            </div>
                        </>
                    )}
                />
                <Column
                    title="Address"
                    key="fullAddress"
                    render={(_, record) => (
                        <>
                            <div style={{ fontSize: '12px' }}>
                                <>
                                    {record.companyDetails.companyAddress}<br />
                                    {record.companyDetails.companyCity} {record.companyDetails.companyStateOrProvince}
                                </>
                            </div>
                        </>
                    )}
                />
                <Column
                    title="Contact"
                    key="contact"
                    render={(_, record) => (
                        <Button className="button-color" onClick={() => { 
                            setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id))
                            openMessageModal(); 
                            setCurrentCompany(record) }}>Contact</Button>

                    )}
                />
                <Column title="More Info"
                    key="moreInfo"
                    render={(_, record) => (
                        <>
                            <div style={{ fontSize: '12px' }}>
                                <>
                                    Industry: {record.companyDetails.industry}<br />
                                    Size: {record.companyDetails.size}<br />
                                    Year Founded: {record.companyDetails.yearFounded}<br />
                                </>
                            </div>
                        </>
                    )}
                />
                <Column
                    title="Last Contacted"
                    // dataIndex="lastContacted"
                    key="lastContacted"
                    render={(_, record) => {
                        if (record.lastContacted || record.lastfollowUp) {
                            const latestDate = record.lastfollowUp ? record.lastfollowUp : record.lastContacted;
                            const date = new Date(parseInt(latestDate, 10));
                            const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                            return (
                                <>
                                    {/* {new Date(parseInt(lastContacted, 10)).toLocaleDateString()} */}
                                    <Badge
                                        count={allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id && email.read === false).length}
                                        style={{
                                            backgroundColor: 'red',
                                        }}
                                        offset={[0, 30]}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


                                            <LastMessageModal date={formattedDate} firstMessage={record.lastContactMessage} followUpMessage={record.lastfollowUpMessage} />
                                            {/* {allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id).length < 1 ? ( */}
                                                <>
                                                    <Button
                                                        style={{
                                                            marginTop: '4px',
                                                            width: '100%',
                                                            backgroundColor: record.lastfollowUpMessage ? '#fcdc5c' : undefined
                                                        }}
                                                        size='small'
                                                        onClick={() => {
                                                            setCurrentCompany(record);
                                                            setModalMessageType("followUp")
                                                            openMessageModal();
                                                        }}
                                                    >
                                                        Follow-Up
                                                    </Button>
                                                </>
                                            {/* ) : (
                                                <>
                                                    {allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id).length > 0 && (

                                                        <Button
                                                            style={{
                                                                marginTop: '4px',
                                                                width: '100%',
                                                                backgroundColor: '#2ca4cc',
                                                                color: 'white'
                                                            }}
                                                            size='small'
                                                            icon={<MailOutlined />}
                                                            onClick={() => {
                                                                setCurrentContact(record)
                                                                setSelectedEmails(allMyEmails.filter(email => email.userContactId && email.userContactId._id == record._id));
                                                                setisMailBoxModalOpen(true);
                                                            }}
                                                        >

                                                            Mail Box
                                                        </Button>


                                                    )}
                                                </>
                                            )} */}
                                        </div>
                                    </Badge>
                                </>
                            );
                        } else {
                            return "N/A";
                        }
                    }}
                />
                <Column title="" key="action" render={(text, record) => (
                    <Space size="middle">
                        <Button className="button-color" onClick={() => handleEdit(record.companyDetails)}>Edit</Button>
                    </Space>
                )} />
            </Table>
            {isCompanyModalOpen && (
                <EditCompanyModal company={currentCompany} onClose={closeCompanyModal} />
            )}
            {isMessageModalOpen && (
                <ContactMessageModal onClose={closeMessageModal} record={currentCompany} contact={"company"} messageType={modalMessageType} myEmails={selectedEmails}/>
            )}

            {isMailBoxModalOpen && (
                <MailBoxModal
                    visible={isMailBoxModalOpen}
                    onClose={() => setisMailBoxModalOpen(false)}
                    contact={currentContact}
                    myEmails={selectedEmails}
                />
            )}

        </div>
    )
}

export default CompanyList;