import React, {useState, useEffect} from 'react';
import WorkExperienceForm from '../../JobStrategy/ResumeInfo/WorkExperience/form'
import WorkExperienceView from '../../JobStrategy/ResumeInfo/WorkExperience/view'
import '../stepsStyle.css'; // Import the CSS file for styling
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';

const Step4 = () => {
    const { data, refetch } = useQuery(QUERY_ME);
    const [workHistory, setWorkHistory] = useState([]);

    useEffect(() => {
        if (data) {
            setWorkHistory(data.user.workHistory);
        }
    }, [data]);

    return (
        <div>
            <h1>Add Work Experience</h1>
            <div className="wiz-container">
                <WorkExperienceForm refreshFunction= {refetch}/>
            </div>
            <div className="wiz-container">
                <WorkExperienceView workHistory={workHistory} />
            </div>
        </div>
    );
}

export default Step4;