import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function FeedbackThankYou() {
    const { id, response } = useParams();
    console.log(id, response);

    useEffect(() => {
        async function submitFeedback() {
            await fetch(`/feedback/${id}/${response}`);
        }

        submitFeedback();
    }, [id, response]);

    return (
        <div>
            <h1>Thank You For Your Feedback</h1>
        </div>
    );
}

export default FeedbackThankYou;