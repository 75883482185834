import React from "react";
import { Card, Col, Row, Button, List } from "antd";

const Feedback = () => {
    return (
        <div>

            <Card title="Feedback" bordered={false} style={{ textAlign: "center" }}>
                <p>
                    As you use Hiremize, we offer feedback to help you improve your job search.
                </p>
                {/* <Button type="primary">Give Feedback</Button> */}
            </Card>
        </div>
    );
}

export default Feedback;