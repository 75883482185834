import React from "react";
import Opportunities from "../../layouts/OpportunitiesList";
import "./FindJobs.css";

const FindJobs = () => {
  return (
    <div className="container">
      {/* Title Section */}
      <div className="title-section">
        <div className="title-content">
          <h1 className="title">Opportunities</h1>
          <p className="blurb">
          Welcome to our Opportunities Board! Explore a diverse range of prospects including job postings, potential networking contacts, prospective companies, and upcoming networking events. Start shaping your future by finding your next big opportunity today.
          </p>
        </div>
      </div>

      {/* Job Postings List */}
      <div className="job-list-section">
        <Opportunities />
      </div>
    </div>
  );
};

export default FindJobs;
