import React, { useState } from 'react';
import { Card, Tag, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const EmailItem = ({ email, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSent = email.direction === 'sent'; 

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const getRecentMessage = (body) => {
    return body.split(/On .+ wrote:/)[0];
};

  return (
    <Card
      hoverable
      onClick={onClick}
      style={{
        marginBottom: 5,
        backgroundColor: isSent ? '#f0f8ff' : '#f5f5f5', 
      }}
    >
      <Card.Meta
        title={
          <div>
            {email.subject} {isSent && <Tag color="blue">Sent</Tag>}
            {!isSent && <Tag color="green">Received</Tag>}
          </div>
        }
        description={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                  {isExpanded ? getRecentMessage(email.body) : `${getRecentMessage(email.body).substring(0, 100)}`}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <small style={{ fontSize: '0.7em' }}>{new Date(parseInt(email.createdAt, 10)).toLocaleString()}</small>
                  {getRecentMessage(email.body).length > 100 && (
                      <Button
                          size='small'
                          icon={isExpanded ? <MinusOutlined /> : <PlusOutlined />}
                          onClick={toggleExpanded}
                      />
                  )}
              </div>
          </div>
      }
      />
    </Card>
  );
};

export default EmailItem;

