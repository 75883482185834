import React, { useState, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import ResumePreviewer from '../../Previewers/ResumePreviewer';


const CompareResumes = ({ visible, onClose, MainResume, EnhancedResume }) => {

    console.log(MainResume.resumeData._id);
    console.log(EnhancedResume.resumeData._id)



    return (
        <Modal
            title="Compare Resumes"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Return
                </Button>,
            ]}
            centered
            width={'95vw'}
            bodyStyle={{ height: '82vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <ResumePreviewer key={MainResume.resumeData._id} ResumeID={MainResume.resumeData._id} style={{ flex: 1 }} />
            <ResumePreviewer key={EnhancedResume.resumeData._id} ResumeID={EnhancedResume.resumeData._id} style={{ flex: 1 }} />
        </Modal>
    );
}

export default CompareResumes;
