import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Card, Divider } from 'antd';
import GettingStarted from '../../Guides/GettingStarted';
import HomeGuide from '../../Guides/Home';
import ResumeGuide from '../../Guides/Resumes';
import ApplicationTrackingGuide from '../../Guides/ApplicationTracking';
import NetworkingGuide from '../../Guides/Networking';
import LandDreamJobGuide from '../../Guides/LandDreamJob';

const gridStyle = {
    width: '33.333%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5em',
};

const NewHelpModal = ({ visible, onClose }) => {
    const [displayTitle, setDisplayTitle] = useState(true);
    const [width, setWidth] = useState('50vw');
    const [height, setHeight] = useState('50vh');
    const [showMainContent, setShowMainContent] = useState(true);
    const [showGettingStarted, setShowGettingStarted] = useState(false);
    const [showHome, setShowHome] = useState(false);
    const [showResumes, setShowResumes] = useState(false);
    const [showApplicationTracking, setShowApplicationTracking] = useState(false);
    const [showNetworking, setShowNetworking] = useState(false);
    const [showLandingYourDreamJob, setShowLandingYourDreamJob] = useState(false);

    const defaultWidth = '85vw';
    const defaultHeight = '85vh';
    
    const handleGuideClick = (guide) => {
        setShowMainContent(false);
        setDisplayTitle(false);
        if (guide === 'gettingStarted') {
            setShowGettingStarted(true);
            setWidth('60vw');
            setHeight('70vh');
        } else if (guide === 'home') {
            setShowHome(true);
            setWidth(defaultWidth );
            setHeight(defaultHeight);
        } else if (guide === 'resumes') {
            setShowResumes(true);
            setWidth(defaultWidth );
            setHeight(defaultHeight);
        } else if (guide === 'applicationTracking') {
            setShowApplicationTracking(true);
            setWidth(defaultWidth );
            setHeight(defaultHeight);
        } else if (guide === 'networking') {
            setShowNetworking(true);
            setWidth(defaultWidth );
            setHeight(defaultHeight);
        } else if (guide === 'landingYourDreamJob') {
            setShowLandingYourDreamJob(true);
            setWidth('80vw');
            setHeight('80vh');
        }
        // Add more else if conditions for other guides
    }

    const handleBackClick = () => {
        setShowMainContent(true);
        setShowGettingStarted(false);
        setShowHome(false);
        setShowResumes(false);
        setShowApplicationTracking(false);
        setShowNetworking(false);
        setShowLandingYourDreamJob(false);
        setDisplayTitle(true);
        setWidth('50vw');
        setHeight('50vh');
    }



    return (
        <Modal
            title={displayTitle ? <div style={{ textAlign: 'center', fontSize: '2em' }}>Guides</div> : <div style={{ fontSize: '1em' }}><Link onClick={() => handleBackClick()}>Back</Link></div>}
            centered
            visible={visible}
            onCancel={() => onClose()}
            closable={true}
            footer={null}
            width={width}
            bodyStyle={{ height: height, alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}
            maskClosable={true}
        >
            {showMainContent &&
            <Card style={{ height: '90%', marginTop: '10px' }} bodyStyle={{ height: '50%' }} title="">
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('gettingStarted')}>Getting Started</Card.Grid>
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('home')}>Home</Card.Grid>
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('resumes')}>Resumes</Card.Grid>
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('applicationTracking')}>Application Tracking</Card.Grid>
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('networking')}>Networking</Card.Grid>
                <Card.Grid style={gridStyle} onClick={() => handleGuideClick('landingYourDreamJob')}>Landing Your Dream Job</Card.Grid>
            </Card> }

            {showGettingStarted && <GettingStarted />}
            {showHome && <HomeGuide />}
            {showResumes && <ResumeGuide />}
            {showApplicationTracking && <ApplicationTrackingGuide />}
            {showNetworking && <NetworkingGuide />}
            {showLandingYourDreamJob && <LandDreamJobGuide />}

        </Modal>

    );

}

export default NewHelpModal;

