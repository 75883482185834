import React, { useState } from 'react';
import { InputNumber, Button } from "antd";


const Experience = ({ userData, setUserData, setStep }) => {
    const [experience, setExperience] = useState(userData.yearsExperience ? userData.yearsExperience : 0);
    const precision = 0.5;

    const handleSubmit = async (_) => {

      if (experience % precision !== 0) {
        setExperience(Math.round(experience / precision) * precision);
      }

      setUserData({
          ...userData,
          yearsExperience: experience,
      });

      setStep(1);
    };

    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '30px', borderRadius: '8px', border: '1px solid gainsboro' }}>
        
        <h3 style={{ marginTop: '20px' }}>Professional Experience</h3>
       
        <span style={{ fontSize: '18px', display: 'block', paddingBottom: '10px', marginTop: '30px' }}>
          How many years of professional experience, without counting internships, do you have as a {userData.targetJob}?
        </span>
        
        <div>
          <InputNumber min={0} max={30} step={precision} defaultValue={experience} onChange={setExperience} onPressEnter={handleSubmit} changeOnWheel />
          <Button onClick={handleSubmit}>Next</Button>
        </div>
      </div>
    );
}

export default Experience;