import React, { createContext, useState, useContext } from 'react';
import { Tour } from 'antd';

// Create the context
export const TourContext = createContext();

// Create the provider component
export function TourProvider({ children }) {
  const [tourSteps, setTourSteps] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState();

  const startTour = () => {
    setCurrentStep();
    setIsTourOpen(true);
  };

  const startTourStep = (stepIndex) => {
    if (stepIndex < tourSteps.length) {
      setCurrentStep(stepIndex);
      setIsTourOpen(true);
    }
  };

  const closeTour = () => {
    setIsTourOpen(false);
};

  return (
    <TourContext.Provider value={{ tourSteps, setTourSteps, startTour, startTourStep }}>
      {children}
      <Tour key={JSON.stringify(tourSteps)} steps={tourSteps} open={isTourOpen} current={currentStep} onClose={closeTour} onChange={(current) => setCurrentStep(current)}/>
    </TourContext.Provider>
  );
}

// Create a custom hook for accessing the tour context
export function useTour() {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
}