import React from 'react';
import Preferences from '../../Profile/Preferences';
import '../stepsStyle.css'; // Import the CSS file for styling

const Step1 = () => {
    return (
        <div> {/* Add a container div with a class name */}
            <h1>Basic Information</h1>
            <div className="wiz-container"> {/* Add a container div for centering */}
                <Preferences showBasicInfo={true} showJobSearchStatus={false} showTargetJob={false} showTargetJobExtraInfo={false} />
            </div>
        </div>
    );
}

export default Step1;