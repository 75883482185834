import React, { useEffect } from "react";
import { Modal, Form, Input, DatePicker, Button, Select } from "antd";
import { useMutation } from '@apollo/client';
import { UPDATE_MY_GOALS } from '../../../utils/mutations.js';

const { Option } = Select;

const UpdateGoalsModal = ({ onClose, currentApplicationGoal, currentNetworkGoal }) => {
    const [form] = Form.useForm();
    const [updateMyGoals] = useMutation(UPDATE_MY_GOALS);

    const handleSubmit = async () => {
        try {
            const formData = await form.validateFields();
            const applications = parseInt(formData.weeklyApplyGoal);
            const networking = parseInt(formData.weeklyNetworkingGoal);
            
            await updateMyGoals({
                variables: {
                    applications: applications,
                    networking: networking,
                },
            });
            form.resetFields();
            onClose();
        } catch (error) {
            console.error("Error updating goals:", error);
        }
    };

    return (
        <Modal
            visible={true}
            title="Update Goals"
            okText="Update"
            onCancel={onClose}
            onOk={handleSubmit}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="weeklyApplyGoal"
                    label="Weekly Application Goal"
                    initialValue={currentApplicationGoal}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="weeklyNetworkingGoal"
                    label="Weekly Networking Goal"
                    initialValue={currentNetworkGoal}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateGoalsModal;