import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { SAVE_COMPANY } from '../../../utils/mutations.js'
import { useQuery } from "@apollo/client";
import { QUERY_MY_COMPANIES } from "../../../utils/queries.js";
import { Card, Button, Row, Col } from 'antd'
import { GlobalOutlined, LinkedinOutlined, StarOutlined } from '@ant-design/icons';
import '../Card.css'
import './CompanyCard.css'
import useIsMobile from '../../../contexts/useIsMobile';


const CompanyItem = ({ company, showButton }) => {
    const [isSaved, setIsSaved] = useState(false);
    const { data: myCompanyData } = useQuery(QUERY_MY_COMPANIES);
    const [myCompanies, setMyCompanies] = useState([]);
    const [saveCompany, { error }] = useMutation(SAVE_COMPANY);
    const isMobile = useIsMobile();

    var displayButton = true
    if (showButton === false) {
        displayButton = false
    }


    useEffect(() => {
        if (myCompanyData) {
            setMyCompanies(myCompanyData.myCompanies);
        }
    }, [myCompanyData]);

    useEffect(() => {
        const isCompanySaved = myCompanies.some((Ccompany) => Ccompany.companyDetails._id === company._id);

        if (isCompanySaved) {
            setIsSaved(true);
        }
    }, [myCompanies, company._id]);


    const handleSave = async (id) => {
        try {
            await saveCompany({
                variables: { company: id }
            });
            setIsSaved(true);
        } catch (error) {
            // Handle any errors that occur during the mutation.
            console.error("Error saving company:", error);
        }
    }

    const actionButton =
        !isSaved ? (
            <Button onClick={() => handleSave(company._id)} type="primary">
                Save
            </Button>
        )
            : (
                <div>
                    <Button type="default">Saved</Button>
                </div>
            )

            const parts = company.industry.split(',');
            const truncatedIndustryText = isMobile && parts.length > 3
                ? parts.slice(0, 3).join(',') + '...'
                : company.industry;

    return (
        <Card className="posting-card">
            <div className="item-label">Company</div> {/* Add the contact label */}
            <Row>
                <Col span={10}>
                    <div className="logo-container">
                        <img src={company.logo} className="logo-image" alt="Company Logo" />
                    </div>
                </Col>
                <Col span={14}>
                    <div className="text-container">
                        <span className="company-name" style={{ fontSize: '18px' }}>{company.companyName}</span>
                        <div className="contact-details">
                            <div className="contact-info">
                                <span style={{ fontStyle: 'italic', fontSize: '12px' }}>{truncatedIndustryText}</span>
                            </div>
                            <div className="contact-info">
                                <span style={{ fontSize: '14px' }}>{company.companyCity} {company.companyStateOrProvince}</span>
                            </div>
                            <div className="contact-info" >
                                {<a href={company.companyWebsite} target="_blank" rel="noopener noreferrer" style={{ marginRight: company.companyLinkedIn ? '10px' : '0' }}><GlobalOutlined /></a>}
                                {<a href={company.companyLinkedIn} target="_blank" rel="noopener noreferrer" style={{ marginRight: company.companyGlassdoorUrl ? '10px' : '0' }}><LinkedinOutlined /></a>}
                                {company.companyGlassdoorUrl ? (
                                    <a href={company.companyGlassdoorUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: '' }}>
                                        <StarOutlined />
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {displayButton &&
                <>
                    <hr className="divider" />
                    <div className="action-container">{actionButton}</div>
                </>
            }
        </Card>
    );
}

export default CompanyItem;