import React, { useState, useEffect } from 'react';
import { Button, Modal, Space, Alert, Collapse, Flex, Progress } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import FixResumeModal from '../../ResumeFixModal';
//FixResumeModal is currently not implemented

const basicInfoStyle = { padding: '2.5px', margin: '0px', marginBottom: '2.5px', marginRight: '5%' }
const mainResultStyle = { padding: '5px', margin: '0px', marginBottom: '5px', marginRight: '5%' }
const subResultStyle = { padding: '0px', paddingLeft: '10px', margin: '0px', marginLeft: '5%', marginBottom: '2.5px', marginRight: '5%' }
const textStyles = { margin: '0px', fontSize: '0.9em' }

const { Panel } = Collapse;

const BaseResumeAnalysisComponent = ({ resume }) => {
    const [name, setName] = useState(false);
    const [address, setAddress] = useState(false);
    const [email, setEmail] = useState(false);
    const [phone, setPhone] = useState(false);
    const [linkedin, setLinkedin] = useState(false);
    const [education, setEducation] = useState(0);
    const totalEducation = 1; // My Standard
    const [educationHighlights, setEducationHighlights] = useState(0);
    const totalEducationHighlights = 1 // My Standard
    const [courses, setCourses] = useState(0);
    const totalCourses = 3; // My Standard
    const [workExperiences, setWorkExperiences] = useState(0);
    const totalWorkExperiences = 3; // My Standard
    const [workHighlights, setWorkHighlights] = useState(0);
    const totalWorkHighlights = 9; // My Standard
    const [otherActivities, setOtherActivities] = useState(0);
    const totalOtherActivities = 2; // My Standard
    const [otherActivityHighlights, setOtherActivityHighlights] = useState(0);
    const totalOtherActivityHighlights = 4; // My Standard
    const [projects, setProjects] = useState(0);
    const totalProjects = 2; // My Standard
    const [projectHighlights, setProjectHighlights] = useState(0);
    const totalProjectHighlights = 4; // My Standard
    const totalOtherActivitiesANDProjects = 1; // My Standard COMBINED
    const totalOtherActivityHighlightsANDProjectHighlights = 2; // My Standard COMBINED
    const [skills, setSkills] = useState(0);
    const [totalSkills, setTotalSkills] = useState(10); // My Standard
    const [languages, setLanguages] = useState(0);
    const [totalLanguages, setTotalLanguages] = useState(2); // My Standard
    const [interests, setInterests] = useState(0);
    const [totalInterests, setTotalInterests] = useState(6); // My Standard

    const [isLoading, setIsLoading] = useState(false);
    const [fixResumeModalVisible, setFixResumeModalVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (!resume) return;

        setIsLoading(true)

        setName(resume.basics?.name ? true : false);
        setAddress(resume.basics?.location?.city ? true : false);
        setEmail(resume.basics?.email ? true : false);
        setPhone(resume.basics?.phone ? true : false);
        setLinkedin(resume.basics?.profiles ? resume.basics.profiles.some(profile => profile.network === "LinkedIn") : false);

        setEducation(resume.education?.length || 0);
        const totalEducationHighlights = resume.education?.reduce((total, education) => total + (education.highlights ? education.highlights.length : 0), 0) || 0;
        setEducationHighlights(totalEducationHighlights);
        const totalCourses = resume.education?.reduce((total, education) => total + (education.courses ? education.courses.length : 0), 0) || 0;
        setCourses(totalCourses);

        setWorkExperiences(resume.work?.length || 0);
        const totalHighlights = resume.work?.reduce((total, workExperience) => total + (workExperience.highlights ? workExperience.highlights.length : 0), 0) || 0;
        setWorkHighlights(totalHighlights);

        setOtherActivities(resume.otherActivities?.length || 0);
        const totalOtherActivityHighlights = resume.otherActivities?.reduce((total, otherActivity) => total + (otherActivity.highlights ? otherActivity.highlights.length : 0), 0) || 0;
        setOtherActivityHighlights(totalOtherActivityHighlights);

        setProjects(resume.projects?.length || 0);
        const totalProjectHighlights = resume.projects?.reduce((total, project) => total + (project.highlights ? project.highlights.length : 0), 0) || 0;
        setProjectHighlights(totalProjectHighlights);

        setSkills(resume.skills?.length || 0);
        setLanguages(resume.languages?.length || 0);
        setInterests(resume.interests?.length || 0);

        setIsLoading(false)
    }, [resume, resume.education, resume.work, resume.otherActivities, resume.projects, resume.skills, resume.languages, resume.interests]);

    const handleType = (value, success, mid, informational) => {
        if (isLoading) {
            return;
        }
        if (value >= success) {
            return "success";
        }
        if (value >= mid) {
            return "warning";
        }
        if (informational) {
            return "info";
        }
        if (!fixResumeModalVisible && !isClosing) {
            setFixResumeModalVisible(true)
        }
        return "error";
    }

    const onCloseModal = () => {
        setIsClosing(true);
        setFixResumeModalVisible(false);
    };

    const basicSuccessCount = [name, address, email, phone, linkedin].filter(Boolean).length;
    const basicTotalCount = [name, address, email, phone, linkedin].length;

    const educationSuccessCount = [education >= totalEducation, educationHighlights >= totalEducationHighlights, courses >= totalCourses].filter(Boolean).length;
    const educationTotalCount = [totalEducation, totalEducationHighlights, totalCourses].length;

    const workSuccessCount = [workExperiences >= totalWorkExperiences, workHighlights >= totalWorkHighlights].filter(Boolean).length;
    const workTotalCount = [totalWorkExperiences, totalWorkHighlights].length;

    const otherActivitiesSuccessCount = [otherActivities + projects >= totalOtherActivitiesANDProjects, otherActivityHighlights + projectHighlights >= totalOtherActivityHighlightsANDProjectHighlights].filter(Boolean).length;
    const otherActivitiesTotalCount = [totalOtherActivitiesANDProjects, totalOtherActivityHighlightsANDProjectHighlights].length;

    const additionalInfoSuccessCount = [skills >= totalSkills, languages >= totalLanguages, interests >= totalInterests].filter(Boolean).length;
    const additionalInfoTotalCount = [totalSkills, totalLanguages, totalInterests].length;

    const totalSuccessCount = [basicSuccessCount, educationSuccessCount, workSuccessCount, otherActivitiesSuccessCount, additionalInfoSuccessCount].reduce((total, count) => total + count, 0);
    const totalTotalCount = [basicTotalCount, educationTotalCount, workTotalCount, otherActivitiesTotalCount, additionalInfoTotalCount].reduce((total, count) => total + count, 0);
    const totalPercentage = Math.round((totalSuccessCount / totalTotalCount) * 100);

    const renderSuccessIcon = (successCount, totalCount) => {
        return successCount === totalCount ? <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} /> : null;
    };


    return (
        <>
            <Progress type="circle" percent={totalPercentage} status="active" style={{marginBottom:'15px'}} />
            <Collapse >
                <Panel
                    header={
                        <>
                            {`Basic Information (${basicSuccessCount}/${basicTotalCount})`}
                            {renderSuccessIcon(basicSuccessCount, basicTotalCount)}
                        </>
                    }
                    key="1"
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%' }}>
                            <Alert
                                message={<p style={textStyles}>Name identified</p>}
                                type={name ? "success" : "error"}
                                showIcon
                                style={basicInfoStyle}
                            />
                            <Alert
                                message={<p style={textStyles}>Address has been located</p>}
                                type={address ? "success" : "error"}
                                showIcon
                                style={basicInfoStyle}
                            />
                        </div>
                        <div style={{ width: '50%' }}>
                            <Alert
                                message={<p style={textStyles}>Email identified</p>}
                                type={email ? "success" : "error"}
                                showIcon
                                style={basicInfoStyle}
                            />
                            <Alert
                                message={<p style={textStyles}>Phone Number Identified</p>}
                                type={phone ? "success" : "error"}
                                showIcon
                                style={basicInfoStyle}
                            />
                            <Alert
                                message={<p style={textStyles}>LinkedIn Identified</p>}
                                type={linkedin ? "success" : "error"}
                                showIcon
                                style={basicInfoStyle}
                            />
                        </div>
                    </div>
                </Panel>

                <Panel
                    header={
                        <>
                            {`Education Section (${educationSuccessCount}/${educationTotalCount})`}
                            {renderSuccessIcon(educationSuccessCount, educationTotalCount)}
                        </>
                    }
                    key="2">
                    <Alert
                        message={<p style={textStyles}>You currently have <strong>{education}</strong> education experiences. We recommend including at least <strong>{totalEducation}</strong></p>}
                        type={handleType(education, totalEducation, 999)}
                        showIcon
                        style={mainResultStyle}
                    />
                    <Alert
                        message={<p style={textStyles}>You've highlighted <strong>{educationHighlights}</strong> key points in your education section. Aim for at least <strong>{totalEducationHighlights}</strong></p>}
                        type={handleType(educationHighlights, totalEducationHighlights, 999)}
                        showIcon
                        style={subResultStyle}
                    />
                    <Alert
                        message={<p style={textStyles}>You've listed <strong>{courses}</strong> key courses. We suggest including at least <strong>{totalCourses}</strong></p>}
                        type={handleType(courses, totalCourses, 999, true)}
                        showIcon
                        style={subResultStyle}
                    />
                </Panel>
                <Panel
                    header={
                        <>
                            {`Work Experience Section (${workSuccessCount}/${workTotalCount})`}
                            {renderSuccessIcon(workSuccessCount, workTotalCount)}
                        </>
                    }
                    key="3">
                    <Alert
                        message={<p style={textStyles}>You've included <strong>{workExperiences}</strong> work experiences. We recommend a total of <strong>{totalWorkExperiences}</strong></p>}
                        type={handleType(workExperiences, totalWorkExperiences, 999)}
                        showIcon
                        style={mainResultStyle}
                    />
                    <Alert
                        message={<p style={textStyles}>You have <strong>{workHighlights}</strong> key points in your work experiences. Aim for at least <strong>{totalWorkHighlights}</strong> ({totalWorkHighlights / totalWorkExperiences} per experience)</p>}
                        type={handleType(workHighlights, totalWorkHighlights, 999)}
                        showIcon
                        style={subResultStyle}
                    />
                </Panel>
                <Panel
                    header={
                        <>
                            {`Other Activities and Projects (${otherActivitiesSuccessCount}/${otherActivitiesTotalCount})`}
                            {renderSuccessIcon(otherActivitiesSuccessCount, otherActivitiesTotalCount)}
                        </>
                    }
                    key="4">
                    <Alert
                        message={<p style={textStyles}>You've included <strong>{otherActivities + projects}</strong> other activities and/or projects. We recommend a total of <strong>{totalOtherActivitiesANDProjects}</strong></p>}
                        type={handleType(otherActivities + projects, totalOtherActivitiesANDProjects, 1)}
                        showIcon
                        style={mainResultStyle}
                    />
                    <Alert
                        message={<p style={textStyles}>You've highlighted <strong>{otherActivityHighlights + projectHighlights}</strong> key points in your activities and projects. Aim for at least <strong>{totalOtherActivityHighlightsANDProjectHighlights}</strong></p>}
                        type={handleType(otherActivityHighlights + projectHighlights, totalOtherActivityHighlightsANDProjectHighlights, 2)}
                        showIcon
                        style={subResultStyle}
                    />
                </Panel>
                <Panel
                    header={
                        <>
                            {`Additional Information (${additionalInfoSuccessCount}/${additionalInfoTotalCount})`}
                            {renderSuccessIcon(additionalInfoSuccessCount, additionalInfoTotalCount)}
                        </>
                    }
                    key="5">
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ width: '30%' }}>
                            <Alert
                                message={<p style={textStyles}>You have <strong>{skills}</strong> out of <strong>{totalSkills}</strong> Skills</p>}
                                type={handleType(skills, totalSkills, 6, true)}
                                showIcon
                                style={basicInfoStyle}
                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <Alert
                                message={<p style={textStyles}>You have <strong>{languages}</strong> out of <strong>{totalLanguages}</strong> Languages</p>}
                                type={handleType(languages, totalLanguages, 999, true)}
                                showIcon
                                style={basicInfoStyle}
                            />
                        </div>
                        <div style={{ width: '30%' }}>
                            <Alert
                                message={<p style={textStyles}>You have <strong>{interests}</strong> out of <strong>{totalInterests}</strong> Interests</p>}
                                type={handleType(interests, totalInterests, 3, true)}
                                showIcon
                                style={basicInfoStyle}
                            />
                        </div>
                    </div>
                </Panel>
            </Collapse>
            {/* {fixResumeModalVisible ? <FixResumeModal visible={fixResumeModalVisible} onClose={onCloseModal} /> : null} */}
        </>
    );

}

export default BaseResumeAnalysisComponent;