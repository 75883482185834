import React from 'react';
import { Divider } from 'antd';


const HomeGuide = () => {

    return (
        <div>
            <div>
                <h1>Welcome to the Home Page</h1>
                <p>Experience a personalized homepage featuring the latest statistics on your job hunt, dedicated feedback, and an intelligent task management system.</p>
                <Divider />
                <h3 style={{textAlign: 'center', marginBottom:'20px' }}>Never Get Lost In Your Job Hunt</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <img src="screen/todo.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} />
                    <div>
                        <h4>To Dos</h4>
                        <p>Our system intelligently monitors your job search activity, providing recommendations and prioritizing your next tasks.</p>
                    </div>
                </div>
                <Divider />
                <h3 style={{textAlign: 'center', marginBottom:'20px'}}>Monitor Your Progress</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    {/* <img src="screen/todo.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} /> */}
                    <div>
                        <h4>Stat Tracking</h4>
                        <p>Obtain comprehensive insights into your achievements throughout your job search journey.</p>
                    </div>
                    <img src="screen/stats.png" alt="Hiremize" style={{ height: '400px', marginLeft: '15%' }} />
                </div>
                {/* <h3 style={{textAlign: 'center' }}>Continous Improvement</h3> */}
                {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                    {/* <img src="screen/todo.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} /> */}
                    {/* <div>
                        <h4>Custom Feedback</h4>
                        <p>Use the navigation bar at the top of the page to explore different sections of the website. You can always return to the home page by clicking on the logo in the top left corner.</p>
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    );
}

export default HomeGuide;
