import axios from 'axios';


export function defaultLinkNetworkMessage(Name, Position, Company) {
    return `Hi ${Name}, I hope all is well. I was impressed with your profile and wanted to connect. I'm an aspiring ${Position} and am keen to learn about your experience at ${Company}. If you're open to it, I'd appreciate a chat. Thanks!`
}

export const additionalInfoNetowrkPlaceholder = "Highlight any common interests, education, experience, connection, job, etc.";
//add other placeholders

export const fetchMainContactMessage = async (CompanyOrPerson, Name, Theme, ThemeValue, Cold, AdditionalInfo, LinkLimit) => {
    const data = {
        CompanyOrPerson,
        Name,
        Theme,
        ThemeValue,
        Cold,
        AdditionalInfo,
        LinkLimit
    };
    try {
        const response = await axios.post('/message/maincontact', data);

        return response.data.mainContactMes;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}