import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import '../SearchBox.css';

const LocationSearchBox = ({ onSearch }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const possibleLocations = ['Vancouver, BC', 'Toronto, ON', 'New York, NY', 'Los Angeles, CA'];

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : possibleLocations.filter((location) =>
        location.toLowerCase().includes(inputValue)
      );
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setInputValue(suggestion);
    onSearch(suggestion); // Trigger the search when a suggestion is selected
  };

  const inputProps = {
    placeholder: 'Search location',
    value: inputValue,
    onChange: (event, { newValue }) => setInputValue(newValue),
  };

  return (
    <div className='locationSearch'>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  );
};

export default LocationSearchBox;

