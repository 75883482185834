import React, { useEffect, useState } from "react";
import Auth from "../../utils/auth";
import { Link } from "react-router-dom";
import "./nav.css";
import { Button } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import logo from '../../assets/logo.png';
import { useQuery } from '@apollo/client';
import { QUERY_MY_APPLICATIONS } from '../../utils/queries';

function Nav() {
  const { data, loading, refetch } = useQuery(QUERY_MY_APPLICATIONS);
  const [addFirstApp, setAddFirstApp] = useState(false);

  const handleLogout = () => {
    Auth.logout();
  };

  // console.log(data);

  useEffect(() => {
    if (data && (!data.myApplications || data.myApplications.length === 0)) {
      setAddFirstApp(true);
    }
  }, [data]);

  function showNavigation() {
    if (Auth.loggedIn()) {
      return (
        <ul className="flex-row">
          {addFirstApp ? (
            <li className="mx-1">
              <Link to="/applications" onClick={(event) => {
                event.preventDefault();
                window.location.href = '/applications';
              }}>
                <Button className="button-pulse">Add First Application</Button>
              </Link>
            </li>
          ) : null}
          <li className="mx-1">
            <Link to="/profile">
              <Button
                className="button-color"
                type="primary"
                icon={<UserOutlined />}
              >
                Profile
              </Button>
            </Link>
          </li>
          <li className="mx-1">
            <Button
              className="button-color"
              type="primary"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="flex-row">
          <li className="mx-1">
            <Link to="/signup">Signup</Link>
          </li>
          <li className="mx-1">
            <Link to="/login">Login</Link>
          </li>
        </ul>
      );
    }
  }

  return (
    <header className="flex-row px-1">
      <div className="logo">
        <h1 className="Hiremize-Title">
          <Link to="/" onClick={(event) => {
            event.preventDefault();
            window.location.href = '/';
          }}>
            <img src={logo} alt="HireMize" />
          </Link>
        </h1>
      </div>

      <nav className="nav-items" style={{ display: "flex", alignItems: "center" }}>
        {showNavigation()}
      </nav>
    </header>
  );

}

export default Nav;
