import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import EmailItem from '../EmailItem/index'
import 'react-chat-elements/dist/main.css';

import { useMutation } from '@apollo/client';
import { MARK_EMAIL_READ } from '../../../utils/mutations';


const MailBoxView = ({ myEmails }) => {

    const [selectedEmail, setSelectedEmail] = useState()
    const [markEmailRead] = useMutation(MARK_EMAIL_READ);

    useEffect(() => {
        myEmails.forEach(email => {
            if (!email.read) {
                markEmailRead({
                    variables: { id: email._id, read: true }
                });
            }
        }
        );
    }, [myEmails, markEmailRead]);


    return (

        <List
            className="email-list"
            dataSource={myEmails}
            renderItem={email => (
                <EmailItem email={email} onClick={() => setSelectedEmail(email)} />
            )}
        />
    )

}

export default MailBoxView