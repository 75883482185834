import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Dropdown, Menu } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_COVER_LETTER_BY_ID } from '../../utils/queries';
import CLPreviewer from '../../components/Previewers/CLPreviewer';
import './EditCoverLetter.css';
// import ResumeBasicInfo from '../../components/JobStrategy/ResumeInfo/BasicInfo';
import CoverLetterForm from '../../components/Forms/CoverLetterData';

import { useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;


const EditCoverLetter = () => {
    const { id } = useParams();
    const [currentCLData, setCurrentCLData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { error, data, refetch } = useQuery(QUERY_COVER_LETTER_BY_ID, { variables: { id: id } });
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCurrentCLData(data.getCoverLetterDataByID);
            setLoading(false);
        }
    }, [data]);

    console.log(currentCLData);


    const goBack = () => {
        navigate(-1);
      };

    if (loading) {
        return (
            <Layout className='editResumeMainContainer' style={{ background: '#fff' }}>
                <Sider width={800} style={{ background: '#fff', marginRight: '10px' }}>
                    <Spin size="large" />
                </Sider>
                <Content>
                    <Spin size="large" />
                </Content>
            </Layout>
        )
    }

    return (
        <div>
            <Link to="#" onClick={goBack} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>&#8592; Back To Job</Link>
            <Layout className='editResumeMainContainer' style={{ background: '#fff' }}>
                <Sider width={800} style={{ background: '#fff', marginRight: '10px' }}>
                    <CoverLetterForm currentCLData={currentCLData} clID={id} refetchCLData={refetch} />
                </Sider>
                <Content style={{ maxHeight: '2000px' }}>
                    <CLPreviewer intialHtmlData={''} currentCLData={currentCLData} clID={id} style={{ width: '100%', height: '100%' }} />
                </Content>
            </Layout>
        </div>
    )
}

export default EditCoverLetter;