import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { AutoComplete, Button } from "antd";

import { SET_WELCOME_WIZARD } from '../../utils/mutations.js';


const TargetJobLevel = ({ jobData, userData, setUserData, setStep }) => {
    const [setWelcomeWizard] = useMutation(SET_WELCOME_WIZARD);

    const handleSubmit = async (_) => {
        setUserData({
            ...userData,
            targetJobLevel: selectedLevel,
        });

        try {
            setWelcomeWizard({
                variables: { 
                    targetJob: userData.targetJob,
                    yearsExperience: userData.yearsExperience,
                    targetJobLevel: selectedLevel,
                },
            });
        } catch (err) {
            console.error(err);
        }

        setStep(1);
    };

    const selectedJob = jobData.allJobs.find(job => job.title === userData.targetJob);
    let options;
    let defaultLevel;
    let recommendedLevelsString;
    if (selectedJob) {
        options = selectedJob.levels.map((level, _) => ({
            value: level.level,
            label: level.level,
        }))

        // Find recommended job level given the user's years of experience
        const recommendedLevels = [];
        for (const level of selectedJob.levels) {
            if (userData.yearsExperience >= level.range.min && (level.range.max === null || level.range.max >= userData.yearsExperience)) {
                recommendedLevels.push(level.level);
                if (!defaultLevel || (defaultLevel.range.max !== null && defaultLevel.range.max < level.range.max)) {
                    defaultLevel = level;
                }
            }
        }
        if (recommendedLevels) {
            switch (recommendedLevels.length) {
                case 1:
                    recommendedLevelsString = recommendedLevels[0];
                    break;
                case 2:
                    recommendedLevelsString = recommendedLevels.join(' or ');
                    break;
                default:
                    recommendedLevelsString = recommendedLevels.slice(0, -1).join(', ') + ', or ' + recommendedLevels.slice(-1);
            }
        }

    // If not found, no suggestions (non-enhanced title) and autocomplete with all levels
    } else {
        const levelSet = new Set();
        jobData.allJobs.forEach(job => {
            job.levels.forEach(level => {
                levelSet.add(level.level);
            });
        });
        options = Array.from(levelSet).map(level => ({
            value: level,
            label: level,
        }));
    }

    const [selectedLevel, setSelectedLevel] = useState(defaultLevel ? defaultLevel.level : null);

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '30px', borderRadius: '8px', border: '1px solid gainsboro' }}>
            
            <h3 style={{ marginTop: '20px' }}>Current Level</h3>

            <span style={{ fontSize: '18px', display: 'block', paddingBottom: '10px', marginTop: '30px' }}>
                What is your current level as a {userData.targetJob}?
            </span>

            {recommendedLevelsString &&
                <span style={{ fontSize: '18px', display: 'block', paddingBottom: '10px', marginTop: '15px' }}>
                    The typical job level for a {userData.targetJob} with {userData.yearsExperience} years of professional experience is {recommendedLevelsString}
                </span>
            }
            
            <div>
                <AutoComplete
                    options={options}
                    onSearch={setSelectedLevel}
                    onSelect={setSelectedLevel}
                    onPressEnter={handleSubmit}
                    style={{ width: 200 }}
                    defaultValue={selectedLevel ? selectedLevel : null}
                />
                <Button onClick={handleSubmit} disabled={!selectedLevel}>Finish</Button>
            </div>
        </div>
    );
}

export default TargetJobLevel;