import React, { useState, useEffect } from "react";
//import GoogleLogin from 'react-google-login';
import { useMutation } from "@apollo/client";
import { UPDATE_GMAIL } from '../../../utils/mutations';
// import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Button } from 'antd'

import decode from 'jwt-decode';


const EmailSettings = () => {

    const [updateGmail] = useMutation(UPDATE_GMAIL);

    const googleLoginD = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            console.log(codeResponse);
            const tokens = await axios.post(
                'http://localhost:3001/auth/google', {
                code: codeResponse.code,
            });

            console.log(tokens.data);
            const email = decode(tokens.data.id_token).email
            console.log(email);

            updateGmail({ variables: { email: email, accessToken: tokens.data.access_token, refreshToken: tokens.data.refresh_token } });

        },
        onError: errorResponse => console.log(errorResponse),
    });


    // const responseGoogle = (response) => {
    //     console.log(response);

    //     const userInfo = decode(response.credential)

    //     console.log(userInfo);

    //     // Send the access token to your server to authenticate the user
    // };

    // const onFailure = (error) => {
    //     console.log(error);
    // };

    return (
        <div>
            {/* <GoogleLogin onSuccess={responseGoogle} onError={onFailure} /> */}
            <h1>Connect Email</h1>
            <p>Connect your email account to send application & emails directly through HireMize</p>
            <Button onClick={googleLoginD}>Connect Gmail</Button>
        </div>

    );
};

export default EmailSettings;
