import React from "react";
import { Modal, Input, Form, Row, Col, Button } from 'antd';
import { useMutation } from "@apollo/client";
import { ADD_COMPANY } from "../../../utils/mutations.js";
import { SAVE_COMPANY } from "../../../utils/mutations.js";

const AddCompanyModal = ({ onClose }) => {
    const [form] = Form.useForm();
    const [addCompany] = useMutation(ADD_COMPANY);
    const [saveCompany] = useMutation(SAVE_COMPANY);

    const handleSubmit = async () => {
        try {
            const formData = await form.validateFields();
            const newCompany = await addCompany({
                variables: {
                    companyName: formData.name,
                    companyWebsite: formData.website,
                    companyLinkedIn: formData.linkedIn,
                    companyEmail: formData.email,
                    companyPhone: formData.phone,
                    companyAddress: formData.address,
                    companyCity: formData.city,
                    companyStateOrProvince: formData.state,
                    industry: formData.industry,
                    size: formData.size,
                    yearFounded: formData.year,
                    postingStatus: "Private"
                },
            });
            //Save company to user's saved companies
            await saveCompany({
                variables: {
                  company: newCompany.data.addCompany._id
                },
            });

            form.resetFields();
            onClose();
        } catch (error) {
            console.error("Error editing contact:", error);
        }
    }


    return (
        <Modal
          title="Add Company"
          visible={true}
          // onOk={handleSubmit}
          onCancel={onClose}
          footer={[
            <Button key="back" onClick={onClose}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit} className='button-color'>
              Add
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Company Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Website" name="website">
              <Input />
            </Form.Item>
            <Form.Item label="LinkedIn" name="linkedIn">
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Email" name="email">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone" name="phone">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Address" name="address">
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="City" name="city">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State" name="state">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Industry" name="industry">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Size" name="size">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Year Founded" name="year">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
}

export default AddCompanyModal;