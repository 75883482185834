import React from 'react';
import { Divider } from 'antd';
import ReactPlayer from 'react-player'


const LandDreamJobGuide = () => {


    return (
        <div>
            <div>
                <h1>Land your dream job</h1>
                <p>Utilize all of what Hiremize has to offer for success!</p>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Simplify Your Job Search</h3>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                    {/* <img src="screen/jobSearch.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} /> */}
                    <div>
                        {/* <h4>Simplify Your Job Search</h4> */}
                        <ReactPlayer url='https://youtu.be/1_-n_8YnpjA' style={{height: '80%', width:'80%'}}playing={true} controls={true} playbackRate={1.25} />
                        {/* <p>Adhere to our proven strategies to secure your ideal job.</p> */}
                    </div>
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Prepare for the interview</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div>
                        <h4>Interview Prep</h4>
                        <p>Prepare for the interview by practicing common interview questions and answers. Record your answers and review them to improve your performance.</p>
                    </div>
                    {/* <img src="screen/interviewPrep.png" alt="Hiremize" style={{ height: '150px', marginRight: '20px' }} /> */}
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Negotiate your salary</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    {/* <img src="screen/salaryNegotiation.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} /> */}
                    <div>
                        <h4>Salary Negotiation</h4>
                        <p>Learn how to negotiate your salary and benefits effectively. Research the market rate for your position and prepare your negotiation strategy.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default LandDreamJobGuide;