import React, { useEffect, useState, useRef } from 'react';
import { Affix, Form, Input, Button, DatePicker, InputNumber, Select, Space, Checkbox, Collapse, Tooltip, Divider, Popconfirm } from 'antd';
import { Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPDATE_RESUME_DATA } from '../../../utils/mutations';
import { getTwoToneColor, setTwoToneColor, RobotFilled, RetweetOutlined, ThunderboltFilled } from '@ant-design/icons';
import { useTour } from '../../../contexts/tourContext';
import { fetchBulletPoint } from './BulletPointAPI';
const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;


setTwoToneColor('#5ce4f4');
getTwoToneColor(); // #eb2f96

const ResumeForm = ({ currentResumeData, theme, resumeID, refetchResumeData }) => {
    const [updateResumeData, { error }] = useMutation(UPDATE_RESUME_DATA);
    const [form] = Form.useForm();
    const [showBasicForm, setShowBasicForm] = useState(true);
    const [showBasicsLabelForm, setShowBasicsLabelForm] = useState(true);
    const [showBasicsSummaryForm, setShowBasicsSummaryForm] = useState(true);
    const [showEducationForm, setShowEducationForm] = useState(true);
    const [showWorkForm, setShowWorkForm] = useState(true);
    const [showInterestsForm, setShowInterestsForm] = useState(true);
    const [showLanguagesForm, setShowLanguagesForm] = useState(true);
    const [showProjectsForm, setShowProjectsForm] = useState(true);
    const [showPublicationsForm, setShowPublicationsForm] = useState(true);
    const [showSkillsForm, setShowSkillsForm] = useState(true);
    const [showVolunteerForm, setShowVolunteerForm] = useState(true);
    const [showAwardsForm, setShowAwardsForm] = useState(true);
    const [showCertificationsForm, setShowCertificationsForm] = useState(true);
    const [showOtherActivitiesForm, setShowOtherActivitiesForm] = useState(true);

    const [currentText, setCurrentText] = useState('');
    const [isClicked, setIsClicked] = useState(null);
    const [prevHighlightField, setPrevHighlightField] = useState(null);
    const [numberOfWords, setNumberOfWords] = useState(25);

    const { setTourSteps, startTour } = useTour();
    const ref = useRef(null);
    const hasAppliedRef = useRef(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const inputRefs = useRef(Array(15).fill().map(() => React.createRef()));

    //console.log("currentResumeData", currentResumeData);

    // const [rows, setRows] = useState(1);
    const [rows, setRows] = useState({});
    const rowStyles = { marginTop: '-10px' }
    const highlightStyles = { marginTop: '-5px', marginBottom: '10px' }
    const removeStyles = { display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '30px' }
    const removeIconStyles = { marginRight: '8px' }
    const highlightsIconsSectionStyles = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', marginLeft: '3px' }

    var downloadButton
    var inputbox
    if (process.env.ON_HEROKU) {
        downloadButton = '#PDF-Download-Button';
        inputbox = '#name'
    } else {
        downloadButton = '#PDF-Download-Button';
        inputbox = '#name'
    }

    useEffect(() => {
        console.log(theme)
    }, [theme]);

    useEffect(() => {
        // Define the tour steps
        setTourSteps([
            {
                target: () => ref1.current,
                title: 'Click To Expand',
                description: 'Click the section to expand and view the form fields.',
            },
            {
                target: () => document.querySelector(inputbox),
                title: 'Update Form Fields',
                description: 'Update the form fields with your information',
            },
            {
                target: () => ref3.current,
                title: 'Update Resume',
                description: 'Click the button to update your resume with the new information',
            },
            {
                target: () => ref4.current,
                title: 'Recommendations',
                description: 'View the recommendations to ensure the best resume possible',
            },
            {
                target: () => document.querySelector(downloadButton),
                title: 'Download',
                description: 'Click the download button to download a PDF of your resume',
            }
        ]);
    }, [setTourSteps]);


    useEffect(() => {
        // Check if it's the user's first visit to the OptimizeResume page
        if (!localStorage.getItem('hasVisitedEditResume')) {
            // Start the tour
            startTour();
            // Set 'hasVisitedOptimizeResume' to 'true' in local storage
            localStorage.setItem('hasVisitedEditResume', 'true');
        }
    }, []);

    useEffect(() => {
        if (theme === 'professional') {
            setShowBasicForm(true);
            setShowBasicsLabelForm(false);
            setShowBasicsSummaryForm(false);
            setShowEducationForm(true);
            setShowWorkForm(true);
            setShowInterestsForm(true);
            setShowLanguagesForm(true);
            setShowProjectsForm(true);
            setShowPublicationsForm(false);
            setShowSkillsForm(true);
            setShowVolunteerForm(false);
            setShowAwardsForm(false);
            setShowCertificationsForm(false);
            setShowOtherActivitiesForm(true);
        } else {
            // Show all forms
            setShowBasicForm(true);
            setShowBasicsLabelForm(true);
            setShowBasicsSummaryForm(true);
            setShowEducationForm(true);
            setShowWorkForm(true);
            setShowInterestsForm(true);
            setShowLanguagesForm(true);
            setShowProjectsForm(true);
            setShowPublicationsForm(true);
            setShowSkillsForm(true);
            setShowVolunteerForm(true);
            setShowAwardsForm(true);
            setShowCertificationsForm(true);
            setShowOtherActivitiesForm(true);
        }
    }, [theme]);

    useEffect(() => {
        if (currentResumeData.basics) {
            form.setFieldsValue({
                //Basics
                name: currentResumeData.basics.name,
                label: currentResumeData.basics.label,
                email: currentResumeData.basics.email,
                phone: currentResumeData.basics.phone,
                summary: currentResumeData.basics.summary,
                'location.address': currentResumeData.basics.location.address,
                'location.postalCode': currentResumeData.basics.location.postalCode,
                'location.city': currentResumeData.basics.location.city,
                'location.countryCode': currentResumeData.basics.location.countryCode,
                'location.region': currentResumeData.basics.location.region,
                profiles: currentResumeData.basics.profiles ? currentResumeData.basics.profiles.map(profile => ({
                    network: profile.network,
                    username: profile.username,
                    url: profile.url,
                })) : [],
                //Education
                education: currentResumeData.education ? currentResumeData.education.map(edu => ({
                    institution: edu.institution,
                    studyType: edu.studyType,
                    startDate: edu.startDate,
                    endDate: edu.endDate,
                    location: edu.location,
                    score: edu.score,
                    courses: edu.courses || [],
                    highlights: edu.highlights ? edu.highlights.map(highlight => ({ highlight })) : [],
                })) : [],
                //Work
                work: currentResumeData.work ? currentResumeData.work.map(work => ({
                    position: work.position,
                    name: work.name,
                    location: work.location,
                    startDate: work.startDate,
                    endDate: work.endDate,
                    currentlyWorking: work.currentlyWorking,
                    url: work.url,
                    highlights: work.highlights.map(highlight => ({ highlight })),
                })) : [],
                //Interests
                interests: currentResumeData.interests,
                //Languages
                languages: currentResumeData.languages ? currentResumeData.languages.map(lang => ({
                    language: lang.language,
                    fluency: lang.fluency,
                })) : [],
                //Projects (Test if this works)
                projects: currentResumeData.projects ? currentResumeData.projects.map(project => ({
                    name: project.name,
                    highlights: project.highlights.map(highlight => ({ highlight })),
                    url: project.url,
                    startDate: project.startDate,
                    endDate: project.endDate,
                })) : [],
                //Publications
                publications: currentResumeData.publications ? currentResumeData.publications.map(publication => ({
                    name: publication.name,
                    publisher: publication.publisher,
                    releaseDate: publication.releaseDate,
                    url: publication.url,
                    summary: publication.summary,
                })) : [],
                //Skills
                skills: currentResumeData.skills,
                //Volunteer
                volunteer: currentResumeData.volunteer ? currentResumeData.volunteer.map(volunteer => ({
                    organization: volunteer.organization,
                    position: volunteer.position,
                    url: volunteer.url,
                    startDate: volunteer.startDate,
                    endDate: volunteer.endDate,
                    summary: volunteer.summary,
                    highlights: volunteer.highlights ? volunteer.highlights.map(highlight => ({ highlight })) : [],
                })) : [],
                //Awards
                awards: currentResumeData.awards ? currentResumeData.awards.map(award => ({
                    award: award.award,
                })) : [],
                //Certifications
                certifications: currentResumeData.certifications ? currentResumeData.certifications.map(certification => ({
                    date: certification.date,
                    issuer: certification.issuer,
                    name: certification.name,
                    url: certification.url,
                })) : [],
                //Other Activities
                otherActivities: currentResumeData.otherActivities ? currentResumeData.otherActivities.map(activity => ({
                    name: activity.name,
                    position: activity.position,
                    location: activity.location,
                    url: activity.url,
                    startDate: activity.startDate,
                    endDate: activity.endDate,
                    highlights: activity.highlights ? activity.highlights.map(highlight => ({ highlight })) : [],
                })) : [],
            })
        }
    }, [currentResumeData]);

    const onFinish = () => {
        const values = form.getFieldsValue();
        // console.log(values);
        // console.log('Received values of form: ', values);
    };

    const handleUpdateResumeData = async () => {
        const values = form.getFieldsValue();
        // console.log(values);
        // console.log('Received values of form: ', values);
        //console.log('Received values of form: ', values);
        // console.log('Resume ID: ', resumeID);
        // Restructure values
        const mainData = {
            awards: values.awards,
            basics: {
                name: values.name,
                label: values.label,
                email: values.email,
                phone: values.phone,
                summary: values.summary,
                location: {
                    address: values['location.address'],
                    postalCode: values['location.postalCode'],
                    city: values['location.city'],
                    countryCode: values['location.countryCode'],
                    region: values['location.region'],
                },
                profiles: values.profiles,
            },
            certifications: values.certifications,
            education: values.education.map(edu => ({
                ...edu,
                courses: edu.courses,
                highlights: edu.highlights ? edu.highlights.map(highlight => highlight.highlight) : [],
            })),
            id: resumeID,
            interests: values.interests,
            languages: values.languages.map(lang => ({
                language: lang.language,
                fluency: lang.fluency,
            })),
            projects: values.projects ? values.projects.map(project => ({
                ...project,
                highlights: project.highlights ? project.highlights.map(highlight => highlight.highlight) : [],
            })) : [],
            publications: values.publications,
            skills: values.skills,
            volunteer: values.volunteer && values.volunteer.length > 0 ? values.volunteer.map(volunteer => ({
                ...volunteer,
                highlights: volunteer.highlights ? volunteer.highlights.map(highlight => highlight.highlight) : [],
            })) : [],
            work: values.work.map(job => ({
                ...job,
                highlights: job.highlights.map(highlight => highlight.highlight),
            })),
            otherActivities: values.otherActivities.map(activity => ({
                ...activity,
                highlights: activity.highlights ? activity.highlights.map(highlight => highlight.highlight) : [],
            })),
        };

        try {
            const { data } = await updateResumeData({
                variables: {
                    id: resumeID,
                    mainData,
                },
            });
            // Refetch resume data after the update
            console.log('Before refetch');
            refetchResumeData();
            console.log('After refetch');
        } catch (err) {
            console.error(err);
        }
    }

    const improveText = async (name, sectionIndex, highlightIndex, currentText, numberOfWords) => {
        console.log('Improving text for section:', sectionIndex);
        console.log('Improving text for field:', highlightIndex);
        console.log('currentText:', currentText);
        console.log('numberOfWords:', numberOfWords);

        // Simulate a call to your AI service
        // const improvedText = await new Promise(resolve => {
        //     setTimeout(() => {
        //         resolve(currentText + ' (improved)');
        //     }, 2000);
        // });

        const improvedText = await fetchBulletPoint(currentText, numberOfWords);

        const fieldToUpdate = form.getFieldValue(name); // get the current work items
        fieldToUpdate[sectionIndex].highlights[highlightIndex].highlight = improvedText; // update the highlight
        form.setFieldsValue({ fieldToUpdate }); // set the new work items

        console.log('Improved text:', improvedText);
    };



    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item style={{ position: 'sticky', top: 0, marginLeft: '80%', marginBottom: '5px', zIndex: 1 }}>
                <Affix>
                    <Button ref={ref3} className='button-color-premium' type="primary" htmlType="submit" onClick={handleUpdateResumeData}>
                        Update Resume
                    </Button>
                </Affix>
            </Form.Item>
            <Collapse defaultActiveKey={['1']} style={{ display: 'block' }}>
                {/* Basics Section */}
                {showBasicForm && (
                    <>
                        <Panel ref={ref1} header="Basic Resume Information" key="1">
                            <Form.Item ref={ref2} name="name" label="Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            {showBasicsLabelForm && (
                                <Form.Item name="label" label="Label">
                                    <Input />
                                </Form.Item>
                            )}
                            {/*Image Input Removed*/}
                            <Row gutter={[16, 1]} style={rowStyles}>
                                <Col span={12}>
                                    <Form.Item name="email" label="Email" rules={[{ type: 'email' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="phone" label="Phone">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*URL Input Removed*/}
                            {/* Resume Summary */}
                            {showBasicsSummaryForm && (
                                <Form.Item name="summary" label="Summary">
                                    <TextArea rows={4} />
                                </Form.Item>
                            )}
                            <Row gutter={[16, 1]} style={rowStyles}>
                                <Col span={12}>
                                    <Form.Item name="location.address" label="Address">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="location.city" label="City">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 1]} style={rowStyles}>
                                <Col span={8}>
                                    <Form.Item name="location.countryCode" label="Country Code">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="location.region" label="Region">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="location.postalCode" label="Postal Code">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.List name="profiles">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'network']}
                                                    fieldKey={[field.fieldKey, 'network']}
                                                >
                                                    <Input placeholder="Network" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'username']}
                                                    fieldKey={[field.fieldKey, 'username']}
                                                >
                                                    <Input placeholder="Username" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'url']}
                                                    fieldKey={[field.fieldKey, 'url']}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Profile
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Education Section - missing some fields*/}
                {showEducationForm && (
                    <>
                        <Panel header={
                            <span>
                                Education
                                <Tooltip title="Add your education details here. Include any relevant courses, academic achievements or highlights that would be beneficial for your profile.">
                                    <InfoCircleTwoTone ref={ref4} style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                            key="2" forceRender>
                            <Form.List name="education">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <div>
                                                <strong><p>Education {field.key + 1}</p></strong>
                                                <Row gutter={[16, 1]} style={rowStyles}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'institution']}
                                                            label="Institution"
                                                            fieldKey={[field.fieldKey, 'institution']}
                                                            rules={[{ required: true, message: 'Missing institution' }]}
                                                        >
                                                            <Input placeholder="Institution" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'location']}
                                                            label="Location"
                                                            fieldKey={[field.fieldKey, 'location']}
                                                            rules={[{ required: true, message: 'Missing location' }]}
                                                        >
                                                            <Input placeholder="Location" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 1]} justify="center" style={rowStyles}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'studyType']}
                                                            fieldKey={[field.fieldKey, 'studyType']}
                                                            rules={[{ required: true, message: 'Missing study type' }]}
                                                        >
                                                            <Input placeholder="Study Type" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'startDate']}
                                                            fieldKey={[field.fieldKey, 'startDate']}
                                                            rules={[{ required: true, message: 'Missing start date' }]}
                                                        >
                                                            <Input placeholder="Start Date" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'endDate']}
                                                            fieldKey={[field.fieldKey, 'endDate']}
                                                            rules={[{ required: true, message: 'Missing end date' }]}
                                                        >
                                                            <Input placeholder="End Date" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Form.List name={[field.name, 'highlights']} style={rowStyles}>
                                                    {(highlightFields, { add: addHighlight, remove: removeHighlight }) => (
                                                        <>
                                                            {highlightFields.map(highlightField => (
                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <Form.Item
                                                                        {...highlightField}
                                                                        name={[highlightField.name, 'highlight']}
                                                                        fieldKey={[highlightField.fieldKey, 'highlight']}
                                                                        rules={[{ required: true, message: 'Missing highlight' }]}
                                                                        style={{ ...highlightStyles, width: '100%' }}
                                                                    >
                                                                        <Input.TextArea
                                                                            placeholder="Highlight"
                                                                            rows={rows[highlightField.name] || 1}
                                                                            onFocus={() => setRows({ ...rows, [highlightField.name]: 4 })}
                                                                            onBlur={() => setRows({ ...rows, [highlightField.name]: 1 })}
                                                                        />
                                                                    </Form.Item>
                                                                    <MinusCircleOutlined style={highlightStyles} onClick={() => removeHighlight(highlightField.name)} />
                                                                </div>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => addHighlight()} block icon={<PlusOutlined />}>
                                                                    Add Highlight
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'courses']}
                                                    fieldKey={[field.fieldKey, 'courses']}
                                                    label="Courses"
                                                    style={{ marginBottom: '10px', marginTop: '-20px' }}
                                                >
                                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Add courses">
                                                        {/* Dynamically generate options based on courses array */}
                                                    </Select>
                                                </Form.Item>
                                                <span style={removeStyles}>
                                                    <MinusCircleOutlined style={removeIconStyles} onClick={() => remove(field.name)} />
                                                    Remove Education
                                                </span>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Education
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Work Section */}
                {showWorkForm && (
                    <>
                        <Panel header={
                            <span>
                                Work Experience
                                <Tooltip title="Include 3-4 work experiences that best showcase your skills and achievements. For each experience, we recommend including 3 key highlights or accomplishments.">
                                    <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                            key="3" forceRender>
                            <Form.List name="work">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <div key={field.key}>
                                                <strong><p>Work Experience {field.key + 1}</p></strong>
                                                <Row gutter={[16, 1]} style={rowStyles}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'position']}
                                                            fieldKey={[field.fieldKey, 'position']}
                                                            rules={[{ required: true, message: 'Missing position' }]}
                                                        >
                                                            <Input placeholder="Position" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'name']}
                                                            fieldKey={[field.fieldKey, 'name']}
                                                            rules={[{ required: true, message: 'Missing name' }]}
                                                        >
                                                            <Input placeholder="Organization Name" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 1]} style={rowStyles}>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'location']}
                                                            fieldKey={[field.fieldKey, 'location']}
                                                            rules={[{ required: true, message: 'Missing location' }]}
                                                        >
                                                            <Input placeholder="Location" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'startDate']}
                                                            fieldKey={[field.fieldKey, 'startDate']}
                                                            rules={[{ required: true, message: 'Missing start date' }]}
                                                        >
                                                            <Input placeholder="Start Date" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'endDate']}
                                                            fieldKey={[field.fieldKey, 'endDate']}
                                                            rules={[{ required: true, message: 'Missing end date' }]}
                                                        >
                                                            <Input placeholder="End Date" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'currentlyWorking']}
                                                            fieldKey={[field.fieldKey, 'currentlyWorking']}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox>Currently Working</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                {/* <Form.Item
                                            {...field}
                                            name={[field.name, 'url']}
                                            fieldKey={[field.fieldKey, 'url']}
                                        >
                                            <Input placeholder="URL" />
                                        </Form.Item> */}

                                                <Form.List name={[field.name, 'highlights']}>
                                                    {(highlightFields, { add: addHighlight, remove: removeHighlight }) => (
                                                        <>
                                                            {highlightFields.map(highlightField => (

                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <Form.Item
                                                                        {...highlightField}
                                                                        name={[highlightField.name, 'highlight']}
                                                                        fieldKey={[highlightField.fieldKey, 'highlight']}
                                                                        style={{ ...highlightStyles, width: '100%' }}
                                                                    >
                                                                        <Input.TextArea
                                                                            placeholder="Highlight"
                                                                            rows={rows[highlightField.name] || 1}
                                                                            onChange={(event) => {
                                                                                const text = event.target.value;
                                                                                setCurrentText(text); // Update the state variable
                                                                            }}
                                                                            onClick={(event) => {

                                                                                setRows(prevRows => {
                                                                                    // If there's a previously clicked text area, update its rows
                                                                                    if (prevHighlightField) {
                                                                                        prevRows = { ...prevRows, [prevHighlightField.name]: 1 };
                                                                                    }

                                                                                    // Update the rows of the clicked text area
                                                                                    return { ...prevRows, [highlightField.name]: 4 };
                                                                                });
                                                                                const text = event.target.value;
                                                                                setCurrentText(text); // Update the state variable
                                                                                setIsClicked(highlightField.name)
                                                                                setPrevHighlightField(highlightField);
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                    <div style={highlightsIconsSectionStyles}>
                                                                        {isClicked == highlightField.name &&
                                                                            <Popconfirm
                                                                                title="AI Bullet Point Rewriter"
                                                                                description={
                                                                                    <>
                                                                                        <p style={{ fontSize: '12px' }}>Would you like to improve this text?</p>
                                                                                        <div style={{ display: 'flex', alignItems:'baseline', justifyContent:'space-evenly' }}>
                                                                                            <p style={{ fontSize: '12px', marginRight: '10px' }}>Max Word Count:</p>
                                                                                            <InputNumber size="small" min={1} defaultValue={25} max={40} onChange={(value) => setNumberOfWords(value)}/>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                onConfirm={() => improveText('work', field.key, highlightField.name, currentText, numberOfWords)}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                icon={<CheckOutlined style={{ color: 'green' }} />}
                                                                            >
                                                                                <Tooltip title="Rewrite!" placement="right">
                                                                                <ThunderboltFilled style={{ ...highlightStyles, color: 'gold', fontSize:'20px' }} />
                                                                                </Tooltip>
                                                                            </Popconfirm>
                                                                        }
                                                                        <MinusCircleOutlined style={highlightStyles} onMouseDown={() => removeHighlight(highlightField.name)} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => addHighlight()} block icon={<PlusOutlined />}>
                                                                    Add Highlight
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <span style={removeStyles}>
                                                    <MinusCircleOutlined style={removeIconStyles} onClick={() => remove(field.name)} />
                                                    Remove Work Experience
                                                </span>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Work Experience
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Projects Section */}
                {showProjectsForm && (
                    <>
                        <Panel header={
                            <span>
                                Projects
                                <Tooltip title="Include any technical projects, apps, consulting projects, or any other relevant work that showcases your skills and experience. Between Projects & Other Activities, include 1-2 entries.">
                                    <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                            key="7" forceRender>
                            <Form.List name="projects">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                    rules={[{ required: true, message: 'Missing project name' }]}
                                                >
                                                    <Input placeholder="Project Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'url']}
                                                    fieldKey={[field.fieldKey, 'url']}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'startDate']}
                                                    fieldKey={[field.fieldKey, 'startDate']}
                                                >
                                                    <Input placeholder="Start Date" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'endDate']}
                                                    fieldKey={[field.fieldKey, 'endDate']}
                                                >
                                                    <Input placeholder="End Date" />
                                                </Form.Item>
                                                <Form.List name={[field.name, 'highlights']}>
                                                    {(highlightFields, { add: addHighlight, remove: removeHighlight }) => (
                                                        <>
                                                            {highlightFields.map(highlightField => (
                                                                <Space key={highlightField.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                    <Form.Item
                                                                        {...highlightField}
                                                                        name={[highlightField.name, 'highlight']}
                                                                        fieldKey={[highlightField.fieldKey, 'highlight']}
                                                                        rules={[{ required: true, message: 'Missing highlight' }]}
                                                                    >
                                                                        <Input placeholder="Highlight" />
                                                                    </Form.Item>
                                                                    <MinusCircleOutlined onClick={() => removeHighlight(highlightField.name)} />
                                                                </Space>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => addHighlight()} block icon={<PlusOutlined />}>
                                                                    Add Highlight
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>

                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Project
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}


                {/* Other Activities Section*/}
                {showOtherActivitiesForm && (
                    <>
                        <Panel header={
                            <span>
                                Other Activities
                                <Tooltip title="Include other activities such as extracurriculars, clubs, or any other activities that highlight your skills and interests. Between Projects & Other Activities, include 1-2 entries.">
                                    <InfoCircleTwoTone style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                            key="4" forceRender>
                            <Form.List name="otherActivities">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <div key={field.key}>
                                                <strong><p>Other Activity {field.key + 1}</p></strong>
                                                <Row gutter={[16, 1]} style={rowStyles}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'name']}
                                                            fieldKey={[field.fieldKey, 'name']}
                                                            rules={[{ required: true, message: 'Missing name' }]}
                                                        >
                                                            <Input placeholder="Organization Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'position']}
                                                            fieldKey={[field.fieldKey, 'position']}
                                                            rules={[{ required: true, message: 'Missing position' }]}
                                                        >
                                                            <Input placeholder="Position" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 1]} style={rowStyles} justify={'center'}>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'location']}
                                                            fieldKey={[field.fieldKey, 'location']}
                                                            rules={[{ required: true, message: 'Missing location' }]}
                                                        >
                                                            <Input placeholder="Location" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'startDate']}
                                                            fieldKey={[field.fieldKey, 'startDate']}
                                                            rules={[{ required: true, message: 'Missing start date' }]}
                                                        >
                                                            <Input placeholder="Start Date" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'endDate']}
                                                            fieldKey={[field.fieldKey, 'endDate']}
                                                            rules={[{ required: true, message: 'Missing end date' }]}
                                                        >
                                                            <Input placeholder="End Date" />
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                                {/* <Form.Item
                                            {...field}
                                            name={[field.name, 'url']}
                                            fieldKey={[field.fieldKey, 'url']}
                                        >
                                            <Input placeholder="URL" />
                                        </Form.Item> */}
                                                <Form.List name={[field.name, 'highlights']}>
                                                    {(highlightFields, { add: addHighlight, remove: removeHighlight }) => (
                                                        <>
                                                            {highlightFields.map(highlightField => (
                                                                <div style={{ display: 'flex', width: '100%' }}>
                                                                    <Form.Item
                                                                        {...highlightField}
                                                                        name={[highlightField.name, 'highlight']}
                                                                        fieldKey={[highlightField.fieldKey, 'highlight']}
                                                                        style={{ ...highlightStyles, width: '100%' }}
                                                                    >
                                                                        <Input.TextArea
                                                                            placeholder="Highlight"
                                                                            rows={rows[highlightField.name] || 1}
                                                                            onChange={(event) => {
                                                                                const text = event.target.value;
                                                                                setCurrentText(text); // Update the state variable
                                                                            }}
                                                                            onClick={(event) => {

                                                                                setRows(prevRows => {
                                                                                    // If there's a previously clicked text area, update its rows
                                                                                    if (prevHighlightField) {
                                                                                        prevRows = { ...prevRows, [prevHighlightField.name]: 1 };
                                                                                    }

                                                                                    // Update the rows of the clicked text area
                                                                                    return { ...prevRows, [highlightField.name]: 4 };
                                                                                });
                                                                                const text = event.target.value;
                                                                                setCurrentText(text); // Update the state variable
                                                                                setIsClicked(highlightField.name)
                                                                                setPrevHighlightField(highlightField);
                                                                            }}
                                                                        />

                                                                    </Form.Item>
                                                                    <div style={highlightsIconsSectionStyles}>
                                                                        {isClicked == highlightField.name &&
                                                                            <Popconfirm
                                                                                title="AI Bullet Point Rewriter"
                                                                                description={
                                                                                    <>
                                                                                        <p style={{ fontSize: '12px' }}>Would you like to improve this text?</p>
                                                                                        <div style={{ display: 'flex', alignItems:'baseline', justifyContent:'space-evenly' }}>
                                                                                            <p style={{ fontSize: '12px', marginRight: '10px' }}>Max Word Count:</p>
                                                                                            <InputNumber size="small" min={1} defaultValue={25} max={40} onChange={(value) => setNumberOfWords(value)}/>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                onConfirm={() => improveText('otherActivities', field.key, highlightField.name, currentText, numberOfWords)}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                icon={<CheckOutlined style={{ color: 'green' }} />}
                                                                            >
                                                                                <Tooltip title="Rewrite!" placement="right">
                                                                                <ThunderboltFilled style={{ ...highlightStyles, color: 'gold', fontSize:'20px' }} />
                                                                                </Tooltip>
                                                                            </Popconfirm>
                                                                        }
                                                                        <MinusCircleOutlined style={highlightStyles} onMouseDown={() => removeHighlight(highlightField.name)} />
                                                                    </div>
                                                                </div>


                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => addHighlight()} block icon={<PlusOutlined />}>
                                                                    Add Highlight
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <span style={removeStyles}>
                                                    <MinusCircleOutlined style={removeIconStyles} onClick={() => remove(field.name)} />
                                                    Remove Other Activity
                                                </span>
                                                <Divider />
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Other Activity
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}



                {/* Languages Section */}
                {showLanguagesForm && (
                    <>
                        <Panel header="Languages" key="6" forceRender>
                            <Form.List name="languages">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'language']}
                                                    fieldKey={[field.fieldKey, 'language']}
                                                    rules={[{ required: true, message: 'Missing language' }]}
                                                    style={highlightStyles}
                                                >
                                                    <Input placeholder="Language" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'fluency']}
                                                    fieldKey={[field.fieldKey, 'fluency']}
                                                    rules={[{ required: true, message: 'Missing fluency' }]}
                                                    style={highlightStyles}
                                                >
                                                    <Input placeholder="Fluency" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Language
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}



                {/* Publications Section */}
                {showPublicationsForm && (
                    <>
                        <Panel header="Publications" key="8" forceRender>
                            <Form.List name="publications">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                    rules={[{ required: true, message: 'Missing publication name' }]}
                                                >
                                                    <Input placeholder="Publication Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'publisher']}
                                                    fieldKey={[field.fieldKey, 'publisher']}
                                                    rules={[{ required: true, message: 'Missing publisher' }]}
                                                >
                                                    <Input placeholder="Publisher" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'releaseDate']}
                                                    fieldKey={[field.fieldKey, 'releaseDate']}
                                                >
                                                    <DatePicker placeholder="Release Date" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'url']}
                                                    fieldKey={[field.fieldKey, 'url']}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'summary']}
                                                    fieldKey={[field.fieldKey, 'summary']}
                                                >
                                                    <Input.TextArea placeholder="Summary" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button className='button-color' type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Publication
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Skills Section */}
                {showSkillsForm && (
                    <>
                        <Panel header="Skills" key="9" forceRender>
                            <Form.Item name="skills" label="Skills">
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Add skills">
                                    {/* Dynamically generate options based on skills array */}
                                </Select>
                            </Form.Item>
                        </Panel>
                    </>
                )}

                {/* Interests*/}
                {showInterestsForm && (
                    <>
                        <Panel header="Interests" key="5" forceRender>
                            <Form.Item name="interests">
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Add interests">
                                    {/* Dynamically generate options based on interests array */}
                                </Select>
                            </Form.Item>
                        </Panel>
                    </>
                )}

                {/* Volunteer Section */}
                {showVolunteerForm && (
                    <>
                        <Panel header="Volunteer" key="10" forceRender>
                            <Form.List name="volunteer">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'organization']}
                                                    fieldKey={[field.fieldKey, 'organization']}
                                                    rules={[{ required: true, message: 'Missing organization' }]}
                                                >
                                                    <Input placeholder="Organization" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'position']}
                                                    fieldKey={[field.fieldKey, 'position']}
                                                    rules={[{ required: true, message: 'Missing position' }]}
                                                >
                                                    <Input placeholder="Position" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'url']}
                                                    fieldKey={[field.fieldKey, 'url']}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'startDate']}
                                                    fieldKey={[field.fieldKey, 'startDate']}
                                                >
                                                    <DatePicker placeholder="Start Date" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'endDate']}
                                                    fieldKey={[field.fieldKey, 'endDate']}
                                                >
                                                    <DatePicker placeholder="End Date" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'summary']}
                                                    fieldKey={[field.fieldKey, 'summary']}
                                                >
                                                    <Input.TextArea placeholder="Summary" />
                                                </Form.Item>
                                                <Form.List name={[field.name, 'highlights']}>
                                                    {(highlightFields, { add: addHighlight, remove: removeHighlight }) => (
                                                        <>
                                                            {highlightFields.map(highlightField => (
                                                                <Space key={highlightField.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                    <Form.Item
                                                                        {...highlightField}
                                                                        name={[highlightField.name, 'highlight']}
                                                                        fieldKey={[highlightField.fieldKey, 'highlight']}
                                                                        rules={[{ required: true, message: 'Missing highlight' }]}
                                                                    >
                                                                        <Input placeholder="Highlight" />
                                                                    </Form.Item>
                                                                    <MinusCircleOutlined onClick={() => removeHighlight(highlightField.name)} />
                                                                </Space>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed" onClick={() => addHighlight()} block icon={<PlusOutlined />}>
                                                                    Add Highlight
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Volunteer
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Awards Section */}
                {showAwardsForm && (
                    <>
                        <Panel header="Awards" key="11" forceRender>
                            <Form.List name="awards">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'award']}
                                                    fieldKey={[field.fieldKey, 'award']}
                                                    rules={[{ required: true, message: 'Missing award' }]}
                                                >
                                                    <Input placeholder="Award" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Award
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}

                {/* Certifications Section */}
                {showCertificationsForm && (
                    <>
                        <Panel header="Certifications" key="12" forceRender>
                            <Form.List name="certifications">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'date']}
                                                    fieldKey={[field.fieldKey, 'date']}
                                                >
                                                    <DatePicker placeholder="Date" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'issuer']}
                                                    fieldKey={[field.fieldKey, 'issuer']}
                                                    rules={[{ required: true, message: 'Missing issuer' }]}
                                                >
                                                    <Input placeholder="Issuer" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'name']}
                                                    fieldKey={[field.fieldKey, 'name']}
                                                    rules={[{ required: true, message: 'Missing name' }]}
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'url']}
                                                    fieldKey={[field.fieldKey, 'url']}
                                                >
                                                    <Input placeholder="URL" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Certification
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Panel>
                    </>
                )}
            </Collapse>
        </Form>
    );
};

export default ResumeForm;
