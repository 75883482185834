import React, { useState, useEffect } from 'react';
import { Card, Space, Typography, InputNumber, Input, Checkbox, Select, AutoComplete, Button } from 'antd';
import { useMutation } from '@apollo/client';
import { SET_WELCOME_WIZARD } from '../../../../../utils/mutations.js'


const { Title, Text } = Typography;
const { Option } = Select;

const provinces = [
  { value: 'AB', name: 'Alberta' },
  { value: 'BC', name: 'British Columbia' },
  { value: 'MB', name: 'Manitoba' },
  { value: 'NB', name: 'New Brunswick' },
  { value: 'NL', name: 'Newfoundland and Labrador' },
  { value: 'NS', name: 'Nova Scotia' },
  { value: 'ON', name: 'Ontario' },
  { value: 'PE', name: 'Prince Edward Island' },
  { value: 'QC', name: 'Quebec' },
  { value: 'SK', name: 'Saskatchewan' },
  { value: 'NT', name: 'Northwest Territories' },
  { value: 'NU', name: 'Nunavut' },
  { value: 'YT', name: 'Yukon' }
];

const states = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];


const JobDetails = ({ jobData, userData, setUserData, setStep, disableNext }) => {
  const [experience, setExperience] = useState(userData.yearsExperience ? userData.yearsExperience : 0);
  const precision = 0.5;
  const [selectedLevel, setSelectedLevel] = useState(userData.targetJobLevel ? userData.targetJobLevel : 0);
  const [setWelcomeWizard] = useMutation(SET_WELCOME_WIZARD);
  const [country, setCountry] = useState(userData.targetLocation.countryCode ? userData.targetLocation.countryCode : null);
  const [provinceOrState, setProvinceOrState] = useState(userData.targetLocation.provinceOrState ? userData.targetLocation.provinceOrState : '');
  const [city, setCity] = useState(userData.targetLocation.city ? userData.targetLocation.city : '');
  const [openToRelocation, setOpenToRelocation] = useState(userData.targetLocation.openToRelocation ? userData.targetLocation.openToRelocation : false);
  const [officePreference, setOfficePreference] = useState(userData.targetLocation.officePreference ? userData.targetLocation.officePreference : undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [provinceOrStateValues, setProvinceOrStateValues] = useState(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // useEffect(() => {
  //   if (!experience || !selectedLevel) {
  //     disableNext(true);
  //   } else {
  //     disableNext(false);
  //   }
  // }, []);

  useEffect(() => {
    if (country && provinceOrState && city) {
      disableNext(false);
    } else {
      disableNext(true);
    }
  }, [country, provinceOrState, city]);


  const updateUserData_Experiences = (experience) => {
    setExperience(experience);
    setUserData({
      ...userData,
      yearsExperience: experience,
    });
  }

  const updateUserDate_TargetJobLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel);
    setUserData({
      ...userData,
      targetJobLevel: selectedLevel,
    });
  }

  const handleSubmit = async (_) => {

    if (experience % precision !== 0) {
      setExperience(Math.round(experience / precision) * precision);
    }

    setUserData({
      ...userData,
      targetJobLevel: selectedLevel,
      yearsExperience: experience,
    });

    try {
      setWelcomeWizard({
        variables: {
          targetJob: userData.targetJob,
          yearsExperience: experience,
          targetJobLevel: selectedLevel,
        },
      });
    } catch (err) {
      console.error(err);
    }

    setStep(1);
  };

  //let options;
  const [options, setOptions] = useState([])
  const [defaultLevel, setDefaultLevel] = useState(null);
  const [recommendedLevelsString, setRecommendedLevelsString] = useState('');

  useEffect(() => {
    const selectedJob = jobData && jobData.allJobs ? jobData.allJobs.find(job => job._id === userData.targetJob._id) : undefined;

    if (selectedJob) {
      setOptions(selectedJob.levels.map((level, _) => ({
        value: level.level,
        label: level.level,
      })))

      // Find recommended job level given the user's years of experience
      const recommendedLevels = [];
      for (const level of selectedJob.levels) {
        if (experience >= level.range.min && (level.range.max === null || level.range.max >= experience)) {
          recommendedLevels.push(level.level);
          if (!defaultLevel || (defaultLevel.range.max !== null && defaultLevel.range.max < level.range.max)) {
            setDefaultLevel(level)
            setSelectedLevel(level.level)
          }
        }
      }

      if (recommendedLevels.length > 0) {
        switch (recommendedLevels.length) {
          case 1:
            setRecommendedLevelsString(recommendedLevels[0]);
            break;
          case 2:
            setRecommendedLevelsString(recommendedLevels.join(' or '));
            break;
          default:
            setRecommendedLevelsString(recommendedLevels.slice(0, -1).join(', ') + ', or ' + recommendedLevels.slice(-1));
        }
      } else {
        setRecommendedLevelsString('');
      }
    }
  }, [experience]);

  const updateTargetLocation = (newLocation) => {
    setUserData({
      ...userData,
      targetLocation: {
        ...userData.targetLocation,
        ...newLocation
      }
    });
  };

  const handleCountryChange = (value) => {
    setCountry(value);

    if (value === 'US') {
      setProvinceOrStateValues(states);
    } else if (value === 'CA') {
      setProvinceOrStateValues(provinces);
    } else {
      setProvinceOrStateValues(null);
    }

    updateTargetLocation({ countryCode: value });
  };

  const handleProvinceOrStateChange = (value) => {
    setProvinceOrState(value);
    updateTargetLocation({ provinceOrState: value });
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    updateTargetLocation({ city: e.target.value });
  };

  const handleOpenToRelocationChange = (e) => {
    setOpenToRelocation(e.target.checked);
    updateTargetLocation({ openToRelocation: e.target.checked });
  };

  const handleOfficePreferenceChange = (value) => {
    setOfficePreference(value);
    updateTargetLocation({ officePreference: value });
  };

  const countries = [
    { value: 'US', name: 'United States' },
    { value: 'CA', name: 'Canada' },
    // { value: 'GB', name: 'United Kingdom' },
    // Add more countries as needed
  ];


  return (
    <>
      <div style={{ margin: '20px', borderRadius: '15px', maxHeight: '70vh', overflowY: 'auto' }}>
        <Space direction="vertical" size="large">
          <Title level={3}>Target Location Details</Title>

          <Space direction="horizontal" size="large">
            <div>
              <Select placeholder="Select Country" value={country} onChange={handleCountryChange} style={{ minWidth: '150px' }}>
                {countries.map((country) => (
                  <Option key={country.value} value={country.value}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              {/* <Input placeholder='Enter Province or State' value={provinceOrState} onChange={handleProvinceOrStateChange} /> */}
              <Select placeholder="Select Province or State" value={provinceOrState} onChange={handleProvinceOrStateChange} style={{ minWidth: '150px' }} disabled={!provinceOrStateValues}>
                {provinceOrStateValues && provinceOrStateValues.map((province) => (
                  <Option key={province.value} value={province.value}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Space>

          <Input placeholder="Enter City" value={city} onChange={handleCityChange} />

          <Space direction="horizontal" size="large">
            <Select placeholder="Select Job Level" value={selectedLevel ? selectedLevel : null} onChange={updateUserDate_TargetJobLevel} style={{ width: 200 }}>
              <Option value={"Intern"}>Intern (Student) </Option>
              <Option value={"Entry"}>Entry (Recent Graduate)</Option>
              <Option value={"Mid"}>Mid (2-3 Years)</Option>
              <Option value={"Senior"}>Senior (5+ Years)</Option>
            </Select>
          </Space>

          <Space direction="horizontal" size="large">
            <div>
              <Checkbox checked={openToRelocation} onChange={handleOpenToRelocationChange}>Open to Relocation</Checkbox>
            </div>
            <div>
              <Select placeholder="Select Office Preference" value={officePreference} onChange={handleOfficePreferenceChange} style={{ width: 200 }}>
                <Option value={null}>No Preference</Option>
                <Option value="Remote">Remote</Option>
                <Option value="InOffice">In-Office</Option>
                <Option value="Hybrid">Hybrid</Option>
              </Select>
            </div>
          </Space>

          {/* <Button type="primary" onClick={toggleVisibility} className='button-color' size='small'>
            {isVisible ? 'View Less' : 'View Additional Details'}
          </Button> */}
          {isVisible && (
            <>
              <Title level={3}>Professional Experience</Title>
              <Text>How many years of professional experience do you have as a {userData.targetJob.title}, excluding internships?</Text>
              <Space>
                <InputNumber min={0} max={30} step={precision} defaultValue={experience} onChange={(value) => updateUserData_Experiences(value)} onPressEnter={handleSubmit} changeOnWheel />
                {/* <Button type="primary" onClick={handleSubmit}>Next</Button> */}
              </Space>

              <Title level={3}>Current Level</Title>
              <Text>What is your current level as a {userData ? userData.targetJob.title : ''}?</Text>
              {recommendedLevelsString &&
                <Text>The typical job level for a {userData.targetJob.title} with {experience} years of professional experience is {recommendedLevelsString}</Text>
              }
              <Space>
                <AutoComplete
                  options={options}
                  onSearch={setSelectedLevel}
                  onSelect={setSelectedLevel}
                  onChange={(value) => updateUserDate_TargetJobLevel(value)}
                  onPressEnter={handleSubmit}
                  style={{ width: 200 }}
                  defaultValue={selectedLevel ? selectedLevel : null}
                />
                {/* <Button type="primary" onClick={handleSubmit} disabled={!selectedLevel}>Finish</Button> */}
              </Space>
            </>
          )}
        </Space>
      </div>
    </>
  );
}

export default JobDetails;