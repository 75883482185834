import React, {useState} from 'react';
import { Typography } from 'antd';

const { Text } = Typography;


const PersonalAdvice = ({ message }) => {

    return (
        <>
                <Text>{message}</Text>
        </>
    );
}

export default PersonalAdvice;

