import React, {useState, useEffect} from 'react';
import EducationForm from '../../JobStrategy/ResumeInfo/Education/form'
import EducationView from '../../JobStrategy/ResumeInfo/Education/view'
import '../stepsStyle.css'; // Import the CSS file for styling
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';

const Step5 = () => {
    const { data, refetch } = useQuery(QUERY_ME);
    const [education, setEducation] = useState([]);

    useEffect(() => {
        if (data) {
            setEducation(data.user.education);
        }
    }, [data]);

    return (
        <div> {/* Add a container div with a class name */}
            <h1>Add Education</h1>
            <div className="wiz-container"> {/* Add a container div for centering */}
                <EducationForm refreshFunction={refetch} />
            </div>
            <div className="wiz-container">
                <EducationView education={education} />
            </div>
        </div>
    );
}

export default Step5;