import React, {useState, useEffect}from "react";
import { useMutation } from '@apollo/client';
import { SAVE_CONTACT } from '../../../utils/mutations.js'
import { useQuery } from "@apollo/client";
import { QUERY_MY_CONTACTS } from "../../../utils/queries.js";
import { Card, Button, Row, Col } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import '../Card.css'


const ContactItem = ({ contact }) => {
    const [isSaved, setIsSaved] = useState(false);
    const { data: myContactsData } = useQuery(QUERY_MY_CONTACTS);
    const [myContacts, setMyContacts] = useState([]);
    const [saveContact, { error }] = useMutation(SAVE_CONTACT);


    useEffect(() => {
        if (myContactsData) {
            setMyContacts(myContactsData.myContacts);
        }
    }, [myContactsData]);

    useEffect(() => {
        const isContactSaved = myContacts.some((Ccontact) => Ccontact.contactDetails._id === contact._id);

        if (isContactSaved) {
            setIsSaved(true);
        }
    }, [myContacts, contact._id]);


    const handleSave = async (id) => {
        try {
            await saveContact({
                variables: { contact: id }
            });
            setIsSaved(true);
        } catch (error) {
            // Handle any errors that occur during the mutation.
            console.error("Error saving contact:", error);
        }
    }

    const actionButton =
        !isSaved ? (
            <Button onClick={() => handleSave(contact._id)} type="primary">
                Save
            </Button>
        )
            : (
                <div>
                    <Button type="default">Saved</Button>
                </div>
            )

    return (
        <Card className="posting-card">
            <div className="item-label">Contact</div> {/* Add the contact label */}
            <Row>
                <Col span={14}>
                    <div className="text-container">
                        <span className="company-name">{contact.firstName} {contact.lastName}</span>
                        <div className="contact-details">
                            <div className="contact-info">
                                <span>{contact.company ? contact.company.companyName : ""}</span>
                            </div>
                            <div className="contact-info">
                                <UserOutlined className="contact-icon" />
                                <span>{contact.jobTitle}</span>
                            </div>
                            <div className="contact-info">
                                <a href={contact.linkedIn}>LinkedIn</a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={10}>
                    <div className="logo-container">
                        <img src={"https://static.thenounproject.com/png/3237155-200.png"} className="logo-image" alt="Company Logo" />
                    </div>
                </Col>
            </Row>
            <hr className="divider" />
            {/* Add a horizontal line */}
            <div className="action-container">{actionButton}</div>
        </Card>
    );
}

export default ContactItem;