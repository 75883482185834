import React, { useContext } from 'react';
import { Layout, Menu, Tabs } from 'antd';
import { Route, Link, Routes, useNavigate } from 'react-router-dom';
import { FeatureFlagsContext } from '../../contexts/FeatureFlagsContext';

import AccountDetails from '../../components/Profile/AccountDetails';
import EmailSettings from '../../components/Profile/EmailSettings';
import Resume from '../../components/Profile/Resume';
import Preferences from '../../components/Profile/Preferences';
import Billing from '../../components/Profile/Billing';
import CoverLetter from '../../components/Profile/CoverLetter';
import useIsMobile from '../../contexts/useIsMobile';

const { Sider, Content } = Layout;
const { TabPane } = Tabs;

const Profile = () => {
  const featureFlags = useContext(FeatureFlagsContext);
  const url = "/profile";
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleTabChange = (key) => {
    switch (key) {
      case 'home':
        navigate('/');
        break;
      case 'account':
        navigate(`${url}/account`);
        break;
      case 'email':
        navigate(`${url}/email`);
        break;
      case 'resume':
        navigate(`${url}/resume`);
        break;
      default:
        break;
    }
  };


  const desktopMenu = (
    <Menu
      mode="vertical"
      theme="light"
      defaultSelectedKeys={['account']}
      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
    >
      <Menu.Item key="home">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="account">
        <Link to={`${url}/account`}>Account Details</Link>
      </Menu.Item>
      {featureFlags.profileSettings.email && (
        <Menu.Item key="email">
          <Link to={`${url}/email`}>Email</Link>
        </Menu.Item>
      )}
      <Menu.Item key="resume">
        <Link to={`${url}/resume`}>Resume</Link>
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Tabs defaultActiveKey="account" tabPosition="top" onChange={handleTabChange} style={{marginLeft: '10px'}}>
      <TabPane tab="Home" key="home" />
      <TabPane tab="Account Details" key="account" />
      {featureFlags.profileSettings.email && (
        <TabPane tab="Email" key="email" />
      )}
      <TabPane tab="Resume" key="resume" />
    </Tabs>
  );

  return (
    <>
    <>
    {isMobile ? mobileMenu : null}
    </>
    <Layout style={{height: '100vh'}}>
    {!isMobile ? (
      <Sider>
         {desktopMenu}
      </Sider>
    ) : null}
      <Content >
        <Routes>
          <Route path="/" element={<AccountDetails />} />
          <Route path="account" element={<AccountDetails />} />
          <Route path="email" element={<EmailSettings />} />
          <Route path="resume" element={<Resume />} />
          <Route path="coverletter" element={<CoverLetter />} />
          <Route path="preferences" element={<Preferences showBasicInfo={true} showJobSearchStatus={true} showTargetJob={true} showTargetJobExtraInfo={true} />} />
          <Route path="billing" element={<Billing />} />
        </Routes>
      </Content>
    </Layout>
    </>
  );
};

export default Profile;

