import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../utils/queries.js';
import { QUERY_MY_EMAILS } from '../utils/queries.js';
import { QUERY_MY_RESUME_DATA } from '../utils/queries';

export const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const { data, refetch: refetchUser } = useQuery(QUERY_ME);
    const [allMyEmails, setAllMyEmails] = useState([]);
    const { data: emailData, refetch: refetchEmails } = useQuery(QUERY_MY_EMAILS);
    const { data: dataResumeData, refetch: refetchMyResume } = useQuery(QUERY_MY_RESUME_DATA);
    const [myResumeData, setMyResumeData] = useState(null);
    const [myResumeDataLoading, setMyResumeDataLoading] = useState(true);

    useEffect(() => {
        if (data) {
            setUser(data.user);
            setUserLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (emailData) {
            setAllMyEmails(emailData.getEmailsByUserID);
        }
    }, [emailData]);

    useEffect(() => {

        if (dataResumeData) {
            if (dataResumeData.user && dataResumeData.user.myResumeData) {
                setMyResumeData(dataResumeData.user.myResumeData.mainData);
                setMyResumeDataLoading(false);
            } 
        }
    }, [dataResumeData]);


    return (
        <UserContext.Provider value={{ user, setUser, refetchUser, userLoading, allMyEmails, myResumeData, refetchMyResume, myResumeDataLoading }}>
            {children}
        </UserContext.Provider>
    );
}