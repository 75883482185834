import axios from 'axios';


export const fetchCraftMatcherEmail = async (education, workExperience, targetJob) => {

    const data = {
        education: education,
        workExperience: workExperience,
        targetJob: targetJob
    };

    try {

        const response = await axios.post(`/message/matcherEmail`, data);

        return response.data;

    } catch (error) {

        console.error("There was a problem with the fetch operation:", error.message);

    }

}