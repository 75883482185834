import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, DatePicker, Button, Select, Checkbox } from "antd";
import { Row, Col } from 'antd';
import { useMutation } from "@apollo/client";
import { ADD_JOB_POSTING } from "../../../utils/mutations.js";
import { ADD_APPLICATION } from "../../../utils/mutations.js";
import "./AddApplicationModal.css"; // Import your custom CSS for the modal
import { provincesStates } from '../../SearchBoxes/LocationSearchBox/provincesOrStates.js';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries.js';
import { useTour } from '../../../contexts/tourContext.js';
import { LinkedinOutlined, GoogleOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'

const { Option } = Select;

const AddApplicationModal = ({ onClose }) => {
    const [form] = Form.useForm();
    const [addJobPosting] = useMutation(ADD_JOB_POSTING);
    const [addApplication] = useMutation(ADD_APPLICATION);
    const { data, loading } = useQuery(QUERY_ME);
    const [isRemote, setIsRemote] = useState(false);
    var isFirstTimeAddApp = localStorage.getItem('isFirstTimeAddApp');

    const { setTourSteps, startTour } = useTour();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    useEffect(() => {
        // Define the tour steps
        setTourSteps([
            {
                target: () => ref1.current,
                placement: 'left',
                title: 'Add An Application From A Job Board',
                description:
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ fontSize: '20px' }}>
                            Ready to land your dream job? Start by exploring popular job boards like
                            <span style={{ color: '#0077B5', marginLeft: '10px' }}>
                                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ color: '#0077B5' }}>
                                    <LinkedinOutlined /> LinkedIn
                                </a>
                            </span> and
                            <span style={{ color: '#2164f3', marginLeft: '5px' }}>
                                <a href="https://www.indeed.com" target="_blank" rel="noopener noreferrer" style={{ color: '#2164f3'}}>
                                    Indeed
                                </a>
                            </span>. Your next opportunity awaits!
                        </div>

                        <ReactPlayer url='/Clips/FindingJobPosting.mp4' controls={false} playing={true} loop={true} width='80%' height='80%' />
                    </div>,
            },
            {
                target: () => ref2.current,
                title: 'Required Fields',
                description: 'We require the Company, Position, and Link fields to be filled out.'
            },
            {
                target: () => ref3.current,
                title: 'Click Save',
                description: 'Click the Save button to save the application.',
            }
        ]);
        //startTour();
    }, [ref1, setTourSteps]);

    useEffect(() => {
        isFirstTimeAddApp = localStorage.getItem('isFirstTimeAddApp');

        if (!isFirstTimeAddApp) {
            startTour();
            localStorage.setItem('isFirstTimeAddApp', 'true');
        }
    }, []);


    useEffect(() => {
        if (data) {
            // Use setFieldsValue to set initial values after data is loaded
            console.log(data);
            const { city, provinceOrState } = data.user.targetLocation;
            const fieldsToSet = {};
            
            if (city) {
                fieldsToSet['city'] = city;
            }
            
            if (provinceOrState) {
                fieldsToSet['provinceOrState'] = provinceOrState;
            }
            
            form.setFieldsValue(fieldsToSet);
        }
    }, [data, form]);


    const handleSubmit = async () => {
        try {
            const formData = await form.validateFields();
            const id = await addJobPosting({
                variables: {
                    postDate: formData.postDate ? formData.postDate.format("YYYY-MM-DD") : new Date().toISOString().slice(0, 10),
                    remote: formData.remote,
                    city: formData.city ? formData.city : "",
                    provinceOrState: formData.provinceOrState ? formData.provinceOrState : "",
                    company: formData.company,
                    position: formData.position,
                    link: formData.link,
                    status: "Private",
                },
            });

            console.log(id);

            await addApplication({
                variables: {
                    job: id.data.addJobPosting._id,
                    status: "Saved",
                },
            });

            form.resetFields();
            onClose();
            if (!isFirstTimeAddApp) {
                startTour();
                localStorage.setItem('isFirstTimeAddApp', 'true');
            }
        } catch (error) {
            console.error("Error adding application:", error);
        }
    };

    return (
        <Modal
            title="Add Application"
            visible
            onCancel={onClose}
            style={{ width: "100%" }} // Adjust the width of the modal
            className="custom-modal" // Apply a custom CSS class

            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button ref={ref3} className="button-color" key="submit" type="primary" onClick={handleSubmit}>
                    Save
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" className="center-form">
                <div ref={ref1}>
                    <div>
                        <Form.Item name="postDate" label="Post Date (Optional)">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>

                        <Row gutter={16} style={{ alignItems: 'center' }}>
                            <Col span={10}>
                                <Form.Item
                                    name="city"
                                    label="City"
                                >
                                    <Input disabled={isRemote} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="provinceOrState"
                                    label="Province/State"
                                >
                                    <Select
                                        placeholder="Select a Province/State"
                                        style={{ width: "100%" }}
                                        disabled={isRemote}
                                    >
                                        {provincesStates.map(code => (
                                            <Option key={code} value={code}>
                                                {code}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="remote" valuePropName="checked" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0px' }}>
                                    <Checkbox onChange={e => setIsRemote(e.target.checked)}>
                                        Remote
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div ref={ref2}>
                        <Form.Item
                            name="company"
                            label="Company"
                            rules={[{ required: true, message: "Please enter the company" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="position"
                            label="Position"
                            rules={[{ required: true, message: "Please enter the position" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="link"
                            label="Link To Job Posting"
                            rules={[
                                { required: true, message: "Please enter the link" },
                                { type: "url", message: "Please enter a valid URL" },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddApplicationModal;
