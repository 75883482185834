import React from "react";
import { Form, Select } from 'antd';
import { jobSearchStatuses } from './jobSearchStatus';

const { Option } = Select;

const JobSearchStatus = () => {
    return (
        <div>
                            <Form.Item name='Current Job Search Status' label="Current Job Search Status">
                    <Select
                        placeholder="Describe your current job search status"
                        style={{ width: "175%" }}
                    >
                        {jobSearchStatuses.map(code => (
                            <Option key={code} value={code}>
                                {code}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
        </div>
    );
}

export default JobSearchStatus;