import React, { useState, useEffect } from "react";
import Step1 from '../../components/JobStratWizard/Step1'
import Step2 from '../../components/JobStratWizard/Step2'
import Step3 from '../../components/JobStratWizard/Step3'
import Step4 from '../../components/JobStratWizard/Step4'
import Step5 from '../../components/JobStratWizard/Step5'
import Step6 from '../../components/JobStratWizard/Step6'
import Step7 from '../../components/JobStratWizard/Step7'
import { useQuery } from "@apollo/client";
import { QUERY_ME } from "../../utils/queries";
import { useMutation } from '@apollo/client';
import { UPDATE_CURRENT_JOBSTRAT_STEP } from '../../utils/mutations';
import { Button } from 'antd'
import './JobStratWizard.css'
import { Link } from "react-router-dom";


const JobStratWizard = () => {
    const { data } = useQuery(QUERY_ME);
    const [currentStep, setCurrentStep] = useState('');
    const [updateCurrentJobStratStep] = useMutation(UPDATE_CURRENT_JOBSTRAT_STEP);

    useEffect(() => {
        if (data) {
            if (data.user.currentJobStrategyStep === null || data.user.currentJobStrategyStep === undefined || data.user.currentJobStrategyStep === 0) {
                setCurrentStep(1);
            }
            else {
                setCurrentStep(data.user.currentJobStrategyStep);
            }
        }
    }, [data]);

    const handleNext = async () => {
        try {
            await updateCurrentJobStratStep({
                variables: {
                    currentJobStrategyStep: currentStep + 1
                }
            });
            setCurrentStep((prevStep) => prevStep + 1);
        } catch (e) {
            console.error(e);
        }
    };

    const handleBack = async () => {
        try {
            await updateCurrentJobStratStep({
                variables: {
                    currentJobStrategyStep: currentStep - 1
                }
            });
            setCurrentStep((prevStep) => prevStep - 1);
        } catch (e) {
            console.error(e);
        }
    };

    const handleFinish = async () => {
        try {
            await updateCurrentJobStratStep({
                variables: {
                    currentJobStrategyStep: 0
                }
            });
            setCurrentStep(0);
            
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="container">
            {/* Title Section */}
            <div className="title-section">
                <div className="title-content">
                    <h1 className="title">Job Strategy Wizard</h1>
                    <p className="blurb">
                        Welcome to our job strategy wizard. Let's get started.
                    </p>
                </div>
            </div>

            <div className="steps">
            {currentStep === 1 && <Step1 />}
            {currentStep === 2 && <Step2 />}
            {currentStep === 3 && <Step3 />}
            {currentStep === 4 && <Step4 />}
            {currentStep === 5 && <Step5 />}
            {currentStep === 6 && <Step6 />}
            {currentStep === 7 && <Step7 />}
            </div>

            <div className="wizard-buttons">
            {currentStep > 1 && (
                <Button onClick={handleBack}>Back</Button>
            )}
            {currentStep < 7 && (
                <Button onClick={handleNext}>Next</Button>
            )}
            {currentStep === 7 && (
                <Link to="/">
                <Button onClick={handleFinish}>Finish</Button>
                </Link>
            )}
            </div>
        </div>
    );
};

export default JobStratWizard;