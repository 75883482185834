import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
//Contexts
import { GoogleOAuthProvider } from '@react-oauth/google';


// Create a history object
const history = createBrowserHistory();

// Initialize GA4 with your Measurement ID
ReactGA.initialize('G-MM03BK06LM');

// Log the initial page load
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

// Log page changes
history.listen((location) => {
  ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
});

ReactDOM.render(
  <GoogleOAuthProvider clientId="612510450299-dv16667fibf1ip7o2th1072f402grqrm.apps.googleusercontent.com">
    <React.StrictMode>
      <Router>
          <App />
      </Router>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
