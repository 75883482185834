import React, { useState, useEffect } from 'react';
import { List, Button, Divider, Card } from 'antd';
import EmailItem from './EmailItem';
import EmailCompose from './EmailComposer';
import EmailButtons from './EmailButtons';
import 'react-chat-elements/dist/main.css';
import { useMutation } from '@apollo/client';
import { MARK_EMAIL_READ } from '../../utils/mutations';


const MailBox = ({ contactName, myEmails, userContactId, toEmail }) => {

    const [emails, setEmails] = useState(myEmails);
    const [newSubject, setNewSubject] = useState('');
    const [newBody, setNewBody] = useState('');
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [markEmailRead] = useMutation(MARK_EMAIL_READ);


    useEffect(() => {
        setEmails(myEmails);

    }, [myEmails]);


    useEffect(() => {
        emails.forEach(email => {
            if (!email.read) {
                markEmailRead({
                    variables: { id: email._id, read: true }
                });
            }
        }
        );
    }, []);

    //ToDo: get rid of placeholders
    const handleChatBoxUpdate = () => {
        if (newSubject.trim() && newBody.trim()) {
            setEmails([
                ...emails,
                {
                    direction: 'sent',
                    type: 'text',
                    text: newBody,
                    date: new Date(),
                    subject: newSubject,
                    body: newBody,
                    fromEmail: 'recipient@example.com',
                    toEmail: 'sender@example.com',
                    userId: 'user123',
                    headers: { 'X-Contact-ID': 'contact123' },
                },
            ]);
            setNewSubject('');
            setNewBody('');
        }
    };

    return (
        <div>
            <h2 style={{ marginBottom: '-10px' }}>{contactName}</h2>
            <Divider />
            <div style={{ margin: '0 auto' }}>
                <div style={{ height: '50vh', overflow: 'auto' }}>
                    <List
                        className="email-list"
                        dataSource={emails}
                        renderItem={email => (
                            <EmailItem email={email} onClick={() => setSelectedEmail(email)} />
                        )}
                    />
                </div>
                <Divider />
                <EmailCompose
                    newSubject={newSubject}
                    setNewSubject={setNewSubject}
                    newBody={newBody}
                    setNewBody={setNewBody}
                />
                <EmailButtons
                    handleChatBoxUpdate={handleChatBoxUpdate}
                    toEmail={toEmail}
                    subject={newSubject}
                    body={newBody}
                    userContactId={userContactId}
                />
            </div>
        </div>
    );
};

export default MailBox;



//React Chat Elements - https://detaysoft.github.io/docs-react-chat-elements/