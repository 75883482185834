import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Dropdown, Menu } from 'antd';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_RESUME_DATA_BY_ID } from '../../utils/queries';
import ResumePreviewer from '../../components/Previewers/ResumePreviewer';
import './EditResume.css';
// import ResumeBasicInfo from '../../components/JobStrategy/ResumeInfo/BasicInfo';
import ResumeForm from '../../components/Forms/ResumeData';

import { useNavigate } from 'react-router-dom';


const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;


const EditResume = ({ resumeId, closeModal }) => {
    const { id: routeId } = useParams();
    const id = routeId ? routeId : resumeId;
    const [currentResumeData, setCurrentResumeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { error, data, refetch } = useQuery(QUERY_RESUME_DATA_BY_ID, { variables: { id: id } });
    const [currentTheme, setCurrentTheme] = useState('professional');
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCurrentResumeData(data.getResumeDataByID.mainData);
            setLoading(false);
            console.log("refetching data");
        }
    }, [data, refetch]);

    console.log(currentResumeData);

    const goBack = () => {
        navigate(-1);
        setTimeout(() => window.location.reload(), 250)
    };

    if (loading) {
        return (
            <Layout className='editResumeMainContainer' style={{ background: '#fff' }}>
                <Sider width={800} style={{ background: '#fff', marginRight: '10px' }}>
                    <Spin size="large" />
                </Sider>
                <Content>
                    <Spin size="large" />
                </Content>
            </Layout>
        )
    }

    return (
        <div>
            {closeModal ?
                <Button onClick={() => closeModal(false)} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>Back to On Boarding</Button> :
                <Link to="#" onClick={goBack} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>&#8592; Back To Job</Link>
            }
            <Layout className='editResumeMainContainer' style={{ background: '#fff' }}>
                <Sider width={750} style={{ background: '#fff', marginRight: '10px', overflow: 'auto', height: '100vh' }}>
                    <ResumeForm currentResumeData={currentResumeData} resumeID={id} refetchResumeData={refetch} theme={currentTheme} />
                </Sider>
                <Content style={{ height: '100vh', overflow: 'auto' }}>
                    <ResumePreviewer intialHtmlData={''} currentResumeData={currentResumeData} appID={id} formThemeChanger={setCurrentTheme} style={{ width: '100%', height: '100%' }} />
                </Content>
            </Layout>
        </div>
    )
}

export default EditResume;