import React, { useState, useEffect } from "react";
import { Card, Button, Dropdown, Menu } from 'antd';
import HTMLPreviewer from "../HTMLPreviewer";
import { fetchGenerateHTML } from '../../../utils/CreateResumeAPIs/generateHTML';
import { fetchGeneratePDF } from '../../../utils/CreateResumeAPIs/generatePDF';
import SaveResumeModal from '../../Modals/SaveResumeModal';
import { useQuery } from '@apollo/client';
import { QUERY_RESUME_DATA_BY_ID } from '../../../utils/queries';
import { resumeSampleData } from '../../../utils/SampleData/resume';




const ResumePreviewer = ({ intialHtmlData, currentResumeData, appID, formThemeChanger, ResumeID }) => {

    const { error, data, refetch } = useQuery(QUERY_RESUME_DATA_BY_ID, {
        variables: { id: ResumeID },
        fetchPolicy: 'network-only',
        skip: !ResumeID
    });
    const [theme, setTheme] = useState('professional');
    const [htmlContent, setHtmlContent] = useState(intialHtmlData);
    const [loading, setLoading] = useState(true);
    const [saveResumeModalVisible, setSaveResumeModalVisible] = useState(false);
    const [currentResumeDataSelected, setCurrentResumeDataSelected] = useState(currentResumeData);
    const [Downloadloading, SetdownloadsetLoading] = useState(false);

    useEffect(() => {
        if (currentResumeData) {
            // Trigger the preview
            previewTheme(theme, currentResumeData);
            setCurrentResumeDataSelected(currentResumeData);
        }
    }, [currentResumeData]);

    useEffect(() => {
        if (data && data.getResumeDataByID) {
            let currentData = data.getResumeDataByID.mainData;
            setCurrentResumeDataSelected(currentData);
            intialHtmlDataLaunch(currentData, theme)
        }
    }, [data, refetch]);

    useEffect(() => {
        if (ResumeID) {
            refetch();
        }
    }, [ResumeID]);



    const intialHtmlDataLaunch = async (currentData, theme) => {
        try {
            const htmlData = await fetchGenerateHTML(currentData, theme);
            setHtmlContent(htmlData);
        }
        catch (error) {
            console.error("Error previewing resume:", error);
        } finally {
            setLoading(false);
        }
    }

    // If there is no Resume Data at all, let's preview a sample resume
    if (!currentResumeData && !ResumeID) {
        intialHtmlDataLaunch(resumeSampleData, theme);
    }


    const previewTheme = async (theme, resData) => {
        if (!resData) {
            resData = currentResumeDataSelected;
        }
        if (formThemeChanger) {
            formThemeChanger(theme);
        }
        setTheme(theme);
        setLoading(true);
        try {
            const htmlData = await fetchGenerateHTML(resData, theme);
            setHtmlContent(htmlData);
        }
        catch (error) {
            console.error("Error previewing resume:", error);
        } finally {
            setLoading(false);
        }
    }

    const downloadPDF = async () => {
        SetdownloadsetLoading(true);
        try {
            const response = await fetchGeneratePDF(currentResumeDataSelected, theme);
            //console.log(response.data.data);
            console.log('Testing');
            // Converting base64 to blob
            const binaryString = window.atob(response.data.data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            const blob = new Blob([bytes], { type: 'application/pdf' });
            console.log('Blob:', blob);
            const url = window.URL.createObjectURL(blob);
            console.log('URL:', url);
            window.open(url);
        }
        catch (error) {
            console.error("Error downloading resume:", error);
        } finally {
            SetdownloadsetLoading(false);
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="professional" style={{ fontWeight: 'bold' }} onClick={() => previewTheme('professional')}>Professional</Menu.Item>
            <Menu.Item key="even" onClick={() => previewTheme('even')}>Even (Beta)</Menu.Item>
            <Menu.Item key="ace" onClick={() => previewTheme('ace')}>Ace (Beta)</Menu.Item>
            <Menu.Item key="elegant" onClick={() => previewTheme('elegant')}>Elegant (Beta)</Menu.Item>
            <Menu.Item key="mantra" onClick={() => previewTheme('mantra')}>Mantra (Beta)</Menu.Item>
            <Menu.Item key="rickosborne" onClick={() => previewTheme('rickosborne')}>Rickosborne (Beta)</Menu.Item>
            <Menu.Item key="kendall" onClick={() => previewTheme('kendall')}>Kendall (Beta)</Menu.Item>
            <Menu.Item key="flat" onClick={() => previewTheme('flat')}>Flat (Beta)</Menu.Item>
            <Menu.Item key="modern" onClick={() => previewTheme('modern')}>Modern (Beta)</Menu.Item>
            <Menu.Item key="classy" onClick={() => previewTheme('classy')}>Classy (Beta)</Menu.Item>
            <Menu.Item key="slick" onClick={() => previewTheme('slick')}>Slick (Beta)</Menu.Item>
            <Menu.Item key="spartan" onClick={() => previewTheme('spartan')}>Spartan (Beta)</Menu.Item>
            <Menu.Item key="fresh" onClick={() => previewTheme('fresh')}>Fresh (Beta)</Menu.Item>
            <Menu.Item key="short" onClick={() => previewTheme('short')}>Short (Beta)</Menu.Item>
            <Menu.Item key="material" onClick={() => previewTheme('material')}>Material (Beta)</Menu.Item>
            <Menu.Item key="stackoverflow" onClick={() => previewTheme('stackoverflow')}>Stackoverflow (Beta)</Menu.Item>
            <Menu.Item key="eloquent" onClick={() => previewTheme('eloquent')}>Eloquent (Beta)</Menu.Item>
            <Menu.Item key="macchiato" onClick={() => previewTheme('macchiato')}>Macchiato (Beta)</Menu.Item>
        </Menu>
    );


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card title={`${theme.charAt(0).toUpperCase() + theme.slice(1)} Resume Preview`} style={{ width: '100%', height: '100%' }} bodyStyle={{ width: '100%', height: '90%' }}
                extra={

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Dropdown overlay={menu}>
                            <Button type="default">Select Theme</Button>
                        </Dropdown>
                        <Button id="PDF-Download-Button" className="button-color" type="primary" block loading={Downloadloading} onClick={downloadPDF}>Download PDF</Button>
                        {/* <Button type="primary" block loading={loading} onClick={() => setSaveResumeModalVisible(true)} disabled={appID === ''}>
                        Save Resume
                    </Button> */}
                    </div>
                }
            >
                <HTMLPreviewer html={htmlContent} />
            </Card>

            {saveResumeModalVisible && <SaveResumeModal onClose={() => setSaveResumeModalVisible(false)} visible={saveResumeModalVisible} appID={appID} />}
        </div>
    )
}

export default ResumePreviewer;