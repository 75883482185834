import React from 'react';
import { Button, Form, Input, Checkbox, DatePicker, Row, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { ADD_EDUCATION } from '../../../../utils/mutations';


const EducationForm = ({refreshFunction}) => {
    const [addEducation] = useMutation(ADD_EDUCATION);

    const [formEducation] = Form.useForm();

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 },
    }

    const handleFormSubmitEducation = async (values) => {
        try {
            // Call the addWorkHistory mutation with the form values
            await addEducation({
                variables: {
                    school: values['School'],
                    degree: values['Degree'],
                    fieldOfStudy: values['Field Of Study'],
                    currentlyAttending: values['Currently Attending'],
                    startDate: values['Start Date'],
                    endDate: values['End Date'],
                    gpa: values['gpa'],
                    description: values['Description']
                }
            });

            // Reset the form
            formEducation.resetFields();
            refreshFunction();

        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form
                {...layout}
                form={formEducation} // Pass the form instance to the Form component
                name={`nest-messages`}
                style={{ width: '100%' }}
                onFinish={handleFormSubmitEducation}
            >
                <Row>
                    <Form.Item name='School' label="School">
                        <Input style={{ minWidth: "300px" }} />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item name='Degree' label="Degree">
                        <Input style={{ minWidth: "300px" }} />
                    </Form.Item>
                </Row>
                <Row>
                    <Col span={8}>
                    <Form.Item name='Field Of Study' label="Field Of Study">
                        <Input style={{ minWidth: "200px" }} />
                    </Form.Item>
                    </Col>
                    <Col span={14}>
                    <Form.Item name='gpa' label="GPA">
                        <Input />
                    </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        <Form.Item name='Currently Attending' label="Currently Attending" valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='Start Date' label="Start Date">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name='End Date' label="End Date">
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
}

export default EducationForm;