import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, message, Steps, theme, Modal } from 'antd';
import ReactPlayer from 'react-player'


const GettingStarted = () => {

    const steps = [
        {
            title: 'Upload Your Resume',
            content:
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>Step 1: Upload Your Resume</h3>
                    <p>
                        Start by uploading your resume. This will help us populate your profile with your work experience and skills.
                    </p>
                    <ReactPlayer url='https://youtu.be/8oqX-tXaWV8' playing={true} controls={true} playbackRate={1.25} />
                </div>
        },
        {
            title: "Add Your First Application",
            content:
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>Step 2: Add Your First Application</h3>
                    <p>
                        Add your first application to get started. You can add applications from popular job boards like LinkedIn and Indeed.
                    </p>
                    <ReactPlayer url='https://youtu.be/rzJMhLJJdLI' playing={true} controls={true} playbackRate={1.25} />
                </div>
        },
        {
            title: 'Land Your Dream Job',
            content:
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>Step 3: Maximizing Hirmize</h3>
                    <p>
                        Discover how we can bolster your job hunting journey. From refining your resume to honing interview techniques and negotiation strategies, we're here to ensure you're fully equipped for success.
                    </p>
                    <ReactPlayer url='https://youtu.be/1_-n_8YnpjA' playing={true} controls={true} playbackRate={1.25} />
                </div>
        }
    ];

    const [current, setCurrent] = useState(() => {
        // Get the current step from the local storage
        const savedStep = localStorage.getItem('currentStep');
        // If there is a current step stored in the local storage, return it, otherwise return 0
        const initialStep = savedStep ? Number(savedStep) : 0;
        // Ensure that initialStep is within the bounds of the steps array
        return initialStep >= 0 && initialStep < steps.length ? initialStep : 0;
    });


    const prev = () => {
        setCurrent(current - 1);
    };


    const next = () => {
        if (current < steps.length - 1) {
            setCurrent(current + 1);
        } else {
            //onClose();
        }
    };

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        // lineHeight: '360px',
        // color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        // borderRadius: token.borderRadiusLG,
        // border: `1px dashed ${token.colorBorder}`,
        // marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };


    return (

        <>
            <Steps
                current={current}
                items={items}
                style={{ marginTop: 20 }}
            />
            <div style={contentStyle}>{steps[current].content}</div>
            <div
                style={{
                    marginTop: 14,
                }}
            >
                <div style={{ textAlign: 'center' }}>
                <Button onClick={prev} disabled={current === 0}>Previous</Button>
                {current < steps.length - 1 && <Button type="primary" className='button-color' onClick={next}>Next</Button>}
                </div>
            </div>
        </>
    );

}

export default GettingStarted;