import axios from 'axios';


//Background
export const startGenerateResumeTask = async (resumeParsedData, jdParsedData) => {

    const data = {
        resumeParsedData,
        jdParsedData
    };

    try {

        const response = await axios.post('/resume/generate-resume-task', data);

        return response;

    } catch (error) {

        console.error("There was a problem generating your resume", error.message);

    }
}

export const getGenerateResumeTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/resume/generate-resume-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your resume", error.message);

    }
}

export const getGenerateResumeTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/resume/generate-resume-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your resume", error.message);

    }
}




//Legacy
export const fetchGenerateResume = async (resumeParsedData, jdParsedData) => {

    const data = {
        resumeParsedData,
        jdParsedData
    };

    try {
        const response = await axios.post('/resume/generateResumeData', data);

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}