import axios from 'axios';


//Background
export const startGenerateMatchesTask = async (userID) => {

    const data = {
        userId: userID
    };

    try {

        const response = await axios.post('/generateMatches/find-matches-task', data);

        return response;

    } catch (error) {

        console.error("There was a problem generating your matches", error.message);

    }
}

export const getGenerateMatchesTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/generateMatches/find-matches-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your matches", error.message);

    }
}

export const getGenerateMatchesTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/generateMatches/find-matches-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your matches", error.message);

    }
}




//Legacy
export const fetchGenerateMatches = async (userID) => {

    const data = {
        userId: userID
    };

    try {
        const response = await axios.post(`/generateMatches/find`, data);

        return response.data;

    } catch (error) {

        console.error("There was a problem with the fetch operation:", error.message);

    }
}