import axios from 'axios';


//Background
export const startParseJDTask = async (jdText) => {

    const jobDescription = jdText;

    try {

        const response = await axios.post('/resume/parseJD-task', { jobDescription });

        return response;

    } catch (error) {

        console.error("There was a problem parse your resume", error.message);

    }
}

export const getParseJDTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/resume/parseJD-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your resume", error.message);

    }
}

export const getParseJDTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/resume/parseJD-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your resume", error.message);

    }
}






//Legacy
export const fetchParseJD = async (jdText) => {

    const jobDescription = jdText;

    try {

        const response = await axios.post('/resume/parseJD', { jobDescription });
        return response;

    } catch (error) {

        console.error("There was a problem parse your resume", error.message);

    }
}