import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';
import { Upload, Button } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';

const CoverLetter = () => {
    // State to store the selected file
    const [file, setFile] = useState(null);

    const { data, loading } = useQuery(QUERY_ME);
    const [userId, setUserId] = useState('');
    const [CoverLetterFile, setCoverLetterFile] = useState('');

    useEffect(() => {
        if (data) {
            setUserId(data.user._id);
        }
    }, [data]);

    // Function to handle file upload
    const handleUpload = async () => {
        // Create a FormData object to send the file to the server
        const formData = new FormData();
        formData.append('file', file);

        try {
            // Send a POST request to the server to upload the file
            // const response = await axios.post(`/users/${userId}/resume`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // });

            // // Log the response from the server
            // console.log(response.data);

            console.log("File uploaded");

            // Clear the form
            setFile(null);
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    };

    // Function to handle file download
    const handleDownload = async () => {
        try {

            // Send a GET request to the server to fetch the file
            // const response = await axios.get(`/users/${userId}/resume`, {
            //     responseType: 'blob'
            // });

            // // Create a URL for the file blob
            // const url = URL.createObjectURL(response.data);

            // setCoverLetterFile(url);

            // Open the file in a new window
            // window.open(url);

            console.log("File downloaded");
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    };

    return (
        <div>
            <h1>Cover Letter</h1>
            <div style={{marginTop: '20px', marginLeft: '40px'}}>

            <Upload
                accept=".doc,.docx,.pdf"
                beforeUpload={(file) => {
                    setFile(file);
                    return false;
                }}
            >
                <Button style={{marginBottom: '10px'}}>Select File</Button>
            </Upload>
            <Button onClick={handleUpload} disabled={!file} icon={<UploadOutlined />}>Upload Cover Letter</Button>
            <Button onClick={handleDownload} icon={<DownloadOutlined />}>Download Cover Letter</Button>
            </div>
        </div>
    );
};

export default CoverLetter;