import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';
import { Card, Typography, Upload, Button, Spin } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { notification, Space } from 'antd';
import { startParseResumeTask, getParseResumeTaskStatus, getParseResumeTaskResult } from '../../../utils/CreateResumeAPIs/resParse'
import { useMutation } from "@apollo/client";
import { ADD_RESUME_DATA } from "../../../utils/mutations";
import { ADD_MY_RESUME_DATA } from "../../../utils/mutations";
import { QUERY_MY_RESUME_DATA } from "../../../utils/queries";
import ResumePreviewer from "../../Previewers/ResumePreviewer";
import { backgroundJobCheck } from '../../../utils/helpers/background';
import { useTour } from '../../../contexts/tourContext';
import { resumeSampleData } from '../../../utils/SampleData/resume';
import BaseResumeAnalysis from '../../Modals/BaseResumeAnalysis';
import useIsMobile from '../../../contexts/useIsMobile';

const close = () => {
    console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
};




const Resume = () => {
    // State to store the selected file
    const [file, setFile] = useState(null);

    const { data, loading, refetch } = useQuery(QUERY_ME);
    const [userId, setUserId] = useState('');
    const [ResumeFile, setResumeFile] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [addResumeData, { error }] = useMutation(ADD_RESUME_DATA);
    const [addMyResumeData] = useMutation(ADD_MY_RESUME_DATA);
    const { loading: loadingResumeDate, error: errorResumeDate, data: dataResumeData } = useQuery(QUERY_MY_RESUME_DATA);
    const [api, contextHolder] = notification.useNotification();
    const [errorState, setErrorState] = useState(false);
    const [openResumeAnalysis, setOpenResumeAnalysis] = useState(false);
    // const [hasUploaded, setHasUploaded] = useState(false);
    const [isParsingDone, setIsParsingDone] = useState(false);

    const [resumeData, setResumeData] = useState(resumeSampleData);
    const [resumeID, setResumeID] = useState("");
    const isMobile = useIsMobile();

    const { setTourSteps, startTour, startTourStep } = useTour();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const tips = [
        "Welcome to Hiremize! We're excited to help you land your dream job.",
        "Hiremize offers a suite of features designed to help you land your dream job.",
        "A compelling resume is the first step towards success. We're here to assist you in crafting one.",
        "We're currently analyzing your resume and extracting as much information as we can. Your patience is appreciated during this process.",
        "Upon completion, we'll present you with the results. You'll then have the opportunity to edit your resume and fill in any missing details.",
        "If you're satisfied with the outcome, feel free to proceed and add your first job application.",
    ];

    const [currentTip, setCurrentTip] = useState(tips[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTip(tips[(tips.indexOf(currentTip) + 1) % tips.length]);
        }, 5000);

        return () => clearInterval(interval); // This is important to clear the interval when the component unmounts
    }, [currentTip]);


    useEffect(() => {
        if (dataResumeData) {
            if (dataResumeData.user && dataResumeData.user.myResumeData) {
                setResumeData(dataResumeData.user.myResumeData.mainData);
                //setResumeData(resumeSampleData);
                console.log('Resume Data Found')
            } else {
                setResumeData(resumeSampleData)
                console.log("No Resume Data")
            }
        }
        if (data && data.user) {
            setUserId(data.user._id);
            if (data.user.myResumeData) {
                setResumeID(data.user.myResumeData._id);
            }
        }
    }, [data, dataResumeData]);

    useEffect(() => {
        if (isParsingDone) {
            setOpenResumeAnalysis(true);
        }
    }, [isParsingDone]);


    const openNotificationWithIcon = (type, mes, description) => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="primary" size="small" onClick={() => api.destroy(key)}>
                    Got It!
                </Button>
            </Space>
        );
        api[type]({
            message: mes,
            description: description,
            duration: 0,
            btn,
            key,
            onClose: close,
        });
    };

    const steps = [
        {
            target: () => ref1.current,
            title: 'Upload Resume',
            description: 'Click this button to upload your resume. The file must be a .pdf file.',
        },
        {
            target: () => ref2.current,
            title: 'Autofill Resume Sections',
            description: 'Click this button to autofill your resume sections with the data from your uploaded resume.',
        },
        // {
        //     target: () => ref3.current,
        //     title: 'Create Resume',
        //     description: 'Click this button to create a new resume from scratch. If you already have a resume, you can skip this step.',
        // },
        // {
        //     target: () => ref4.current,
        //     title: 'Edit Resume',
        //     description: 'Click this button to edit your resume. Add or modify the data in your resume.',
        // },
    ];

    useEffect(() => {
        // Define the tour steps
        setTourSteps(steps);
    }, [setTourSteps]);

    useEffect(() => {
        // Check if it's the user's first visit to the OptimizeResume page
        if (!localStorage.getItem('hasVisitedProfileResume')) {
            // Start the tour
            startTour();
            // Set 'hasVisitedOptimizeResume' to 'true' in local storage
            localStorage.setItem('hasVisitedProfileResume', 'true');
        }
    }, []);

    // if (!hasUploaded) {
    //     startTourStep(0);
    // }

    // Function to handle file upload
    const handleUpload = async () => {
        // Create a FormData object to send the file to the server
        const formData = new FormData();
        formData.append('file', file);

        try {
            // Send a POST request to the server to upload the file
            const response = await axios.post(`/users/${userId}/resume`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Log the response from the server
            console.log(response.data);

            // Clear the form
            setFile(null);
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    };

    // Function to handle file download
    const handleDownload = async () => {
        try {

            // Send a GET request to the server to fetch the file
            const response = await axios.get(`/users/${userId}/resume`, {
                responseType: 'blob'
            });

            // Create a URL for the file blob
            const url = URL.createObjectURL(response.data);

            setResumeFile(url);

            // Open the file in a new window
            window.open(url);
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    };

    const handleParse = async () => {
        try {
            setErrorState(false);
            setIsLoading(true);
            // ---Start the resume parsing task and get a task ID---
            let taskResponse = await startParseResumeTask(file);
            let taskId = taskResponse.data.taskID;
            // Check the status of the task and get the result
            let response = await backgroundJobCheck(getParseResumeTaskStatus, getParseResumeTaskResult, taskId);
            let parsedResumeData = response.result;


            //Send Resume Data to DB
            const { data } = await addResumeData({ variables: { mainData: parsedResumeData, dataType: 'Basic Parse' } });
            //Link Resume Data to User's DB
            await addMyResumeData({ variables: { myResumeData: data.addResumeData._id } });

            setResumeID(data.addResumeData._id);

            //startTourStep(3);

            setResumeData(parsedResumeData);
            setIsParsingDone(true);

            openNotificationWithIcon('success', 'Autofill Complete!', (
                <>
                   Review our analysis and fix any red alerts. Please click on Edit Resume to view the result and add any missing information. <strong>If you are happy with the base resume result, please proceed to add your first application!</strong>
                </>
            ))

            //refetch();

            setIsLoading(false);

            //setOpenResumeAnalysis(true)
        } catch (error) {
            openNotificationWithIcon('error', 'Error Parsing Resume', 'Please try again, if the problem persists contact support or proceed to create your resume from scratch.')
            setIsLoading(false);
            setErrorState(true);
            console.error("There was a problem parsing your resume", error.message);
        }
    }

    const constructResumeFromScratch = async () => {
        try {
            if (resumeID) {

                openNotificationWithIcon('warning', 'Resume Already Exists', 'Please click on Edit Resume to view your existing resume.')
                return;
            }
            //Send Resume Data to DB
            const { data } = await addResumeData({ variables: { mainData: resumeSampleData, dataType: 'Scratch' } });
            //Link Resume Data to User's DB
            await addMyResumeData({ variables: { myResumeData: data.addResumeData._id } });
            setResumeID(data.addResumeData._id);

            startTourStep(3);

            openNotificationWithIcon('success', 'Resume Created!', 'Please click on Edit Resume to view your new resume.')


        } catch (error) {
            openNotificationWithIcon('error', 'Error Creating Resume', 'Please try again, if the problem persists contact support')
            console.error("There was a problem creating your resume", error.message);
        }
    }

    // const hasTriedUpload = () => {
    //     setHasUploaded(true);
    //     //store in local state
    //     localStorage.setItem('hasUploaded', 'true');
    // }


    return (
        <div>
            <h1>Resume</h1>
            <Space>{contextHolder}</Space>
            <div style={{ marginTop: '20px', marginLeft: '40px' }}>

                <Upload
                    accept=".doc,.docx,.pdf"
                    showUploadList={false}
                    beforeUpload={(file) => {
                        setFile(file);
                        startTourStep(1);
                        //hasTriedUpload()
                        return false;
                    }}
                >
                    <Button ref={ref1}>Select File</Button>
                </Upload>
                {/* <Button onClick={handleUpload} disabled={!file} icon={<UploadOutlined />}>Upload Resume</Button>
                <Button onClick={handleDownload} icon={<DownloadOutlined />}>Download Resume</Button> */}
            </div>

            <div ref={ref2} style={{ marginLeft: '40px' }}>
                <Button
                    ref={ref2}
                    onClick={handleParse}
                    disabled={!file}
                    style={{
                        backgroundColor: !file ? 'grey' : '#fcdc5c',
                        borderColor: !file ? 'grey' : '#fcdc5c',
                        color: 'black'
                    }}
                    icon={<DownloadOutlined />}
                >
                    Autofill Resume Section
                </Button>
                <Button ref={ref4} disabled={!resumeID} type="primary" loading={loading} style={{ marginTop: '15px', backgroundColor: !resumeID ? 'gray' : '#2ca4cc' }} >
                    <Link to={`/edit-resume/${resumeID}`}>Edit Resume</Link>
                </Button>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '40px' }}>
                {errorState && (
                    <Button ref={ref3} className='button-color' onClick={constructResumeFromScratch}>
                        Construct Resume from Scratch
                    </Button>
                )}
            </div>

            {isLoading &&
                <Card style={{ marginTop: '20px', marginLeft: '40px', marginRight: '40px', padding: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                        <Spin tip="Reviewing" size="large" />
                        <Typography.Paragraph style={{ marginLeft: '20px', fontSize: '18px', color: '#333' }}>
                            {currentTip}
                        </Typography.Paragraph>
                    </div>
                    <Typography.Title level={4} style={{ marginTop: '20px' }}>
                        Reviewing Your Resume
                    </Typography.Title>
                    <Typography.Paragraph>
                        This may take a few minutes. We are trying our best to extract as much information as possible. However, some information may not be extracted. After completion, please click "Edit Resume" to review and add any missing information.
                    </Typography.Paragraph>
                </Card>
            }

            {!isLoading &&
                <>
                    {resumeID &&
                        <div>
                            <Button
                                disabled={isMobile}
                                onClick={() => setOpenResumeAnalysis(true)}
                                style={{
                                    backgroundColor: '#fcdc5c',
                                    borderColor: '#fcdc5c',
                                    color: 'black',
                                    marginLeft: '40px'
                                }} > View Resume Analysis</Button>
                            <Typography.Paragraph style={{ textAlign: 'center', marginTop: '20px' }}>
                                You can select "Edit Resume" to update or add information to your resume. To get the most out of HireMize, please ensure your base resume is complete!
                            </Typography.Paragraph>
                        </div>
                    }
                    <div style={{ marginTop: '40px', height: '100vh', width: "90%", marginLeft: 'auto', marginRight: 'auto' }}>
                        {resumeID ? <ResumePreviewer ResumeID={resumeID} /> : <p>Please Upload Your Current Resume To Get Started</p>}
                    </div>
                </>
            }

            {errorState &&
                <Card style={{ marginTop: '20px', marginLeft: '40px', padding: '20px' }}>
                    <Typography.Title level={4} style={{ marginTop: '20px' }}>
                        Error Parsing Resume
                    </Typography.Title>
                    <Typography.Paragraph>
                        There was an error autfilling your resume. Please try again. If the problem persists, please create your resume from scratch.
                    </Typography.Paragraph>
                </Card>
            }

            <BaseResumeAnalysis resumeID={resumeID} resume={resumeData} visible={openResumeAnalysis} onClose={() => setOpenResumeAnalysis(false)} />
        </div>

    );
};

export default Resume;
