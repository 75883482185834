import React, { useState, useEffect } from 'react';
import { Col, Card, Statistic } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_APPLICATIONS_BY_STATUS } from '../../utils/queries.js';
import './ApplicationStatistic.css';

const ApplicationStatistic = (status) => {
    const appData = useQuery(QUERY_APPLICATIONS_BY_STATUS, {
        variables: { status: status.status }
    });
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        if (appData.data) {
            console.log(appData.data.getApplicationsBasedOnStatus);
            setApplications(appData.data.getApplicationsBasedOnStatus);
        }
    }
        , [appData]);


    const applicationsCount = applications.length

    return (
        <Col className="application-statistic-col">
            <Card
                title={<span style={{ fontSize: '0.9em' }}>{status.status}</span>}
            >
                <Statistic value={applicationsCount} />
            </Card>
        </Col>
    )
}

export default ApplicationStatistic;
