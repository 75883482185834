import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Layout, Breadcrumb } from "antd";

import { QUERY_ME, QUERY_ALL_JOBS } from '../../utils/queries.js';
import Home from '../Home';
import TargetJob from './TargetJob';
import Experience from './Experience';
import TargetJobLevel from './TargetJobLevel';

const { Header, Content } = Layout;


const WelcomeWizard = () => {
    const { data: meData } = useQuery(QUERY_ME);
    const { data: jobData } = useQuery(QUERY_ALL_JOBS);
    const [welcomeWizardData, setWelcomeWizardData] = useState(null);

    const [step, setStepAbsolute] = useState(null);
    const setStepRelative = (change) => setStepAbsolute(step + change);

    // Subcomponent for each step
    const step_content = {
        1: <TargetJob jobData={jobData} userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} />,
        2: <Experience userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} />,
        3: <TargetJobLevel jobData={jobData} userData={welcomeWizardData} setUserData={setWelcomeWizardData} setStep={setStepRelative} />,
    }

    // Converts the given step to corresponding content
    const getContent = (current_step) => {
        if (!(current_step in step_content)) return null;

        // Build breadcrumbs
        const breadcrumb_titles = ['Title', 'Experience', 'Level'];
        const breadcrumbs = [];
        for (let i = 0; i < current_step; i++) {
            breadcrumbs.push({
                title: i === current_step - 1 ? breadcrumb_titles[i] : <a>{breadcrumb_titles[i]}</a>,
                onClick: () => step === i ? null : setStepAbsolute(i + 1),
            });
        }

        return (
            <Layout style={{ height: '100vh', background: 'transparent' }}>
            {breadcrumbs.length > 1 &&
                <Header style={{ background: 'transparent', fontWeight: 'bold' }}>
                    <Breadcrumb style={{}} separator=">" items={breadcrumbs} />
                </Header>
            }
            <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{step_content[current_step]}</Content>
            </Layout>
        );
    }

    // Set starting fields using existing values from DB
    useEffect(() => {
        if (meData && !welcomeWizardData) {
            const data = {
                targetJob: meData.user.targetJob,
                yearsExperience: meData.user.yearsExperience,
                targetJobLevel: meData.user.targetJobLevel,
            };

            setWelcomeWizardData(data);

            // Starting step
            if (!data.targetJob) {
                setStepAbsolute(1);
            } else if (data.yearsExperience === null) {
                setStepAbsolute(2);
            } else if (!data.targetJobLevel) {
                setStepAbsolute(3);
            } else {
                setStepAbsolute(0);
            }
        }
    }, [meData, welcomeWizardData]);

    if (!welcomeWizardData || !jobData) return <p>Loading...</p>;

    return getContent(step) || <Navigate to="/" />;
}

export default WelcomeWizard;