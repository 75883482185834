import axios from 'axios';

export const fetchGenerateHTML = async (resumeData, theme) => {

    const data = {
        theme,
        resumeData
    };

    try {
        const response = await axios.post('/resume/generateResumeHtml', data);

        return response.data;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}