import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Spin, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';
import { useMutation } from '@apollo/client';
import { UPDATE_PREFERENCES } from '../../../utils/mutations';
import BasicInfo from '../../JobStrategy/BasicInfo';
import JobSearchStatus from '../../JobStrategy/JobSearchStatus';
import TargetJob from '../../JobStrategy/TargetJob';
import TargetJobExtraInfo from '../../JobStrategy/TargetJobExtraInfo';

const Preferences = ({ showBasicInfo, showJobSearchStatus, showTargetJob, showTargetJobExtraInfo }) => {
    const [form] = Form.useForm(); // Create a form instance
    const { data, loading, refetch } = useQuery(QUERY_ME);
    const [updatePreferences, { error }] = useMutation(UPDATE_PREFERENCES);
    const [currentPreferences, setCurrentPreferences] = useState({});

    useEffect(() => {
        if (data) {
            // Use setFieldsValue to set initial values after data is loaded
            console.log(data);
            form.setFieldsValue({
                'Industry': data.user.preference.industry,
                'Desired Job Title': data.user.preference.desiredJob.jobTitle,
                'Job Description 1': data.user.preference.desiredJob.jobdescription1,
                'Job Description 2': data.user.preference.desiredJob.jobdescription2,
                'Job Description 3': data.user.preference.desiredJob.jobdescription3,
                'Job Description 4': data.user.preference.desiredJob.jobdescription4,
                'Job Description 5': data.user.preference.desiredJob.jobdescription5,
                'City': data.user.preference.city,
                'ProvinceOrState': data.user.preference.provinceOrState,
                'Worksite': data.user.preference.worksite,
                'Desired Salary': data.user.preference.desiredSalary,
                'Part or Full Time': data.user.preference.partOrFullTime,
                'Current Job': data.user.preference.currentJob,
                'Current Job Search Status': data.user.preference.currentJobSearchStatus,
            });
            setCurrentPreferences(data.user.preference);
        }
    }, [data, form]);

    const handleFormSubmit = (values) => {
        // Handle form submission here
        updatePreferences({
            variables: {
                industry: values.Industry ? values.Industry : currentPreferences.industry,
                jobTitle: values['Desired Job Title'] ? values['Desired Job Title'] : currentPreferences.desiredJob.jobTitle,
                description1: values['Job Description 1'] ? values['Job Description 1'] : currentPreferences.desiredJob.jobdescription1,
                description2: values['Job Description 2'] ? values['Job Description 2'] : currentPreferences.desiredJob.jobdescription2,
                description3: values['Job Description 3'] ? values['Job Description 3'] : currentPreferences.desiredJob.jobdescription3,
                description4: values['Job Description 4'] ? values['Job Description 4'] : currentPreferences.desiredJob.jobdescription4,
                description5: values['Job Description 5'] ? values['Job Description 5'] : currentPreferences.desiredJob.jobdescription5,
                city: values.City ? values.City : currentPreferences.city,
                provinceOrState: values.ProvinceOrState ? values.ProvinceOrState : currentPreferences.provinceOrState,
                worksite: values.Worksite ? values.Worksite : currentPreferences.worksite,
                desiredSalary: values['Desired Salary'] ? values['Desired Salary'] : currentPreferences.desiredSalary,
                partOrFullTime: values['Part or Full Time'] ? values['Part or Full Time'] : currentPreferences.partOrFullTime,
                currentJob: values['Current Job'] ? values['Current Job'] : currentPreferences.currentJob,
                currentJobSearchStatus: values['Current Job Search Status'] ? values['Current Job Search Status'] : currentPreferences.currentJobSearchStatus,
            }
        });
        refetch();
    };

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 },
    };

    if (loading) {
        // Display a loading indicator while waiting for data to load
        return <Spin size="large" />;
    }

    return (
        <div>
            <h2>Preferences</h2>
            <div style={{
                width: '100%',
                display: 'flex',
            }}>
                <Form
                    {...layout}
                    form={form} // Pass the form instance to the Form component
                    name="nest-messages"
                    style={{ maxWidth: 600 }}
                    onFinish={handleFormSubmit}
                >
                    {showBasicInfo ? BasicInfo() : ""}
                    {showJobSearchStatus ? JobSearchStatus() : ""}
                    {showTargetJob ? TargetJob() : ""}
                    {showTargetJobExtraInfo ? TargetJobExtraInfo() : ""}

                    <Form.Item style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div >
        </div>
    );
};

export default Preferences;