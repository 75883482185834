import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Space, Alert } from 'antd';
import ResumePreviewer from '../../Previewers/ResumePreviewer';
import BaseResumeAnalysisComponent from './Analysis/index'

const BaseResumeAnalysis = ({ resumeID, resume, visible, onClose }) => {
    

    return (
        <Modal
            centered
            title={
                <h1 style={{ fontSize: '1.3em', fontWeight: "bold", margin: '0px' }}>
                    Resume Analysis
                    {/* <Alert
                    type="error"
                    message={`Please fix before adding your application`}
                    showIcon
                    style={nextStepsStyle}
                  /> */}
                </h1>
            }
            visible={visible}
            onCancel={onClose}
            width={'90vw'}
            bodyStyle={{ height: '85vh', alignItems: 'center', justifyContent: 'center' }}
            footer={null}
            maskClosable={false}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space
                    direction="vertical"
                    style={{
                        width: '50%',
                    }}
                >
                    <div style={{ maxHeight: 'calc(85vh - 200px)', overflowY: 'auto' }}>
                    <BaseResumeAnalysisComponent resumeID={resumeID} resume={resume} />
                    </div>
                    <hr style={{ marginRight: '5px' }} />
                    <div style={{ backgroundColor: '#f0f0f0', marginRight: '5px', borderRadius: '10px', padding: '5px' }}>
                        <h4><strong>Next Steps</strong></h4>
                        <p style={{ margin: '0', marginTop: '10px', fontSize: '1.2em', textAlign: 'center' }}>
                            To ensure a strong foundation for your resume, please click
                            <Button className='button-color' style={{ margin: '0 5px', padding: '5px 10px' }}>
                                <Link to={`/edit-resume/${resumeID}`}>Edit Resume</Link>
                            </Button>
                            to complete any missing information. Once you're satisfied with your resume, proceed by clicking
                            <Link to="/applications" onClick={(event) => {
                                event.preventDefault();
                                window.location.href = '/applications';
                            }}>
                                <Button style={{marginLeft:'20px'}}className="button-pulse">Add First Application</Button>
                            </Link>
                        </p>
                    </div>
                </Space>
                <div style={{ height: '85vh', width: '55%' }}>
                    <ResumePreviewer ResumeID={resumeID}></ResumePreviewer>
                </div>
            </div>
            
        </Modal>
        
    );
}

export default BaseResumeAnalysis;