
import React, {useState, useEffect} from 'react';
import SocialURLsForm from '../../JobStrategy/ResumeInfo/SocialURLs'
import SkillsForm from '../../JobStrategy/ResumeInfo/Skills/form';
import SkillsView from '../../JobStrategy/ResumeInfo/Skills/view';
import '../stepsStyle.css'; // Import the CSS file for styling
import { useQuery } from '@apollo/client';
import { QUERY_ME } from '../../../utils/queries';

const Step6 = () => {
    const { data, refetch } = useQuery(QUERY_ME);
    const [skills, setSkills] = useState([]);
    const [URLs, setURLs] = useState({})

    useEffect(() => {
        if (data) {
            setSkills(data.user.skills);;
            setURLs(data.user.socialURLs)
        }
    }, [data]);

    return (
        <div>
            <h1>Additional Info</h1>
            <div className="wiz-container">
                <SocialURLsForm URLs={URLs}/>
            </div>
            <div className="wiz-container">
                <SkillsForm refreshFunction={refetch} />
            </div>
            <div className="wiz-container">
                <SkillsView skills={skills} />
            </div>
        </div>
    );
}

export default Step6;