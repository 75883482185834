import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Button, Grid, Row, Col, Divider, Typography, Tag, Rate } from 'antd'
import { GlobalOutlined } from '@ant-design/icons';
import CompanyItem from "../CompanyItem";
import EmailCompose from "../../MailBox/EmailComposer";
import EmailButtons from "../../MailBox/EmailButtons";
import '../Card.css'
import { UserContext } from '../../../contexts/userContext';
import { useMutation } from "@apollo/client";
import { NOT_INTERESTED_COMPANY } from "../../../utils/mutations";
import { REMOVE_KEYWORDS_FROM_USER } from "../../../utils/mutations";
import { useQuery } from "@apollo/client";
import { QUERY_CONTACTS_BY_COMPANY_ID } from "../../../utils/queries";
import './MatchCard.css';
import useIsMobile from '../../../contexts/useIsMobile';


var messageRows

const MatchCard = ({ match, next }) => {

    const isMobile = useIsMobile();
    const [isExpanded, setIsExpanded] = useState(false);

    if (isMobile) {
        messageRows = 16
    } else {
        messageRows = 20
    }

    var MessageTitle = `Connect with ${match ? match.companyName : 'Company'}`;

    const [not_interested_company] = useMutation(NOT_INTERESTED_COMPANY);
    const [remove_keywords_from_user] = useMutation(REMOVE_KEYWORDS_FROM_USER);
    const { data: contactsData } = useQuery(QUERY_CONTACTS_BY_COMPANY_ID, {
        variables: { company: match._id },
        skip: !!match.companyEmail, // Skip the query if companyEmail is not empty
    });
    const [contacts, setContacts] = useState([]);
    const [mainContactEmail, setMainContactEmail] = useState(null);

    useEffect(() => {
        if (contactsData) {
            setContacts(contactsData.findContactsByCompany);
            if (contactsData.findContactsByCompany.length > 0) {
                setMainContactEmail(contactsData.findContactsByCompany[0].email);
            }
        }
    }, [contactsData]);

    //EMAIL IS NOT ACTIVE YET - ALL EMAILS SENT TO ME-----------------------------------------------------
    var toEmail;
    useEffect(() => {
        if (match.companyEmail) {
            // toEmail = match.companyEmail;
            console.log('Company Email:', match.companyEmail)
        } else {
            // toEmail = mainContactEmail;
            console.log('Contact Email:', mainContactEmail)
        }
    }, [match.companyEmail, mainContactEmail]);

    //EMAIL IS NOT ACTIVE YET - ALL EMAILS SENT TO ME
    toEmail = 'mandeepa98@gmail.com'
    //EMAIL IS NOT ACTIVE YET - ALL EMAILS SENT TO ME-----------------------------------------------------

    const { user } = useContext(UserContext);

    //Check if match._id is in user.targetCompanies._id
    var isTarget = false;
    user.targetCompanies.forEach(company => {
        if (company._id === match
            ._id) {
            isTarget = true;
        }
    });

    const [newSubject, setNewSubject] = useState(user.matcherEmails[0].emailSubject);
    const [newBody, setNewBody] = useState('Hello,\n\n' + user.matcherEmails[0].emailBody + '\n\nBest regards,\n' + user.firstName + ' ' + user.lastName);


    //Check if match._id is in user.targetCompanies._id


    const handleNotInterested = async () => {

        try {
            await not_interested_company({
                variables: {
                    id: match._id,
                    notInterestedTo: user._id
                }
            });

            await remove_keywords_from_user({
                variables: {
                    keywords: match.keywords
                }
            });

            next(true);

        } catch (err) {
            console.error(err);
        }
    }

    const KeywordsScroll = ({ keywords }) => {
        const scrollContainerRef = useRef(null);

        useEffect(() => {
            const scrollContainer = scrollContainerRef.current;
            if (scrollContainer) {
                let start = null;
                const duration = 50000; // Duration of the scroll in milliseconds

                const step = (timestamp) => {
                    if (!start) start = timestamp;
                    const progress = timestamp - start;
                    const scrollAmount = Math.min(progress / duration, 1) * scrollContainer.scrollWidth;
                    scrollContainer.scrollLeft = scrollAmount;

                    if (progress < duration) {
                        requestAnimationFrame(step);
                    }
                };

                requestAnimationFrame(step);
            }
        }, [keywords]);

        return (
            <div ref={scrollContainerRef} className="keyword-scroller">
                {keywords.map((keyword, index) => (
                    <Tag key={index} style={{ marginRight: '10px', backgroundColor: '#2ca4cc', color: 'white' }}>{keyword}</Tag>
                ))}
                {/* color:'#001529' */}
                <style jsx>{`
                    div::-webkit-scrollbar {
                        display: none;
                    }
                `}</style>
            </div>
        );
    };

    return (

        <div className="full-match-card">
            {isTarget && (
                <div style={{
                    position: 'absolute',
                    top: '0px', // Move further up
                    left: '0px', // Move further left
                    fontSize: '11px', // Smaller font size
                    fontWeight: 'bold',
                    color: 'black',
                    lineHeight: '1.8',
                    paddingInline: '2.0em', // Smaller padding
                    paddingBottom: '0.25em', // Smaller folded part
                    borderImage: 'conic-gradient(#0008 0 0) 51%/0.25em', // Adjusted for smaller folded part
                    clipPath: 'polygon(100% calc(100% - 0.25em), 100% 100%, calc(100% - 0.25em) calc(100% - 0.25em), 0.25em calc(100% - 0.25em), 0 100%, 0 calc(100% - 0.25em), 999px calc(100% - 0.25em - 999px), calc(100% - 999px) calc(100% - 0.25em - 999px))',
                    transform: 'translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg)',
                    transformOrigin: '100% 100%',
                    backgroundColor: '#fcdc5c'
                }}>
                    Target Company
                </div>
            )}
            <button
                className="not-interested"
                onClick={handleNotInterested}
            >
                Not Interested
            </button>
            <Row gutter={[24, 8]} className="match-card-row">
                <Col xs={24} md={12}>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <CompanyItem company={match} showButton={false} />
                        </Col>
                        <Col span={24}>
                            {match.keywords && match.keywords.length > 0 && (
                                <Card className="keyword-scroller-card">
                                    <KeywordsScroll keywords={match.keywords} />
                                </Card>
                            )}
                        </Col>
                        <Col span={24} style={{ flex: '1' }} className="company-description-card">
                            {!isExpanded && isMobile ? (
                                <Button size='small' className="view-more-button" onClick={() => setIsExpanded(true)}>View Company Info</Button>
                            ) : (
                                <Card style={{ minHeight: '30vh', maxHeight: '35vh', overflowY: 'auto' }}>
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography.Title level={4} style={{ margin: 0 }}>Company Overview</Typography.Title>
                                        </div>
                                        <Divider style={{ marginTop: '5px', marginBottom: '10px' }} />
                                        {match.companyGlassdoorRating && (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography.Paragraph>
                                                    <strong>Glassdoor Rating:</strong>
                                                    <Rate style={{ marginInline: '0px', marginBottom: '0px', marginRight: '5px', color: '#2ca4cc' }} disabled defaultValue={match.companyGlassdoorRating} count={5} />
                                                    ({match.companyGlassdoorVotes} reviews)
                                                </Typography.Paragraph>
                                            </div>
                                        )}
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            {match.size && (<Typography.Paragraph><strong>Company Size:</strong> {match.size}</Typography.Paragraph>)}
                                            {match.yearFounded && (<Typography.Paragraph><strong>Year Founded:</strong> {match.yearFounded}</Typography.Paragraph>)}
                                        </div>
                                        {match.description ? (
                                            <Typography.Paragraph>
                                                <strong>Description: </strong>{match.description}
                                            </Typography.Paragraph>
                                        ) : (
                                            <Typography.Paragraph>
                                                <strong>Description: </strong>
                                                For more information, visit the company's
                                                {match.companyWebsite ? (
                                                    <>
                                                        <a href={match.companyWebsite} target="_blank" rel="noopener noreferrer"> website</a>
                                                    </>
                                                ) : null}
                                                {match.companyLinkedIn ? (
                                                    <>
                                                        {match.companyWebsite ? ' or ' : ' '}
                                                        <a href={match.companyLinkedIn} target="_blank" rel="noopener noreferrer"> LinkedIn</a>
                                                    </>
                                                ) : null}.
                                            </Typography.Paragraph>
                                        )}
                                    </div>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={12}>
                    <h4 className='connectWithText' >{MessageTitle}</h4>
                    <Divider className='matchCardDivider' />
                    <EmailCompose
                        newSubject={newSubject}
                        setNewSubject={setNewSubject}
                        newBody={newBody}
                        setNewBody={setNewBody}
                        messageRows={messageRows} />
                    <EmailButtons
                        handleChatBoxUpdate={next}
                        toEmail={toEmail}
                        subject={newSubject}
                        body={newBody}
                        userContactId={match._id}
                        forMatcher={true}
                    />
                </Col>
            </Row>
        </div>

    );
}

export default MatchCard;