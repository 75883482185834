import React, { useState, useContext, useEffect } from 'react';
import { Layout, Result, Button } from 'antd';
import MailBox from '../../components/MailBox';
import EmailList from '../../components/MailBox/EmailList'
import { UserContext } from '../../contexts/userContext'
import useIsMobile from '../../contexts/useIsMobile';
import './Inbox.css';

const { Content, Sider } = Layout;


const Inbox = ({ setSideBar }) => {

    const { allMyEmails } = useContext(UserContext);
    const [selectedEmailThread, setSelectedEmailThread] = useState()
    const isMobile = useIsMobile();


    const handleBackToList = () => {
        setSelectedEmailThread(null);
      };
    
      return (
        <div className='main-inbox'>
          <Layout className='email-list'>
            {!isMobile || !selectedEmailThread ? (
              <Sider
                style={{
                  backgroundColor: 'white',
                  overflow: 'auto',
                  height: '100vh',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  borderRight: '1px solid #d9d9d9',
                  width: isMobile ? '100%' : '350px',
                }}
                width={isMobile ? '100%' : 350}
              >
                <EmailList selectEmailThread={setSelectedEmailThread} />
              </Sider>
            ) : null}
    
            {(!isMobile || selectedEmailThread) && (
              <Content
                style={{
                  height: '100vh',
                  overflow: 'auto',
                  backgroundColor: 'white',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                {selectedEmailThread ? (
                  <>
                    {isMobile && (
                      <Button size='small' onClick={handleBackToList} style={{ marginBottom: '0px', marginTop: '5px' }}>
                        Back
                      </Button>
                    )}
                    <MailBox
                      contactName={selectedEmailThread.fromEmail}
                      myEmails={allMyEmails.filter(
                        (email) =>
                          email.fromEmail === selectedEmailThread.fromEmail ||
                          email.toEmail === selectedEmailThread.fromEmail
                      )}
                      userContactId={selectedEmailThread.userContactId?._id || ''}
                      toEmail={selectedEmailThread.fromEmail}
                    />
                  </>
                ) : (
                  <p>Select Email</p>
                )}
              </Content>
            )}
          </Layout>
        </div>
      );
}

export default Inbox
