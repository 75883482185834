import React from 'react';
import { Divider } from 'antd';
import ReactPlayer from 'react-player'


const ApplicationTrackingGuide = () => {


    return (
        <div>
            <div>
                <h1>Track your job applications</h1>
                <p>Stay organized and monitor your progress with our intuitive application tracking system.</p>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Monitor your job applications</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <ReactPlayer url='/Clips/Applications.mp4' controls={false} playing={true} loop={true} width='80%' height='80%' />
                    <div>
                        <h4>Application Tracking</h4>
                        <p>Monitor the progress of your job applications with ease. Simply select from the dropdown menu to update the application status.</p>
                    </div>
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Stay organized with our intuitive system</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div>
                        <h4>Task Management</h4>
                        <p>Our system intelligently identifies the most suitable tasks for your current application stage, such as creating cover letters, sending follow-up emails, conducting mock interviews, and more, to keep your job hunt on track!</p>
                    </div>
                    {/* <img src="screen/todo.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} /> */}
                </div>
            </div>
        </div>
    );
}


export default ApplicationTrackingGuide;