import React from 'react';
import { Divider } from 'antd';


const ResumeGuide = () => {

    return (
        <div>
            <div>
                <h1>Build the perfect resumes</h1>
                <p>Craft customized resumes for each job application and maintain various versions at your fingertips!</p>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Create new resumes in one click</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <img src="screen/resumeEnhancer.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} />
                    <div>
                        <h4>Resume Enhancer</h4>
                        <p>Simply copy and paste the job description, then click 'Enhance' to instantly customize your resume for the specific job posting.</p>
                    </div>
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Let's enhance the bullet points of your resume</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div>
                        <h4>Resume Bullet Point Generator</h4>
                        <p>Effortlessly craft and refine the bullet points in your resume for maximum impact.</p>
                    </div>
                    <img src="screen/resumeBullet.png" alt="Hiremize" style={{ height: '150px', marginLeft: '20px' }} />
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Organize and prioritize your diverse resumes</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <img src="screen/resumeManager.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} />
                    <div>
                        <h4>Resume Organizer</h4>
                        <p>Manage your various resumes efficiently. Highlight your preferred versions and customize their names for easy reference.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResumeGuide;