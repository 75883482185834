import React, { useState, useEffect} from 'react';
import { Button, Modal } from 'antd';
import EditResume from '../../../../../pages/EditResume/EditResume'


const EditResumeModal = ({ visible, onClose, resumeID }) => {

    const contentStyle = {
        height: '100%',
    };

    return (
        <>
        <Modal
            title=" "
            centered
            visible={visible}
            onCancel={onClose}
            closable={false}
            footer={null}
            width={'100%'}
            bodyStyle={contentStyle}
        >
            <EditResume resumeId={resumeID} closeModal={onClose}/>
        </Modal>

        
        </>

    );

};

export default EditResumeModal;
