import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Auth from '../utils/auth';
import { ADD_USER } from '../utils/mutations';
import { Form, Input, Button, Layout, notification, Card } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Login from './Login';
import logo from '../assets/logo.png';
import useIsMobile from '../contexts/useIsMobile';

const { Sider, Content } = Layout;

function Signup(props) {
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [addUser] = useMutation(ADD_USER);
  const isMobile = useIsMobile(); // Use the custom hook

  const handleFormSubmit = async (values) => {
    const mutationResponse = await addUser({
      variables: {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });
    const token = mutationResponse.data.addUser.token;
    Auth.login(token);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // return (
  //   <div className="container my-1">
  //     <h2>Sign Up is currently unavailable for Closed Beta</h2>
  //     <Link to="/login">← Go to Login</Link>
  //   </div>
  // );


  return (
    <div style={{ height: '100vh', }}>


      {isMobile ? (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#001529' }}>

          <div style={{ color: 'white', padding: '20px', textAlign: 'center' }}>
            <img src={logo} alt="HireMize" style={{ marginBottom: '5px' }} />

            <h2 style={{ color: '#fff', fontWeight: 'bold' }}>Sign Up</h2>
            <p style={{ color: '#fff', fontSize: '14px' }}>Please enter your details below to sign up for the free Beta.</p>
            <Form
              name="normal_login"

              onFinish={handleFormSubmit}


              style={{


                color: '#fff'
              }}
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: 'Please input your First Name!' }]}
              >
                <Input placeholder="First Name" onChange={handleChange} />
              </Form.Item>

              <Form.Item
                name="lastName"
                rules={[{ required: true, message: 'Please input your Last Name!' }]}
              >
                <Input placeholder="Last Name" onChange={handleChange} />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your Email!' }]}
              >
                <Input placeholder="Email" onChange={handleChange} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </Form.Item>

              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" className='button-color' htmlType="submit">
                  Sign Up
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="link">
                  <Link to="/login">← Go to Login</Link>
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

      ) : (


        <Layout style={{ background: '#fff' }}>
          {/* <Link to="/login">← Go to Login</Link> */}
          <Sider width={600} style={{ color: '#fff', marginRight: '10px', position: 'fixed', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

              <div>
                <img src={logo} alt="HireMize" />
              </div>

              <h2 style={{ color: '#fff', fontWeight: 'bold' }}>Sign Up</h2>
              <p style={{ fontSize: '14px' }}>Please enter your details below to sign up for the free Beta.</p>
              <Form
                name="normal_login"

                onFinish={handleFormSubmit}

                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                style={{
                  marginLeft: '35%',
                  maxWidth: 600,
                  color: '#fff'
                }}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your First Name!' }]}
                >
                  <Input placeholder="First Name" onChange={handleChange} />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your Last Name!' }]}
                >
                  <Input placeholder="Last Name" onChange={handleChange} />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                  <Input placeholder="Email" onChange={handleChange} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item>
                  <Button style={{ width: '100%' }} type="primary" className='button-color' htmlType="submit">
                    Sign Up
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="link">
                    <Link to="/login">← Go to Login</Link>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Sider>
          <Content style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'auto', marginLeft: '610px', background: '#fff', color: '#020b16', height: '100vh' }}>
            {/* TODO: Add Beta related Content? */}
            <h2>Welcome to Our Beta Program!</h2>
            <p style={{ textAlign: 'center' }}>By signing up for our beta, you'll get early access to new features and have the opportunity to help shape the future of our product.</p>
            <p style={{ textAlign: 'center' }}>We're looking for users who are willing to provide feedback and report any issues they encounter. In return, get free access to all our powerful tools!</p>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginBottom: '10px' }}>
                <Card title="Unlimited Resume Enhancements" bordered={false} style={{ width: '47.5%', padding: '30px' }}>
                  <p>Boost your career with limitless resume enhancements.</p>
                </Card>
                <Card title="Unlimited Cover Letter Creations" bordered={false} style={{ width: '47.5%', padding: '30px' }}>
                  <p>Stand out from the crowd with unlimited custom cover letters.</p>
                </Card>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginBottom: '20px' }}>
                <Card title="Personalized Networking Messages Tool" bordered={false} style={{ width: '47.5%', padding: '30px' }}>
                  <p>Generate limitless personalized messages to enhance your networking endeavors.</p>
                </Card>
                <Card title="And More" bordered={false} style={{ width: '47.5%', padding: '30px' }}>
                  <p>Join our beta program to unlock a plethora of features, including our Professional Relationship Manager, Mock Interview Tool, and personalized Job Search Recommendations.</p>
                </Card>
              </div>
            </div>
            <p>The current Beta version and its features are available for a limited period only.</p>
            <p>Sign up now to secure your spot! Availability is limited.</p>
          </Content>
        </Layout>
      )}
    </div>
  );
};

export default Signup;