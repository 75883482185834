import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { ADD_SKILL } from '../../../../utils/mutations';


const SkillsForm = () => {

    const [addSkill] = useMutation(ADD_SKILL);

    const [formSkill] = Form.useForm();

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 },
    }

    const handleFormSubmitSkill = async (values) => {
        try {
            console.log(values);
            // call the addSkill mutation with the form values
            await addSkill({
                variables: {
                    skills: values['Skill']
                }
            });

            formSkill.resetFields();
        } catch (error) {
            // Log any errors that occur
            console.error(error);
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Form
                {...layout}
                form={formSkill} // Pass the form instance to the Form component
                name={`nest-messages`}
                style={{ width: '100%' }}
                onFinish={handleFormSubmitSkill}
            >
                <Row>
                    <Form.Item name='Skill' label="Skill">
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
}

export default SkillsForm;