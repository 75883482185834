import axios from 'axios';



export const title_network = "Network";

export function defaultLinkNetworkMessage(Name, Position, Company) {
    
    if (!Name) {
        Name = "[Name]";
    }

    return `Hi ${Name}, I hope all is well. I was impressed with your profile and wanted to connect. I'm an aspiring ${Position} and am keen to learn about your experience at ${Company}. If you're open to it, I'd appreciate a chat. Thanks!`
}

export const additionalInfoNetowrkPlaceholder = "Highlight any common interests, education, experience, connection, job, etc.";

export const fetchLinkNetworkMessage = async (Position, Company, AdditionalInfo, currentContact, coldOutreach, linkedInCharacterLimit) => {
    const data = {
        Position: Position,
        Company: Company,
        AdditionalInfo: AdditionalInfo,
        Name: currentContact,
        Cold: coldOutreach,
        LinkLimit: linkedInCharacterLimit
    };
    
    try {
        const response = await axios.post('/message/linknetwork', data);

        return response.data.linkNetworkMes;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}