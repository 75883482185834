import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
const { Paragraph } = Typography;


const WorkExperienceView = ({ workHistory }) => {

    return (
        <>
            {workHistory.map((entry, index) => (
                <Card key={index} style={{ marginBottom: '20px', width: '50%', justifyContent: 'center' }}>
                    <Row>
                        <p><strong>{entry['company']}</strong>, {`${entry.location.city}, ${entry.location.provinceOrState}`} </p>
                    </Row>
                    <Row>
                        <p>{entry['jobTitle']}</p>
                    </Row>
                    <Row>
                        <p>{entry['startDate']} - {entry.currentlyWorking ? "Current" : entry['endDate']}</p>
                    </Row>
                    <Row>
                        <p><strong>Description:</strong></p>
                    </Row>
                    <Row style={{marginTop:'-25px'}}>
                        <pre>{entry['description']}</pre>
                    </Row>
                </Card>
            ))}
        </>
    );
}

export default WorkExperienceView;