import axios from 'axios';

export const fetchGeneratePDF = async (resumeData, theme) => {

    const data = {
        theme,
        resumeData
    };

    try {
        const response = await axios.post('/resume/generateResumePdf', data);

        return response;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}