import React from 'react';
import { Card, Row, Col } from 'antd';


const EducationView = ({ education }) => {

    return (
        <>
            {education.map((entry, index) => (
                <Card key={index} style={{ marginBottom: '20px', width: '50%'}}>
                    <Row>
                        <p><strong>{entry['school']}</strong></p>
                    </Row>
                    <Row>
                    <p>{entry['degree']} in {entry['fieldOfStudy']}</p>
                    </Row>
                    <Row>
                        <p>{entry['startDate']} - {entry.currentlyAttending ? "Current" : entry['endDate']}</p>
                    </Row>
                    <Row>
                    <p><strong>GPA: {entry['gpa']}</strong></p>
                    </Row>

                </Card>
            ))}
        </>
    );
};

export default EducationView;