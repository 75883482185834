import React, { useState, useEffect } from 'react';
import { Button, Modal, Input } from 'antd';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { UPDATE_MY_SAVED_RESUMES } from '../../../utils/mutations';
import { QUERY_MY_SAVED_RESUMES } from '../../../utils/queries';


const SaveResumeModal = ({onClose, appID}) => {
    const [updateMySavedResumes] = useMutation(UPDATE_MY_SAVED_RESUMES);
    const { loading, error, data } = useQuery(QUERY_MY_SAVED_RESUMES);
    const [resumeName, setResumeName] = useState('');
    const [currentSavedResumes, setCurrentSavedResumes] = useState([]);
    const [numberOfSavedResumes, setNumberOfSavedResumes] = useState(0);

    useEffect(() => {
        if (data) {
            if (data.user && data.user.savedResumes) {
                setCurrentSavedResumes(data.user.savedResumes);
                setNumberOfSavedResumes(data.user.savedResumes.length);
            }
        }
    }
    , [data]);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    console.log(currentSavedResumes);
    console.log(numberOfSavedResumes);

    const saveResume = async (SLOT) => {

        try {
            const { data } = await updateMySavedResumes({
                variables: { 
                    resumeData: appID,
                    resumeName: resumeName,
                    slot: SLOT
                },
            });
            console.log(data)
        } catch (err) {
            console.error(err);
        }
    }

    const handleInputChange = (e) => {
        setResumeName(e.target.value);
    };

  return (
    <>
    {numberOfSavedResumes < 3 && (
        <Modal open={true} footer={null} onCancel={onClose}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p> Save this Resume to your profile!</p>
                <Input style={{ marginBottom: '10px', width: '70%' }} placeholder="Enter Resume Name" onChange={handleInputChange} />
                <Button onClick={() => saveResume(numberOfSavedResumes)} type="primary">Save</Button>
            </div>
        </Modal>
    )} else {
        <Modal title="Saved Resume" footer={null} onCancel={onClose}>
            <p> You have reached the maximum number of saved resumes. </p>
        </Modal>
    }
    </>
  );
};
export default SaveResumeModal;