import React from 'react';
import { Form, Input, Button, DatePicker, InputNumber, Select, Space, Checkbox } from 'antd';
import { Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPDATE_CL_DATA } from '../../../utils/mutations';
const { TextArea } = Input;
const { Option } = Select;


const CoverLetterForm = ({ currentCLData, clID, refetchCLData }) => {

    const [form] = Form.useForm();
    const [updateCLData] = useMutation(UPDATE_CL_DATA);

    const initialValues = {
        name: currentCLData.userData.name,
        email: currentCLData.userData.email,
        phone: currentCLData.userData.phone,
        label: currentCLData.userData.label,
        date: currentCLData.currentDate,
        jobTitle: currentCLData.jobData.jobTitle,
        company: currentCLData.jobData.company,
        location: currentCLData.jobData.location,
        hiringManager: currentCLData.jobData.hiringManager,
        coverLetterParagraphs: currentCLData.coverLetterParagraphs.join('\n\n')
    }

    const onFinish = async (values) => {
        console.log('Received values of form:', values);
        // const { data } = await updateResumeData({
        //     variables: { resumeData: values },
        // });
        // refetchResumeData();
    };

    const handleUpdateCoverLetter = async () => {
        const values = form.getFieldsValue();
        console.log('Received values of form:', values);
        await updateCLData({
            variables: { 
                id: clID, 
                userData: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    label: values.label
                },
                currentDate: values.date,
                jobData: {
                    jobTitle: values.jobTitle,
                    company: values.company,
                    location: values.location,
                    hiringManager: values.hiringManager
                },
                coverLetterParagraphs: values.coverLetterParagraphs.split('\n\n')
            },
        });
        refetchCLData();
    }


    return (
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
            {/* User Info */}
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="label" label="Label">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="date" label="Date">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            {/* Job Info */}
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="jobTitle" label="Job Title">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="company" label="Company">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="location" label="Location">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="hiringManager" label="Hiring Manager">
                <Input />
            </Form.Item>
            {/* Cover Letter Text */}
            <Form.Item name="coverLetterParagraphs" label="Cover Letter Paragraphs">
                <TextArea rows={18} />
            </Form.Item>
            <Form.Item>
                <Button className='button-color-premium' type="primary" htmlType="submit" onClick={handleUpdateCoverLetter}>
                    Update Cover Letter
                </Button>
            </Form.Item>
        </Form>
    );

}

export default CoverLetterForm;