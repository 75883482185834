import React, { useState, useEffect } from 'react';
import { Card, Space, Typography, AutoComplete, Button, Select, List, Tabs, Flex, Tag, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_ALL_JOB_FIELDS } from '../../../../../utils/queries';
import './TargetJob.css';

const { Title, Text } = Typography;


const TargetJob = ({ jobData, userData, setUserData, setStep, disableNext }) => {
  const [selectedJob, setSelectedJob] = useState(userData.targetJob);
  const [showJobFieldsParents, setShowJobFieldsParents] = useState(false);
  const [showJobFields, setShowJobFields] = useState(false);
  const [selectedJobField, setSelectedJobField] = useState('');
  const [selectedParent, setSelectedParent] = useState('');
  const { data: jobFieldsData } = useQuery(QUERY_ALL_JOB_FIELDS);
  const [jobFieldsParents, setJobFieldsParents] = useState([]);
  const [filteredJobFields, setFilteredJobFields] = useState([]);
  const jobFields = jobFieldsData ? jobFieldsData.allJobFields : [];

  useEffect(() => {
    if (jobFieldsData) {
      const parents = jobFieldsData.allJobFields.map(field => field.Parent);
      const uniqueParents = [...new Set(parents)];
      setJobFieldsParents(uniqueParents);
    }
  }, [jobFieldsData]);


  useEffect(() => {
    if (!selectedJob) {
      disableNext(true);
    } else {
      disableNext(false);
    }
  }, []);

  const updateUserData_TargetJob = (value) => {

    setSelectedJob({ title: value });

    const selectedJobObject = jobData.allJobs.find(job => job.title === value);

    if (!selectedJobObject) {
      setShowJobFieldsParents(true);
      return
    } else {
      setShowJobFieldsParents(false);
      setShowJobFields(false);
    }

    setUserData({
      ...userData,
      targetJob: selectedJobObject,
    });

    disableNext(false);
  }

  const handleSelectJobField = (value) => {

    console.log(selectedJob);

    setSelectedJobField(value);

    const selectedJobFieldObject = jobFields.find(field => field.Field === value);

    if (!selectedJobFieldObject) {
      setFilteredJobFields(jobFields.filter(field => field.Parent === value));
      setSelectedParent(value);
      setShowJobFields(true);
      return
    }

    if (jobFieldsParents.includes(value)) {
      setShowJobFields(false);
    }

    setUserData({
      ...userData,
      customTargetJob: selectedJob.title,
      targetJobField: selectedJobFieldObject,
    });

    disableNext(false);
  }

  const jobCategories = [
    {
      category: 'Business',
      jobs: [
        { title: 'Investment Banker' },
        { title: 'Business Development Representative' },
        { title: 'Accountant' },
        { title: 'Private Equity Analyst' },
        { title: 'Financial Analyst' },
        { title: 'Human Resources Manager' },
        { title: 'Project Manager' },
        { title: 'Sales Manager' },
        { title: 'Consultant (Management/Strategy)' },
        { title: 'Sales Development Representative (SDR)' },
      ],
    },
    {
      category: 'Engineering',
      jobs: [
        { title: 'Software Engineer' },
        { title: 'Network Engineer' },
        { title: 'AI/ML Engineer' },
        { title: 'DevOps Engineer' },
        { title: 'Systems Engineer' },
        { title: 'Network Engineer' },
        { title: 'Mechanical Engineer' },
        { title: 'Electrical Engineer' },
        { title: 'Civil Engineer' },
        { title: 'Quality Assurance Engineer' },
      ],
    },
    {
      category: 'Health & Medicine',
      jobs: [
        { title: 'Doctor' },
        { title: 'Nurse' },
        { title: 'Pharmacist' },
        { title: 'Dentist' },
        { title: 'Surgeon' },
        { title: 'Physician Assistant' },
        { title: 'Medical Technologist' },
        { title: 'Radiologic Technologist' },
        { title: 'Physical Therapist' },
        { title: 'Occupational Therapist' },
      ],
    },
    {
      category: 'Social Sciences',
      jobs: [
        { title: 'Social Worker' },
        { title: 'Counselor' },
        { title: 'Human Resources Specialist' },
        { title: 'Sociologist' },
        { title: 'Psychologist' },
        { title: 'Anthropologist' },
        { title: 'Political Scientist' },
        { title: 'Economist' },
        { title: 'Urban Planner' },
        { title: 'Public Policy Analyst' },
      ],
    },
    {
      category: 'Math & Technology',
      jobs: [
        { title: 'Blockchain Developer' },
        { title: 'Product Manager' },
        { title: 'Physicist' },
        { title: 'Data Scientist' },
        { title: 'AI/ML Engineer' },
        { title: 'Mathematician' },
        { title: 'Software Developer' },
        { title: 'Computer Scientist' },
        { title: 'Systems Analyst' },
        { title: 'Cybersecurity Analyst' },
      ],
    },
    {
      category: 'Law',
      jobs: [
        { title: 'Lawyer' },
        { title: 'Paralegal' },
        { title: 'Judge' },
        { title: 'Legal Assistant' },
        { title: 'Corporate Counsel' },
        { title: 'Public Defender' },
        { title: 'Prosecutor' },
        { title: 'Legal Analyst' },
        { title: 'Compliance Officer' },
        { title: 'Mediator' },
      ],
    },
    {
      category: 'Arts & Communication',
      jobs: [
        { title: 'Graphic Designer' },
        { title: 'Journalist' },
        { title: 'Public Relations Specialist' },
        { title: 'Photographer' },
        { title: 'Art Director' },
        { title: 'Copywriter' },
        { title: 'Video Editor' },
        { title: 'Animator' },
        { title: 'Social Media Manager' },
        { title: 'Content Creator' },
      ],
    }
    // Add more categories as needed
  ];


  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className="target-job-container">
        <div className="target-job-content">
            <div className="job-details">
                <Space direction="vertical" size="large">
                    <Title level={3}>Target Job</Title>
                    <Text>Select your target job title to get started</Text>
                    <Space>
                        <AutoComplete
                            options={
                                jobData && jobData.allJobs ? jobData.allJobs.map((job, _) => ({
                                    value: job.title,
                                    label: job.title,
                                })) : []
                            }
                            value={selectedJob ? selectedJob.title : ''}
                            onChange={(value) => updateUserData_TargetJob(value)}
                            style={{ width: 300 }}
                            filterOption={(inputValue, option) =>
                                option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Space>

                    {showJobFieldsParents && (
                        <>
                            <Text>Select the most applicable job field</Text>
                            <Space>
                                <Select
                                    defaultValue={selectedJobField.Parent ? selectedJobField.Parent : ''}
                                    className='parentJobFieldInput'
                                    onChange={handleSelectJobField}
                                >
                                    {jobFieldsParents.map((parent, _) => (
                                        <Select.Option value={parent}>{parent}</Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </>
                    )}

                    {showJobFields && (
                        <>
                            <Text>Select the most applicable field in {selectedParent} </Text>
                            <Space>
                                <Select
                                    defaultValue={""}
                                    className='jobFieldInput'
                                    onChange={handleSelectJobField}
                                >
                                    {filteredJobFields.map((field, _) => (
                                        <Select.Option value={field.Field}>{field.Field}</Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </>
                    )}
                </Space>
            </div>

            <Divider type="vertical" className="vertical-divider" />

            <div className="job-actions">
                <Tabs
                    onChange={onChange}
                    type="card"
                    style={{
                        width: '100%',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                    }}
                    moreIcon={
                      <span className="more-icon">
                          <div className="more-icon-text">View</div>
                          <div className="more-icon-text">More</div>
                      </span>
                  }
                    items={jobCategories.map((category, index) => {
                        const id = String(index + 1);
                        return {
                            label: category.category,
                            key: id,
                            children: (
                                <div>
                                    <Space direction="vertical" size="large">
                                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '3px 0' }}>
                                            {category.jobs.map((job, jobIndex) => (
                                                <Tag
                                                    key={jobIndex}
                                                    color="#108ee9"
                                                    onClick={() => updateUserData_TargetJob(job.title)}
                                                    className='jobTags'
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0d7bc1'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#108ee9'}
                                                >
                                                    {job.title}
                                                </Tag>
                                            ))}
                                        </div>
                                    </Space>
                                </div>
                            ),
                        };
                    })}
                />
            </div>
        </div>
    </div>
);
}

export default TargetJob;