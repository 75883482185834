import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import '../SearchBox.css'; // Import your custom CSS

const KeywordSearchBox = ({ onSearch }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [keyword, setKeyword] = useState('');

    const possibleOptions = ["Healthcare", "Information Technology", "Education", "Finance", "Banking", "Manufacturing", "Retail", "Hospitality", "Tourism", "Marketing", "Advertising", "Law", "Legal Services", "Construction", "Real Estate", "Environmental", "Green Jobs", "Arts", "Entertainment", "Government", "Public Administration", "Nonprofit", "Social Services", "Transportation", "Logistics", "Agriculture", "Farming", "Energy", "Utilities", "Journalism", "Media", "Automotive", "Transportation", "Software", "Analyst", "Consultant", "Developer", "Engineer"];

    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
            ? []
            : possibleOptions.filter((location) =>
                location.toLowerCase().includes(inputValue)
            );
    };

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        onSearch(suggestion);
    };

    const inputProps = {
        placeholder: 'Search Keyword',
        value: keyword, // Use inputValue instead of keyword here
        onChange: (event, { newValue }) => {
            setKeyword(newValue); // Update inputValue
            onSearch(newValue); // Trigger the search with the updated value
        },
    };
    

    return (
        <div className='locationSearch'>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
            />
        </div>
    );
};

export default KeywordSearchBox;
