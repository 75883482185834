import React, { useState } from 'react';
import { Upload, AutoComplete, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';


const TargetJob = ({ jobData, userData, setUserData, setStep }) => {
    const [selectedJob, setSelectedJob] = useState(userData.targetJob);

    const handleSubmit = async (_) => {
        if (!selectedJob) {
            return;
        }

        setUserData({
            ...userData,
            targetJob: selectedJob,
        });

        setStep(1);
    };

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '30px', borderRadius: '8px', border: '1px solid gainsboro' }}>
            
            <h1 className="title" style={{ fontFamily: "-apple-system,BlinkMac</div>SystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'", textAlign: 'center', fontSize: '55px', fontWeight: 'bold' }}>
                Welcome to Hiremize
            </h1>
            
            <p style={{ marginTop: '20px' }}>
                Our mission is to streamline the application process and to help you find quality opportunities
            </p>

            <h3 style={{ marginTop: '20px' }}>Lets get started!</h3>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}>
                
                <div style={{ width: '50%', paddingRight: '15px', borderRight: '1px solid gainsboro', textAlign: 'center' }}>
                    <span style={{ fontSize: '18px', display: 'block', paddingBottom: '10px' }}>To begin, upload your existing resume to autofill your work experience</span>
                    <Upload>
                        <Button icon={<UploadOutlined/>}>Upload Resume</Button>
                    </Upload>
                </div>
                
                <div style={{ width: '50%', paddingLeft: '15px', textAlign: 'center' }}>
                    <span style={{ fontSize: '18px', display: 'block', paddingBottom: '10px' }}>Or start by entering your job title</span>
                    <div>
                        <AutoComplete
                            options={
                                jobData.allJobs.map((job, _) => ({
                                    value: job.title,
                                    label: job.title,
                                }))
                            }
                            defaultValue={selectedJob}
                            onSearch={setSelectedJob}
                            onSelect={setSelectedJob}
                            onPressEnter={handleSubmit}
                            style={{ width: 200 }}
                        />
                        <Button onClick={handleSubmit} disabled={!selectedJob}>Next</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TargetJob;
