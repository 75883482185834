import React from "react";
import { Form, Input, Select } from 'antd';
import { provincesStates } from '../../SearchBoxes/LocationSearchBox/provincesOrStates.js'

const { Option } = Select;

const BasicInfo = () => {
    return (
        <div>
            <Form.Item name='ProvinceOrState' label="Province/State">
                <Select
                    placeholder="Select a Province/State"
                    style={{ width: "50%" }}
                >
                    {provincesStates.map(code => (
                        <Option key={code} value={code}>
                            {code}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name='City' label="City">
                <Input />
            </Form.Item>
            <Form.Item name='Current Job' label="Current Job">
                <Input />
            </Form.Item>
        </div>
    );

}

export default BasicInfo;