import React, { useState, useEffect } from 'react';
import './Interview.css';
import { Layout, Row, Col, Card, Input, Button, Spin, Typography, Space, Modal, Dropdown, Select } from 'antd';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
// Components
import JobPostingItem from '../../components/Cards/JobPostingItem';
// Queries
import { QUERY_SINGLE_JOB_POSTING } from '../../utils/queries';
// Mutations
import { UPDATE_INTERVIEW_OR_NEGOTIATION_PREP } from '../../utils/mutations';

import { useNavigate } from 'react-router-dom';

const { Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

//https://grow.google/certificates/interview-warmup/category/general/ - general mock interview questions

const Interview = () => {
    const { JobId, AppId } = useParams();
    const [loading, setLoading] = useState(false);
    const { jobLoading, error, data } = useQuery(QUERY_SINGLE_JOB_POSTING, { variables: { id: JobId } });
    const [updateInterviewOrNegotiationPrep] = useMutation(UPDATE_INTERVIEW_OR_NEGOTIATION_PREP);
    const job = data?.singleJobPosting || {};
    const [jobDescription, setJobDescription] = useState('');
    const [currentParseJD, setCurrentParseJD] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [showGooglePrep, setShowGooglePrep] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Get the Parsed Job Description if it already exists
        if (data && data.singleJobPosting && data.singleJobPosting.parsedDescription) {
            setCurrentParseJD(data.singleJobPosting.parsedDescription);
        }
        if (data && data.singleJobPosting && data.singleJobPosting.description) {
            setJobDescription(data.singleJobPosting.description);
        }
    }, [data]);

    if (jobLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const goBack = () => {
        navigate(-1);
        setTimeout(() => window.location.reload(), 250)
    };

    const openMockInterview = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2500);
        setShowGooglePrep(true);
    }

    const markInterviePrepStatus = async () => {
        try {
            await updateInterviewOrNegotiationPrep({
                variables: {
                    id: AppId,
                    interviewPreped: true
                }
            });
            api.success({
                message: 'Interview Preparation Completed!',
                description: 'You have successfully completed your interview preparation. Good luck with your interview!',
                placement: 'topRight'
            });
        } catch (err) {
            console.error(err);
        }
    }


    return (
        <div>
            <Space>{contextHolder}</Space>
            <Link to="#" onClick={goBack} style={{ position: 'relative', top: 10, left: 10, fontSize: '0.7em' }}>&#8592; Back To Applications</Link>
            <Layout className='interviewPrepMainContainer' style={{ background: '#fff' }}>
                <Sider width={600} style={{ background: '#fff', marginRight: '10px' }}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <JobPostingItem job={job} />
                        </Col>
                        <Col span={12}>
                            <Card title="Prepare For Your Interview">
                                <Text>
                                    Welcome to our Interview Prep Service Beta! To get started, kindly input the job description in the space provided. We offer tailored suggestions to improve your interview skills. Let's enhance your career prospects together!
                                </Text>


                                <Button type="primary" onClick={() => markInterviePrepStatus()} block loading={loading} className='button-color' style={{ marginTop: '15px' }}>
                                    Mark as Completed
                                </Button>
                            </Card>
                        </Col>
                    </Row>
                    <Card title="Job Description" style={{ marginTop: '20px' }}>
                        <TextArea
                            rows={13}
                            placeholder="Enter your Job Description"
                            value={jobDescription && jobDescription.trim() !== '' ? jobDescription : ''}
                            onChange={(e) => setJobDescription(e.target.value)}
                        />
                    </Card>
                </Sider>
                <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loading ? (
                        <>
                            <Spin size="large" />
                            <div style={{ marginLeft: '5px' }}>Please give us a moment!</div>
                        </>
                    ) : (
                        <Card
                            title="Practice Mock Interview"
                            style={{ height: '100%', width: '100%' }}
                            bodyStyle={{ height: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            {showGooglePrep ? (
                                <iframe src="https://grow.google/certificates/interview-warmup/category/general/" style={{ width: '100%', height: '100%' }} allow="microphone"></iframe>
                            ) : (
                                <Button
                                    type="primary"
                                    className='button-color-premium'
                                    onClick={() => openMockInterview()}
                                    style={{
                                        fontSize: '20px',
                                        padding: '30px',
                                        borderRadius: '5px',
                                        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                                        justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    }}
                                >
                                    Begin Mock Interview
                                </Button>
                            )}
                        </Card>
                    )
                    }
                </Content>
            </Layout>

        </div>

    );
};

export default Interview;
