import React from 'react';
import { Divider } from 'antd';


const NetworkingGuide = () => {


    return (
        <div>
            <div>
                <h1>Networking</h1>
                <p>Connect with professionals and expand your network to increase your chances of landing your dream job.</p>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Connect with professionals</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img src="screen/ContactList.png" alt="Hiremize" style={{ height: '300px', marginRight: '20px' }} />
                    <div>
                        <h4>Your Professional Relationship Manager</h4>
                        <p>Efficiently manage your network, from recruiters and alumni to companies. Streamline all your networking efforts.</p>
                    </div>
                </div>
                <Divider />
                <h3 style={{ textAlign: 'center', marginBottom:'20px' }}>Network like a Pro</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div>
                        <h4>Message Crafter</h4>
                        <p>Easily create professional messages for potential contacts. Select the topic and parameters, then click 'Generate'. Feel free to add your personal touch - our system will smartly weave it into the message!</p>
                    </div>
                    <img src="screen/network.png" alt="Hiremize" style={{ height: '400px', marginRight: '20px' }} />
                </div>
            </div>
        </div>
    );
}


export default NetworkingGuide;