import React, { useState } from 'react';
import { Button, Modal, Typography, Space } from 'antd';

const { Text } = Typography;

const LastMessageModal = ({ date, firstMessage, followUpMessage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const title = `Last Message on ${date}`;

  return (
    <>
      <Button style={{ width: '100%' }} size='small' onClick={showModal}>
        {date}
      </Button>
      <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Space direction="vertical">
        <Text><strong>Intial Message: </strong>  {firstMessage}</Text>
        {followUpMessage !== '' ? (
          <Text style={{ display: 'block' }}> <strong>Latest Follow-Up: </strong> {followUpMessage}</Text>
        ) : null}
        </Space>
      </Modal>
    </>
  );
};
export default LastMessageModal;