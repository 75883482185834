import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_ME } from "../../../utils/queries";
import { useMutation } from '@apollo/client';
import { UPDATE_TARGET_JOB_PERSONAL_ADVICE } from '../../../utils/mutations';
import PersonalAdvice from '../../JobStrategy/PersonalAdvice'
import '../stepsStyle.css'
import axios from 'axios';
import { Button, Input, Spin, Typography } from 'antd'

const { TextArea } = Typography;

const Step7 = () => {
    const { data, refetch } = useQuery(QUERY_ME);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [updateTargetJobPersonalAdvice] = useMutation(UPDATE_TARGET_JOB_PERSONAL_ADVICE);


    useEffect(() => {
        if (data) {
            setUserData(data.user);
            setMessage(data.user.jobStrategy.targetJobPersonalAdvice);
        }
    }, [data]);

    const fetchPersonalAdvice = async (Position, JobD1, JobD2, JobD3, Experience, Education) => {
        const data = {
            Position,
            JobD1,
            JobD2,
            JobD3,
            Experience,
            Education
        };
        try {
            const response = await axios.post('/jobStrat/personal', data);

            updateTargetJobPersonalAdvice({
                variables: {
                    targetJobPersonalAdvice: response.data.message
                }
            });

            return response.data.message;

        } catch (error) {
            console.error("There was a problem with the fetch operation:", error.message);
        }
    }

    const generateAdvice = async () => {
        setLoading(true);
        try {
            const advice = await fetchPersonalAdvice(
                userData.preference.desiredJob.jobTitle,
                userData.preference.desiredJob.jobdescription1,
                userData.preference.desiredJob.jobdescription2,
                userData.preference.desiredJob.jobdescription3,
                userData.workHistory,
                userData.education);

            setMessage(advice);
        } catch (error) {
            console.error("Error fetching the follow-up message:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Additional Info</h1>
            <p>Here is some additional info that may help you in your job search.</p>
            <Button type="primary" onClick={generateAdvice} loading={loading}>
                Generate Advice
            </Button>
            <div className="wiz-container">
                <div style={{
                    width: '100%',
                    marginTop: '15px',
                    marginBottom: '15px',
                }}>
                    {loading ? <Spin /> :

                        <Input.TextArea
                            rows={20}
                            value={message}
                        />}
                </div>
            </div>
        </div>
    );
}

export default Step7;