import React, { useState, useEffect } from 'react';
import { Input, Tooltip, Popconfirm } from 'antd';
import { CopyTwoTone, ThunderboltFilled, CheckOutlined } from '@ant-design/icons';
import useTypingEffect from './typingEffect'
import useIsMobile from '../../../contexts/useIsMobile';
import './EmailComposer.css';

const { TextArea } = Input;

var ROWS

const EmailCompose = ({ newSubject, setNewSubject, newBody, setNewBody, messageRows }) => {

    const isMobile = useIsMobile();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const typedSubjectText = useTypingEffect(newSubject, 25);
    const typedBodyText = useTypingEffect(newBody, 5);

    // useEffect(() => {
    //     if (isInitialRender) {
    //         setIsInitialRender(false);
    //     }
    // }, []);

    const ROWS = messageRows || (isMobile ? 4 : 8);

    const editSubject = (value) => {
        setIsInitialRender(false);
        setNewSubject(value);
    }

    const editBody = (value) => {
        setIsInitialRender(false);
        setNewBody(value);
    }



    const copyMessageToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(newBody);
            // Optionally, you can set a state or display a notification to inform the user that the text has been copied
            console.log("Message copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };


    //Set New Body to be the typed body text
    //setNewBody(typedBodyText)
    // useEffect(() => {
    //     setNewBody(typedBodyText);
    // }, []);


    return (
        <div className='mainEmailComposerContainer'>
            <Input
                placeholder="Subject"
                value={isInitialRender ? typedSubjectText : newSubject}
                onChange={e => editSubject(e.target.value)}
                style={{ marginBottom: 10 }}
            />
            <div style={{ position: 'relative', width: '100%' }}> {/* Add this container */}
                <TextArea
                    placeholder="Message"
                    value={isInitialRender ? typedBodyText : newBody}
                    onChange={e => editBody(e.target.value)}
                    style={{ flex: 1, marginBottom: 10 }}
                    rows={ROWS}
                />
                <Popconfirm
                    title="AI Message Generator"
                    placement="topRight"
                    description={
                        <>
                        <div style={{ width:'500px' }}>
                            <p style={{ fontSize: '12px' }}>Let's create a message for you!</p>
                            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-evenly' }}>
                                {/* <p style={{ fontSize: '12px', marginRight: '10px' }}>Additional Information</p> */}
                                <TextArea
                                placeholder='Highlight any additional information like common interests, experiences, educations, connection, or job'
                                rows={6}
                                />
                            </div>
                        </div>
                        </>
                    }
                    onConfirm={copyMessageToClipboard}
                    okText="Generate"
                    cancelText="Cancel"
                    icon={<CheckOutlined style={{ color: 'green' }} />}
                >
                    <Tooltip title="Generate Message">
                        <ThunderboltFilled
                            onClick={copyMessageToClipboard}
                            style={{
                                color: 'gold',
                                position: 'absolute',
                                bottom: '50px',
                                right: '10px',
                                fontSize: '18px'
                            }}
                        >
                            Copy
                        </ThunderboltFilled>
                    </Tooltip>
                </Popconfirm>
                <Tooltip title="Copy Message">
                    <CopyTwoTone
                        onClick={copyMessageToClipboard}
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '10px',
                            fontSize: '18px'
                        }}
                    >
                        Copy
                    </CopyTwoTone>
                </Tooltip>
            </div>
        </div>
    );
};

export default EmailCompose;
