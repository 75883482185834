import React, { useRef, useEffect } from "react";

const HTMLPreviewer = ({ html }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.document.open();
      iframeRef.current.contentWindow.document.write(html);
      iframeRef.current.contentWindow.document.close();
    }
  }, [html]);

  return (
    <div style={{ transform: 'scale(0.7)', transformOrigin: '0 0', width: '143%', height: '143%' }}>
      <iframe ref={iframeRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default HTMLPreviewer;