import React, { useEffect } from 'react';
import './WelcomeStep.css';

const WelcomeStep = ({ disableNext }) => {

    useEffect(() => {
        disableNext(false);
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div className='mainWelcomeStep'>
            <h2 className='welcome'>Welcome!</h2>
            <img src="/logo.png" alt="Hiremize" className='logo-welcome' />
            <p>Welcome to the next stage of your career journey! We're here to empower your job search and help you land your dream job. Let's get started!</p>
        </div>
    );
}

export default WelcomeStep;