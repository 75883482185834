export const jobSearchStatuses = [
    "Trying to score more Interviews",
    "Lost in my Job Search",
    "Looking for a Summer Internship or Co-op",
    "Changing Careers",
    "Looking for a Raise or Promotion",
    "Feel stuck in my Career",
    "Looking for a Second Job",
    "Looking to Build my Network",

];