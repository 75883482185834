import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col } from 'antd';
import { useMutation } from "@apollo/client";
import { EDIT_CONTACT } from "../../../utils/mutations.js";
import { EDIT_CONTACT_RELATION } from "../../../utils/mutations.js";
import { useQuery } from "@apollo/client";
import { QUERY_MY_COMPANIES } from "../../../utils/queries.js";
import AutoComplete from 'antd/lib/auto-complete';
import { ADD_COMPANY } from "../../../utils/mutations.js";
import { SAVE_COMPANY } from "../../../utils/mutations.js";
import { CheckCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditContactModal = ({ onClose, contact }) => {
  const [form] = Form.useForm();
  const [editContact] = useMutation(EDIT_CONTACT);
  const { data, loading, refetch } = useQuery(QUERY_MY_COMPANIES);
  const [companies, setCompanies] = useState([]);
  const [addCompany] = useMutation(ADD_COMPANY);
  const [saveCompany] = useMutation(SAVE_COMPANY);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(contact.contactDetails.company ? contact.contactDetails.company.companyName : "");
  const [contactPublicOrPrivate, setContactPublicOrPrivate] = useState(contact.contactDetails.postingStatus);
  const [editContactRelation] = useMutation(EDIT_CONTACT_RELATION);
  const [formStatus, setFormStatus] = useState("");



  console.log(contact);

  useEffect(() => {
    if (data) {
      setCompanies(data.myCompanies);
    }
  }, [data]);

  useEffect(() => {
    if (contactPublicOrPrivate === "Public") {
      setFormStatus("disabled");
    }
  }, [contactPublicOrPrivate]);

  const handleSubmit = async () => {

    if (contactPublicOrPrivate === "Public") {
      console.log("Cannot Edit Public Contact");
      return;
    }

    var companyID = null;

    console.log("editing...");

    try {

      if (companies) {
        console.log("company exist");
        var filteredCompanies = companies.filter(company => company.companyDetails.companyName === currentCompany);
        if (filteredCompanies.length > 0) {
          console.log(filteredCompanies[0].companyDetails._id);
          companyID = filteredCompanies[0].companyDetails._id;
        } else {
          console.log("no companies found");
          // Do not proceed if no company is found
          return;
        }
      }

      const formData = await form.validateFields();
      await editContact({
        variables: {
          id: contact.contactDetails._id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          company: companyID ? { _id: companyID } : null, // pass the company object as a variable only if companyID is not null
          jobTitle: formData.jobTitle,
          linkedIn: formData.linkedIn,
          email: formData.email,
          phone: formData.phone,
        },
      });

      if (formData.relationship) {
        await editContactRelation({
          variables: {
            id: contact._id,
            relationship: formData.relationship ? formData.relationship : "Other",
          },
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error editing contact:", error);
    }
  };

  const handleSearch = (value) => {
    if (companies) {
      const filteredCompanies = companies.filter(company => company.companyDetails.companyName.toLowerCase().includes(value.toLowerCase()));
      const options = filteredCompanies.map(company => {
        return { value: company.companyDetails.companyName, id: company.companyDetails._id }
      });
      setCompanyOptions(options);
    }
  };

  const handleSelectCompany = (value, id) => {
    setCurrentCompany(value);
  }

  const handleAddCompany = async () => {
    try {
      const newCompany = await addCompany({
        variables: {
          companyName: currentCompany,
          postingStatus: "Private"
        },
      });
      //Save Company to user's saved companies
      await saveCompany({
        variables: {
          company: newCompany.data.addCompany._id
        },
      });
      refetch();
    } catch (error) {
      console.error("Error adding company:", error);
    }
  }

  const isCompanyAdded = () => {
    if (companies) {
      const filteredCompanies = companies.filter(company => company.companyDetails.companyName === currentCompany);
      if (filteredCompanies.length > 0) {
        return true;
      }
    }
    return false;
  }


  return (
    <Modal
      title="Edit Contact"
      visible={true}
      // onOk={handleSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} className='button-color'>
          Update
        </Button>,
      ]}
      className="custom-modal"
    >
      <Form form={form} layout="vertical" className="custom-form" disabled={formStatus}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              initialValue={contact.contactDetails.firstName}
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              initialValue={contact.contactDetails.lastName}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Company"
          name="company"
          initialValue={contact.contactDetails.company ? contact.contactDetails.company.companyName : ""}
        >
          <div className="autocomplete-wrapper">
            <AutoComplete
              disabled={formStatus}
              options={companyOptions}
              style={{ width: '100%' }}
              onSearch={handleSearch}
              onSelect={handleSelectCompany}
              onChange={(value) => setCurrentCompany(value)}
            >
              <Input.Search size="medium" placeholder={contact.contactDetails.company ? contact.contactDetails.company.companyName : ""} />
            </AutoComplete>
            {isCompanyAdded() ? (
              <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />
            ) : (
              <Button className='button-color' type="primary" onClick={handleAddCompany} style={{ marginLeft: '10px' }}>Quick Add</Button>
            )}
          </div>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Job Title" name="jobTitle" initialValue={contact.contactDetails.jobTitle}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Relationship" name="relationship" initialValue={contact.relationship}>
              <Select>
                <Option value="Family">Family</Option>
                <Option value="Friend">Friend</Option>
                <Option value="Mutual">Mutual</Option>
                <Option value="Co-Worker">Co-Worker</Option>
                <Option value="Alumni">Alumni</Option>
                <Option value="Recruiter">Recruiter</Option>
                <Option value="Hiring Manager">Hiring Manager</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="LinkedIn" name="linkedIn" initialValue={contact.contactDetails.linkedIn}>
          {contactPublicOrPrivate === "Public" ? (
            <Input type="password" />
          ) : (
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Email" name="email" initialValue={contact.contactDetails.email}>
          {contactPublicOrPrivate === "Public" ? (
            <Input type="password" />
          ) : (
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Phone" name="phone" initialValue={contact.contactDetails.phone}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditContactModal;