import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Card, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';


const OptimizeResumeSuccess = ({ visible, onClose, beforeText, afterText }) => {

    //beforeText = ["Developed custom software to automate procurement and shipping to reduce order processing time by 70%; catapulting the company to become the fastest online retailer in the industry", "Selected as one of the two candidates from thousands of applicants for Intact’s National Accelerated Development Program, showcasing strong analytical and interpersonal skills.", "Developed custom software to automate procurement and shipping to reduce order processing time by 70%; catapulting the company to become the fastest online retailer in the industry"];
    ///afterText = ["Invented a bespoke software to automate procurement and shipping processes, resulting in a 70% reduction in order processing time and positioning the company as the industry's fastest online retailer — showcasing my creative thinking and business management skills.", "One of two selected in Vancouver among thousands of applicants to join Intact’s National Accelerated Development Program", "Analyzed the cost of customer acquisition (CAC) using SQL, Python, and R to identify high-yielding marketing channels, leading to a 117% increase in sales for each marketing dollar spent"]

    const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + 1) % beforeText.length);
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            centered
            width={'75vw'}
            bodyStyle={{ height: '50vh', alignItems: 'center', justifyContent: 'center' }}
            footer={
                <div>
                    <Button
                        type="primary"
                        onClick={onClose}
                        className='button-color'
                    >
                        View Resume
                    </Button>
                </div>
            }

        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>Resume Enhancement Complete <CheckOutlined style={{ color: 'green' }} /></h2>
                <p style={{ textAlign: 'center' }}>
                    Congratulations! Your resume has been successfully optimized. You can now download your optimized resume or edit it further.
                </p>
                <p><strong>We have meticulously refined the highlights and skills of your resume to align more closely with the job description.</strong></p>
                <Divider style={{ margin: '2px' }} />
                <p>Here are some changes we made:</p>
                <Row gutter={16} justify="center" align="stretch" style={{ marginTop: "1%" }}>
                    <Col span={10} >
                        <Card title="Before" bordered={false} style={{ height: '100%' }}>
                            {beforeText[index]}
                        </Card>
                    </Col>
                    <Col span={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ border: 'solid #000', borderWidth: '0 3px 3px 0', display: 'inline-block', padding: '3px', transform: 'rotate(-45deg)', '-webkit-transform': 'rotate(-45deg)' }}></div>
                    </Col>
                    <Col span={10}>
                        <Card title="After" bordered={false} style={{ height: '100%' }}>
                            {afterText[index]}
                        </Card>
                    </Col>
                </Row>
                <Button type="primary" className='button-color' onClick={handleNext}>Next</Button>
            </div>

        </Modal>
    );
}

export default OptimizeResumeSuccess;