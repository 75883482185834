import React, { useState, useEffect, useRef } from "react";
import ContactList from "../../layouts/ContactsList";
import CompanyList from "../../layouts/CompanyList"
import { Button, Switch } from "antd";
import "./Contacts.css";
import AddContactModal from "../../components/Modals/AddContactModal";
import AddCompanyModal from "../../components/Modals/AddCompanyModal";
import { FilterOutlined } from "@ant-design/icons";
import { useTour } from "../../contexts/tourContext";

import { useLocation } from 'react-router-dom';


const Contacts = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [isAddContactOpen, setIsAddContactOpen] = useState(false);
    const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
    const [showCompanyList, setShowCompanyList] = useState(queryParams.get('filter') || true); // New state variable
    const [showFilters, setShowFilters] = useState(false);

    const { setTourSteps, startTour } = useTour();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    useEffect(() => {
        // Define the tour steps
        setTourSteps([
            {
                title: 'Welcome to your Professional Relationship Manager (PRM)',
                description: 'Networking is key to landing your dream job. Here you can manage your contacts and companies.'
            },
            {
                target: () => ref1.current,
                placement: 'left',
                title: "People or Companies",
                description: "Toggle between viewing your individuals or companies."
            },
            {
                target: () => ref2.current,
                placement: 'left',
                title: 'Filters',
                description: 'Filter your contacts or companies by favourites and stage of contact.'
            },
            {
                target: () => ref3.current,
                placement: 'left',
                title: 'Add Contacts or Companies',
                description: 'Click to add new contacts or companies to your list.'
            },
        ]);
        
        var isFirstTimeContacts = localStorage.getItem('isFirstTimeContacts');

        if (!isFirstTimeContacts) {
            startTour();
            localStorage.setItem('isFirstTimeContacts', 'false');
        }
    }, [setTourSteps]);

    const openAddContact = () => {
        setIsAddContactOpen(true);
    };

    const closeAddContact = () => {
        setIsAddContactOpen(false);
    };

    const openAddCompany = () => {
        setIsAddCompanyOpen(true);
    }

    const closeAddCompany = () => {
        setIsAddCompanyOpen(false);
    }

    const toggleList = () => {
        setShowCompanyList(!showCompanyList);
    };

    const showDrawer = () => {
        setShowFilters(true);
    }

    const onClose = () => {
        setShowFilters(false);
    }


    return (
        <div className="container">

            {/* Title Section */}
            <div className="applist-title-section" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="title" style={{ fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'", textAlign: 'center', fontSize: '34px', fontWeight: 'bold' }}>My Contacts</h2>

                <div className="button-container">
                    <div ref={ref1} className="contacts-toggle-container">
                        <Switch className="button-color" checkedChildren="Companies" unCheckedChildren="People" checked={showCompanyList} onChange={toggleList} style={{ borderRadius: '5px' }} />
                    </div>
                    <Button ref={ref2} className="button-color" type="primary" onClick={showDrawer}>
                        <FilterOutlined />
                    </Button>
                    <div ref={ref3}>
                    <Button className="button-color add-contact-button" type="primary" onClick={openAddContact}>
                        Add Contact
                    </Button>
                    <Button className="button-color add-company-button" type="primary" onClick={openAddCompany}>
                        Add Company
                    </Button>
                    </div>
                </div>
            </div>

            {showCompanyList ? <CompanyList onAddclosed={isAddContactOpen} openFilters={showFilters} closeFilters={onClose} /> : <ContactList onAddclosed={isAddCompanyOpen} openFilters={showFilters} closeFilters={onClose} />}
            {isAddContactOpen && (
                <AddContactModal onClose={closeAddContact} />
            )}
            {isAddCompanyOpen && (
                <AddCompanyModal onClose={closeAddCompany} />
            )}
        </div>
    );
}

export default Contacts;