import React  from "react";
import { Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';


const OptimizeResumeButton = ({jobID, appID, resumeOptimized}) => {

    return (
        <Button 
        icon={<CheckCircleOutlined />}
        style={{ background: resumeOptimized ? "#fcdc5c" : "" }}
        >
        <Link to={`/application/resume/${jobID}/${appID}`}>Optimize Resume</Link>
      </Button>
    );

}

export default OptimizeResumeButton;