import axios from 'axios';

//Backgroud
export const startParseResumeTask = async (resumeFile) => {

    const formData = new FormData();
    formData.append('resume', resumeFile);

    try {

        const response = await axios.post('/resume/parse-resume-task', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response;

    } catch (error) {

        console.error("There was a problem parse your resume", error.message);

    }
}

export const getParseResumeTaskStatus = async (taskId) => {

    try {

        const response = await axios.get(`/resume/parse-resume-task/${taskId}/status`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the status of your resume", error.message);

    }
}

export const getParseResumeTaskResult = async (taskId) => {

    try {

        const response = await axios.get(`/resume/parse-resume-task/${taskId}/result`);

        return response;

    } catch (error) {

        console.error("There was a problem getting the result of your resume", error.message);

    }
}

//legacy
export const fetchParseResume = async (resumeFile) => {

    const formData = new FormData();
    formData.append('resume', resumeFile);

    try {

        const response = await axios.post('/resume/parseResume', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });;

        return response;

    } catch (error) {

        console.error("There was a problem parse your resume", error.message);

    }
}