import axios from 'axios';


export function defaultFollowUpMessage(Name, Position, Company) {
    return `Hi ${Name}, I just wanted to follow-up on my previous message. I'm an aspiring ${Position} and am keen to learn about your experience at ${Company}. If you're open to it, I'd appreciate a chat. Thanks!`
}

export const additionalFollowUpPlaceholder = "Add any additional information here.";
//add other placeholders

// TO DO: Need to make a new fetch function for follow up messages
export const fetchFollowUpMessage = async (CompanyOrPerson, Name, Cold, AdditionalInfo, LinkLimit) => {

    const Theme = 'This is a follow-up message'
    const ThemeValue = 'We following up with the contact, hoping to get a response.'
    
    const data = {
        CompanyOrPerson,
        Name,
        Theme,
        ThemeValue,
        Cold,
        AdditionalInfo,
        LinkLimit
    };
    try {
        const response = await axios.post('/message/maincontact', data);

        return response.data.mainContactMes;
    } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
    }
}