import React, { useContext } from 'react';
import { Button, notification } from 'antd';
import { fetchSendEmail } from '../../../utils/EmailAPIs/sendEmail.js';
import { UserContext } from '../../../contexts/userContext.js';
import { useMutation } from '@apollo/client';
import { SAVE_COMPANY } from '../../../utils/mutations.js';
import { ADD_MATCH_LIKED } from '../../../utils/mutations.js';
import { UPDATE_LAST_CONTACTED_COMPANY } from '../../../utils/mutations.js';
import useIsMobile from '../../../contexts/useIsMobile';


const EmailButtons = ({ handleChatBoxUpdate, toEmail, subject, body, userContactId, forMatcher }) => {

    const { user } = useContext(UserContext);
    const [saveCompany, { error }] = useMutation(SAVE_COMPANY);
    const [addMatchLiked, { error: matchError }] = useMutation(ADD_MATCH_LIKED);
    const [updateCompany] = useMutation(UPDATE_LAST_CONTACTED_COMPANY);
    const isMobile = useIsMobile();

    var displaySave = false
    if (forMatcher) {
        displaySave = true
    }

    const markAsSent = async (contactID) => {
        try {
            // if (companyOrPerson === "company") {
            await updateCompany({
                variables: {
                    id: contactID,
                    lastContacted: new Date().toISOString().slice(0, 10),
                    lastContactMessage: body,
                }
            });
            // } else {
            //     if (messageType === "followUp") {
            //         await updateFollowUp({
            //             variables: {
            //                 id: recordID,
            //                 lastfollowUp: new Date().toISOString().slice(0, 10),
            //                 lastfollowUpMessage: message,
            //             }
            //         });
            //     }
            //     else {
            //         await updateContact({
            //             variables: {
            //                 id: recordID,
            //                 lastContacted: new Date().toISOString().slice(0, 10),
            //                 lastContactMessage: message,
            //             }
            //         });
            //     }
            // }
            // onClose();
        } catch (error) {
            console.error("Error updating the application:", error);
        }
    };

    const sendEmail = async () => {
        try {

            var contactID

            if (forMatcher) {
                var newSaveCompany = await saveCompany({
                    variables: { company: userContactId }
                });
                contactID = newSaveCompany.data.saveCompany._id
                try {
                    await addMatchLiked();
                } catch (error) {
                    console.error("Error adding match liked:", error);
                    // Handle the error as needed, e.g., log it, notify the user, etc.
                }
            } else {
                contactID = userContactId
            }

            //Send email
            const response = await fetchSendEmail(toEmail, user.customUserEmail, subject, body, contactID, user._id,);

            // Check if the response is an error
            if (response instanceof Error || !response) {
                console.log("Error sending email:", response);
                throw response;
            }

            notification.success({
                message: 'Email Sent',
                description: isMobile ? "" : 'Your email has been sent successfully.',
                placement: 'topRight',
                duration: 2,
                pauseOnHover: true,
                showProgress: true,
                style: isMobile ? { padding: '10px', paddingRight: '20px', paddingBottom: '5px', width: '100%' } : "",
                closeIcon: isMobile ? false : true,

            });

            markAsSent(contactID);
        } catch (error) {


            if (toEmail === undefined || toEmail === null || toEmail === '') {
                notification.error({
                    message: 'Error Sending Email',
                    description: 'Contact is missing an email address.',
                    placement: 'topRight',
                    duration: 10,
                });
            } else {

                notification.error({
                    message: 'Error Sending Email',
                    description: 'Error sending email. Please try again later.',
                    placement: 'topRight',
                    duration: 10,
                });
            }
        }

    };

    const save = async () => {
        try {
            var newSaveCompany = await saveCompany({
                variables: { company: userContactId }
            });

            try {
                await addMatchLiked();
            } catch (error) {
                console.error("Error adding match liked:", error);
                // Handle the error as needed, e.g., log it, notify the user, etc.
            }

            notification.success({
                message: 'Company Saved',
                description: isMobile ? "" : 'This company has been saved to your contacts.',
                placement: 'topRight',
                duration: 2,
                pauseOnHover: true,
                showProgress: true,
                style: isMobile ? { padding: '10px', paddingRight: '20px', paddingBottom: '5px', width: '100%' } : "",
                closeIcon: isMobile ? false : true,

            });

        } catch (error) {
            notification.error({
                message: 'Error Saving Company',
                description: 'This feature is not available for the beta',
                placement: 'topRight',
                duration: 10,
            });
        }
    }


    // <Button className='button-color-premium'>Generate Message</Button>

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

            {displaySave &&
                <Button
                    onClick={() => {
                        save()
                        handleChatBoxUpdate();
                    }}
                    style={{ width: '150px' }}>
                    Save for Later
                </Button>
            }

            <Button
                className='button-color-premium'
                style={{ width: '150px' }}
                onClick={() => {
                    handleChatBoxUpdate();
                    sendEmail();
                }}
            >
                {displaySave ? 'Save & Send' : 'Send'}
            </Button>
        </div>
    );
}

export default EmailButtons;
