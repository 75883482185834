import React, { useState, useEffect, useRef } from "react";
import ApplicationsList from "../../layouts/ApplicationsList";
import AddApplicationModal from "../../components/Modals/AddApplicationModal";
import { Button, Row } from "antd";
import "./Applications.css";
// import ApplicationStatistic from '../../components/ApplicationStatistic';

const Applications = () => {

    // const [isAddAppOpen, setIsAddAppOpen] = useState(false);
    // const statuses = ["Saved", "Applied", "Interviewing", "Offered", "Archived"]

    // const openAddApp = () => {
    //     setIsAddAppOpen(true);
    // };

    // const closeAddApp = () => {
    //     setIsAddAppOpen(false);
    // };


    return (
        <div className="applications-container">

            {/* Title Section */}
            {/* <div className="title-section">
                <div className="title-content">
                    <h1 className="title">My Applications</h1>
                    <p className="blurb">
                        Welcome to your applications. Add a new application or view your existing applications.
                    </p>
                    <div className="button-container">
                        <Button className="button-color add-application-button" type="primary" onClick={openAddApp}>
                            Add New Job Posting
                        </Button>
                    </div>
                </div>
            </div> */}
            {/* <div  className="dashboard-section"> */}
                {/* <h2>Statistics</h2> */}
                {/* <Row justify="space-evenly">
                    {statuses.map((status) => (<ApplicationStatistic status={status} />))}
                </Row> */}
            {/* </div> */}
            < ApplicationsList />
            {/* < ApplicationsList isAddAppOpen={isAddAppOpen} /> */}
            {/* {isAddAppOpen && (
                <AddApplicationModal onClose={closeAddApp} />
            )} */}
        </div>
    );
}

export default Applications;